import { MenuUsageDataSet } from "../types/WebData";
import API from "./api";
import * as MESSAGE from "../const/message";
import { HTTP_STATUS_CODE } from "../const/index";

/**
 * menu_usageテーブルAPI 更新
 *
 * @param {MenuUsageDataSet} rows
 */
export const updateMenuUsage = async (rows: MenuUsageDataSet[]) => {
  const result = await API.put("/cms-api/menuUsage/", {
    rows,
  })
    .then((res) => {
      return { isError: false, message: MESSAGE.TOROKU_SEIKO };
    })
    .catch((error) => {
      const status = error.response.status;
      const message = status === HTTP_STATUS_CODE.CONFLICT_ERROR ? MESSAGE.CONFLICT_ERROR : MESSAGE.SYSTEM_ERROR;
      return { isError: true, message: message };
    });
  return result;
};
