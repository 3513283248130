import { useEffect, useRef, memo } from 'react';
import videojs, { VideoJsPlayer } from 'video.js';

import 'video.js/dist/video-js.css';
import PlayerModules from './Player.module.css';

type Props = {
  options: videojs.PlayerOptions | undefined;
  onReady: any;
};

const Player = ({ options, onReady }: Props) => {
  const videoRef = useRef(null);
  const playerRef = useRef<VideoJsPlayer | undefined>(undefined);

  useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;

      if (!videoElement) return;

      const player = playerRef.current = videojs(videoElement, options, () => {
        onReady && onReady(player);
      });
      // 音量を指定
      player.volume(0.5);
    }
  }, [options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = undefined;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player>
      <video ref={videoRef} className={`${PlayerModules['video-js']} video-js vjs-big-play-centered`} />
    </div>
  );
}

export default memo(Player);
