import { useState, useContext, useEffect } from "react";

import {
  DataGrid,
  GridColDef,
  jaJP,
} from "@mui/x-data-grid";

import { ReloadContext } from "../../context/ReloadContext";
import { renderCellExpand } from "../Common/GridCellExpand";
import {
  HoeishomeiData, HoeishomeiDataSet, HoeiKaisuSearchParam
} from "../../types/WebData";
import { postSearchHoeiKaisu } from "../../api/hoeiKaisu";

/**
 * 全件データを１ページ分の行にsliceしている
 */
const loadServerRows = (
  page: number,
  pageSize: number,
  allRows: HoeishomeiDataSet[]
): Promise<HoeishomeiDataSet[]> =>
  new Promise<HoeishomeiDataSet[]>((resolve) => {
    if (!allRows) return;
    resolve(
      // １ページ分にsliceする
      allRows.slice(page * pageSize, (page + 1) * pageSize)
    );
  });

const useQuery = (page: number, pageSize: number, allRows: any[]) => {
  const [rowCount, setRowCount] = useState<number | undefined>(undefined);
  const [data, setData] = useState<HoeishomeiDataSet[]>([]);

  useEffect(() => {
    let active = true;

    setRowCount(undefined);
    loadServerRows(page, pageSize, allRows).then((newRows) => {
      if (!active) {
        return;
      }
      setData(newRows);
      setRowCount(allRows.length);
    });

    return () => {
      active = false;
    };
  }, [page, pageSize, allRows]);

  return { data, rowCount };
};

interface RowsState {
  page: number;
  pageSize: number;
}

type Props = {
  searchParam: HoeiKaisuSearchParam | null;
  setload: React.Dispatch<React.SetStateAction<boolean>>
};

/**
 * 放映証明管理一覧コンポーネント
 *
 * @return {searchParam} 
 */
export const HoeiShomeiGrid = ({ searchParam, setload }: Props) => {
  const reloadContext = useContext(ReloadContext);
  const [hoeishomeiData, setHoeishomeiData] = useState<HoeishomeiData>({ data: [] });

  useEffect(() => {
    const getSearchData = async (param: HoeiKaisuSearchParam) => {
      const result = await postSearchHoeiKaisu(param);
      setload(false);
      if (result.isError) {
        return reloadContext?.setSnackbarInfo({
          isOpen: true,
          type: "error",
          message: result.message,
        });
      } else {
        setHoeishomeiData(result);
      }
    };
    if (!searchParam) return setload(false);
    getSearchData(searchParam)
  }, [searchParam, reloadContext?.reload]);

  const columns: GridColDef[] =
    [
      {
        field: "id",
        headerName: "ID",
        hide: true,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "bangumi_mei",
        headerName: "番組名",
        width: 300,
        sortable: false,
        disableColumnMenu: true,
        renderCell: renderCellExpand,
      },
      {
        field: "hoei_seiko_kaisu",
        headerName: "放映成功回数",
        width: 150,
        sortable: false,
        disableColumnMenu: true,
      },
      {
        field: "hoei_shippai_kaisu",
        headerName: "放映失敗回数",
        width: 150,
        sortable: false,
        disableColumnMenu: true,
      },
    ];

  // ページのstate　１ページに１０行
  const [rowsState, setRowsState] = useState<RowsState>({
    page: 0,
    pageSize: 10,
  });

  const { data, rowCount } = useQuery(
    rowsState.page,
    rowsState.pageSize,
    hoeishomeiData.data
  );

  // データの読み込みが間に合ってなくてrowCountがundefinedのままの場合の対策
  const [rowCountState, setRowCountState] = useState(rowCount || 0);
  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      rowCount !== undefined ? rowCount : prevRowCountState
    );
  }, [rowCount, setRowCountState]);

  return (
    <div style={{ height: 650, width: "100%" }}>
      <DataGrid
        rows={data}
        columns={columns}
        rowCount={rowCountState}
        {...rowsState}
        rowsPerPageOptions={[10]}
        pagination
        paginationMode="server"
        sortModel={[{ field: "id", sort: "asc" }]}
        onPageChange={(page) => setRowsState((prev) => ({ ...prev, page }))}
        onPageSizeChange={(pageSize) =>
          setRowsState((prev) => ({ ...prev, pageSize }))
        }
        localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
      />
    </div>
  );
};