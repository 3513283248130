import { useState, useEffect, useRef, useContext } from "react";

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import moment from "moment";

import {
  Box,
  Toolbar,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";

import { Copyright } from "../components/Common/Copyright";
import BaitaiSelect from "../components/Common/BaitaiSelect";
import BaitaiShosaiSelect from '../components/Common/BaitaiShosaiSelect';
import SimpleDatePicker from '../components/Common/DatePicker/SimpleDatePicker';
import SimpleTimePicker from '../components/Common/SimpleTimePicker';
import TentoShomeiList from "../components/TentoShomei/TentoShomeiList";
import LoadingButton from '@mui/lab/LoadingButton';
import { AuthInfoContext } from "../context/AuthContext";
import { KENGEN } from "../const/index";

import { TentoShomeiSearchParam, BaitaiDataSet, BaitaiShosaiDataSet } from "../types/WebData";

const mdTheme = createTheme();

/**
 * 点灯証明管理画面コンポーネント
 *
 * @return {*} 
 */
export const TentoShomei = () => {
  const [baitai, setBaitai] = useState<BaitaiDataSet | null>(null);
  const [baitaiShosai, setBaitaiShosai] = useState<BaitaiShosaiDataSet | null>(null);
  const [haishinbi, setHaishinbi] = useState<Date | null>(null);
  const [haishinKaishiJikan, setHaishinKaishiJikan] = useState<Date | null>(null);
  const [haishinShuryoJikan, setHaishinShuryoJikan] = useState<Date | null>(null);
  const [isOnlyErrorData, setIsOnlyErrorData] = useState<boolean>(true);
  const [searchParam, setSearchParam] = useState<TentoShomeiSearchParam | null>(null);
  const [authInfo] = useContext(AuthInfoContext);

  // エラーメッセージ
  const [errorMessageBaitai, setErrorMessageBaitai] = useState<string>("");
  const [errorMessageBaitaiShosai, setErrorMessageBaitaiShosai] = useState<string>("");
  const [errorMessageHaishinbi, setErrorMessageHaishinbi] = useState<string>("");
  const [errorMessageHaishinKaishiJikan, setErrorMessageHaishinKaishiJikan] = useState<string>("");
  const [errorMessageHaishinShuryoJikan, setErrorMessageHaishinShuryoJikan] = useState<string>("");

  const [load, setLoad] = useState<boolean>(false);

  // 処理中フラグ（ボタン連打対策）
  const processing = useRef(false);

  // 媒体詳細情報を初期化
  useEffect(() => {
    if (baitaiShosai?.baitai_id) {
      setBaitaiShosai({ id: 0 });
    }
  }, [baitai])

  // 検索実行
  const handleSearch = async () => {
    setErrorMessageBaitai("");
    setErrorMessageBaitaiShosai("");
    setErrorMessageHaishinbi("");
    setErrorMessageHaishinKaishiJikan("");
    setErrorMessageHaishinShuryoJikan("");

    // バリデーションチェック
    if (!baitai?.id) {
      return setErrorMessageBaitai("媒体名は必須です。");
    }
    if (!baitaiShosai?.id) {
      return setErrorMessageBaitaiShosai("画面名は必須です。");
    }
    if (!haishinbi) {
      return setErrorMessageHaishinbi("配信日は必須です。");
    }
    if (!moment(haishinbi).isValid()) {
      return setErrorMessageHaishinbi("不正な入力形式です。");
    }
    if (haishinKaishiJikan && !moment(haishinKaishiJikan).isValid()) {
      return setErrorMessageHaishinKaishiJikan("不正な入力形式です。");
    }
    if (haishinShuryoJikan && !moment(haishinShuryoJikan).isValid()) {
      return setErrorMessageHaishinShuryoJikan("不正な入力形式です。");
    }

    // 処理中(true)なら非同期処理せずに抜ける
    if (processing.current) return;
    // 処理中フラグを上げる
    processing.current = true;
    // 疑似非同期処理
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 1000);
    setLoad(true);
    const searchParam: TentoShomeiSearchParam = {
      baitai_shosai_id: baitaiShosai.id,
      haishinbi: (haishinbi !== null) ? moment(haishinbi).format('YYYY-MM-DD') : "",
      haishin_kaishi_jikan: (haishinKaishiJikan !== null) ? moment(haishinbi).format('YYYY-MM-DD') + moment(haishinKaishiJikan).format(" HH:mm:ssZ") : "",
      haishin_shuryo_jikan: (haishinShuryoJikan !== null) ? moment(haishinbi).format('YYYY-MM-DD') + moment(haishinShuryoJikan).format(" HH:mm:ssZ") : "",
      is_only_error_data: isOnlyErrorData
    };
    await setSearchParam(searchParam);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />

          <Container maxWidth={false} sx={{ mt: 2, mb: 4 }}>

            <Grid container spacing={1}>
              {/* 検索 */}
              <Grid item xs={3} md={4} lg={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '75vh',
                  }}
                >
                  <Box>
                    <BaitaiSelect
                      baitai={baitai}
                      setBaitai={setBaitai}
                      label="媒体名 *"
                      is_visible={authInfo?.kengen === KENGEN.ADMIN ? true : false}
                      kanri_only_flg={true}
                    />
                    {errorMessageBaitai && (
                      <small className={"error-message"}>{errorMessageBaitai}</small>
                    )}

                    <BaitaiShosaiSelect
                      baitaiId={baitai?.id}
                      baitaiShosai={baitaiShosai}
                      setBaitaiShosai={setBaitaiShosai}
                      label="画面名 *"
                      kanri_only_flg={true}
                    />
                    {errorMessageBaitaiShosai && (
                      <small className={"error-message"}>{errorMessageBaitaiShosai}</small>
                    )}
                    <SimpleDatePicker
                      date={haishinbi}
                      setDate={setHaishinbi}
                      label="配信日 *"
                    />
                    {errorMessageHaishinbi && (
                      <small style={{ color: "red", marginLeft: "16px" }}>{errorMessageHaishinbi}</small>
                    )}
                    <SimpleTimePicker
                      time={haishinKaishiJikan}
                      setTime={setHaishinKaishiJikan}
                      label="配信時間開始"
                      viewMode={1}
                    />
                    {errorMessageHaishinKaishiJikan && (
                      <small style={{ color: "red", marginLeft: "16px" }}>{errorMessageHaishinKaishiJikan}</small>
                    )}
                    <SimpleTimePicker
                      time={haishinShuryoJikan}
                      setTime={setHaishinShuryoJikan}
                      label="配信時間終了"
                      viewMode={1}
                    />
                    {errorMessageHaishinShuryoJikan && (
                      <small style={{ color: "red", marginLeft: "16px" }}>{errorMessageHaishinShuryoJikan}</small>
                    )}
                    <FormGroup>
                      <FormControlLabel control={
                        <Switch
                          value={isOnlyErrorData}
                          defaultChecked
                          onChange={e => setIsOnlyErrorData((e.target.value === "true") ? false : true)}
                        />} label="異常データのみ表示する" />
                    </FormGroup>

                    <LoadingButton
                      loading={load}
                      type="button"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      onClick={() => handleSearch()}
                    >
                      検索
                    </LoadingButton>
                  </Box >
                </Paper >
              </Grid >
              {/* 一覧 */}
              < Grid item xs={9} md={8} lg={9} pl={0} >
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '75vh',
                    maxWidth: '80vw'
                  }}
                >
                  <Grid container spacing={1}>
                    {/* タイトル */}
                    <Grid item xs={6} pl={0} mb={1}>
                      <Box
                        display='flex'
                        alignItems='center'
                        height='100%'>
                        <Typography component="h1" variant="h5">
                          点灯証明写真ログ
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <TentoShomeiList searchParam={searchParam} setload={setLoad} />
                </Paper>
              </Grid >
            </Grid >
            <Copyright />
          </Container >
        </Box >
      </Box >
    </ThemeProvider >
  );
}