import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { ExcelBangumihyoHaishinbData, ExcelBangumihyoHizukeDataSet } from "../../types/WebData";
import moment from "moment";
import { Content } from './Preview/data';

type Props = {
  contents: Content[];
  bangumiHaisinkaishibi: string;
  bangumihyoHizuke: ExcelBangumihyoHizukeDataSet;
  setBangumihyoHizuke: React.Dispatch<React.SetStateAction<ExcelBangumihyoHizukeDataSet>>;
  label?: string;
};

/**
 * 番組表日付セレクトボックスコンポーネント
 *
 * @param {Props} { contents, bangumiHaisinkaishibi, bangumihyoHizuke, setBangumihyoHizuke, label }
 * @return {*} 
 */
const BangumihyoHizukeSelect = ({ contents, bangumiHaisinkaishibi, bangumihyoHizuke, setBangumihyoHizuke, label = "配信日" }: Props) => {
  const [bangumihyohizukeData, setBangumihyohizukeData] = useState<ExcelBangumihyoHaishinbData>({ data: [] });

  // 初期化処理
  useEffect(() => {
    let haishinKaishibiList: ExcelBangumihyoHizukeDataSet[] = [];
    let hizukeList: string[] = [];
    let unSetFlg: boolean = false;
    let indexId: number = 0;
    setBangumihyohizukeData({ data: [] });
    const getBangumihyoHizukeData = async () => {

      contents.forEach((content) => {
        // 配信開始日
        if (content.haishin_kaishibi <= bangumiHaisinkaishibi) {
          if (!hizukeList.some((haishinKaishibi) => haishinKaishibi === bangumiHaisinkaishibi)) {
            hizukeList.push(bangumiHaisinkaishibi)
          }
        } else {
          if (!hizukeList.some((haishinKaishibi) => haishinKaishibi === content.haishin_kaishibi)) {
            hizukeList.push(content.haishin_kaishibi)
          }
        }
        // 配信終了日
        if (content.haishin_shuryobi && content.haishin_shuryobi >= bangumiHaisinkaishibi) {
          const haishinShuryobi = moment(content.haishin_shuryobi).add(1, 'd').format('YYYY/MM/DD')
          if (!hizukeList.some((haishinKaishibi) => haishinKaishibi === haishinShuryobi)) {
            hizukeList.push(haishinShuryobi)
          }
        }
        if (content.haishin_shuryobi === '') {
          unSetFlg = true;
        }
      })
      hizukeList.sort(((a, b) => { return (a > b ? 1 : -1); }))

      hizukeList.forEach((hizuke) => {
        if ((hizukeList.length !== indexId + 1)) {
          haishinKaishibiList.push({ id: indexId, bangumihyo_haishinbi: hizuke })
          indexId++
        } else if (unSetFlg) {
          haishinKaishibiList.push({ id: indexId, bangumihyo_haishinbi: hizuke })
        }
      })
    };

    getBangumihyoHizukeData();
    setBangumihyohizukeData({ data: haishinKaishibiList })
  }, []);

  const handleChange = (event: SelectChangeEvent) => {
    const bangumihyohizuke = bangumihyohizukeData.data.find(bangumihyohizuke => event.target.value === String(bangumihyohizuke?.id))
    if (!bangumihyohizuke) return;
    setBangumihyoHizuke(bangumihyohizuke);
  };

  return (
    <Box sx={{ minWidth: 200 }} mt={1} ml={119}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label" >{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={(bangumihyoHizuke) ? String(bangumihyoHizuke.id) : "0"}
          label={label}
          onChange={handleChange}
        >
          {bangumihyohizukeData.data.map((bangumihyoHizuke) => {
            return (
              <MenuItem key={bangumihyoHizuke.id} value={String(bangumihyoHizuke.id)} sx={{ height: "36px" }}>
                {moment(bangumihyoHizuke.bangumihyo_haishinbi).format('YYYY/MM/DD')}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}

export default BangumihyoHizukeSelect;