import {
  Box,
  Card,
  CardContent,
} from "@mui/material";
import { Error, Warning } from '@mui/icons-material';

import moment from "moment";

import Thumbnail from "../Common/Thumbnail";
import { TentoShomeiDataSet } from "../../types/WebData";
import { TENTO_SHOMEI_STATUS } from "../../const/index";

import TentoShomeiListModule from './TentoShomeiList.module.css';

interface Props {
  tentoShomei: TentoShomeiDataSet;
}

/**
 * 点灯証明写真リストアイテムコンポーネント
 *
 * @param {Props} { tentoShomei }
 * @return {*} 
 */
const TentoShomeiListItem = ({ tentoShomei }: Props) => {

  return (
    <Box sx={{ display: "flex" }}>
      <Card>
        <CardContent sx={{ padding: '7px', paddingBottom: '0px !important' }}>
          <div>
            <Thumbnail src={tentoShomei.judge_file_path} height={'240px'} width={'320px'} />
            <div className={TentoShomeiListModule['thumbnail-text']}>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: 'end' }}>
                <p>{`撮影時間 ${moment(tentoShomei.satsuei_dt).format('HH時mm分ss秒')}`}</p>
              </Box>
              {(tentoShomei.status === TENTO_SHOMEI_STATUS.NG_GIWAKU) ?
                <p style={{ display: "flex", alignItems: "center" }}><Warning color="warning" />異常の可能性あり</p>
                : (tentoShomei.status === TENTO_SHOMEI_STATUS.IJO) ?
                  <p style={{ display: "flex", alignItems: "center" }}><Error color="error" />異常あり</p>
                  :
                  <p style={{ display: "flex", alignItems: "center" }}>　</p>
              }
            </div>
          </div>
        </CardContent>
      </Card>
    </Box >
  );
}

export default TentoShomeiListItem;