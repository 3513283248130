import { useState, useContext, useRef } from 'react';
import axios from "axios";

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { Upload } from '@mui/icons-material';

import { getJackContent, putJackContentUpload, putJackContentUploadStatus } from "../../api/jackContent";
import { ReloadContext } from "../../context/ReloadContext";
import FileUpload from '../Common/FileUpload';
import { JackContentUploadPostData } from "../../types/WebData";
import { JACK_UPLOAD_STATUS, ALLOW_MIME_TYPES, HTTP_STATUS_CODE } from "../../const/index";
import { SYSTEM_ERROR } from "../../const/message";
import LoadingButton from '@mui/lab/LoadingButton';

type Props = {
  contentId: number;
  contentFileMei: string;
  contentByosu: number;
  jackId: number;
  kaizodoId: number;
};

/**
 * ジャックコンテンツアップロードダイアログコンポーネント
 *
 * @param {Props} { contentId, contentFileMei, contentByosu }
 * @return {*} 
 */
const JackUploadDialog = ({ contentId, contentFileMei, contentByosu, jackId, kaizodoId }: Props) => {
  const reloadContext = useContext(ReloadContext);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [uploadFile, setUploadFile] = useState<File | null>(null);
  const [open, setOpen] = useState(false);
  // 処理中フラグ（ボタン連打対策）
  const processing = useRef(false);
  const [load, setLoad] = useState<boolean>(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  /**
   * ブラウザのアラートダイアログを表示する
   *
   * @param {*} e
   */
  const onUnload = (e: any) => {
    // イベントをキャンセルする
    e.preventDefault();
    // Chrome では returnValue を設定する必要がある
    e.returnValue = '';
  };

  /**
   * アップロード開始時処理
   */
  const onUploadStart = () => {
    // アップロード中フラグON
    reloadContext?.setUploadingInfo({ isUploading: true });
    // アップロード中にブラウザ×閉じ押したらダイアログで警告イベントをセット
    window.addEventListener('beforeunload', onUnload, false);
  };

  /**
   * アップロード終了時処理
   */
  const onUploadEnd = () => {
    // アップロード中フラグOFF
    reloadContext?.setUploadingInfo({ isUploading: false });
    // アップロード中にブラウザ×閉じ押したらダイアログで警告イベントを解除
    window.removeEventListener('beforeunload', onUnload, false);
  };

  const handleUpdate = async () => {
    if (!uploadFile) {
      return setErrorMessage("ファイルを選択してください。");
    }
    const content = (await getJackContent(contentId)).data[0];
    if (content.content_file_mei && content.upload_status === JACK_UPLOAD_STATUS.UPLOADING) {
      return reloadContext?.setSnackbarInfo({
        isOpen: true,
        type: "error",
        message: "現在アップロード中です。\nアップロードが終わるまでしばらくお待ちください。",
      });
    };
    if (!isAllowedMimeType(uploadFile.type)) {
      return reloadContext?.setSnackbarInfo({
        isOpen: true,
        type: "error",
        message: "拡張子が mp4, mpg, jpg, jpeg, png 以外のファイルはアップロードできません。",
      });
    };

    // 処理中(true)なら非同期処理せずに抜ける
    if (processing.current) return;
    // 処理中フラグを上げる
    processing.current = true;
    // 疑似非同期処理
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 1000);
    setLoad(true);

    // アップロード処理
    const contentUploadPostData: JackContentUploadPostData = {
      id: contentId,
      jack_id: jackId,
      kaizodo_id: kaizodoId,
      content_file_mei: uploadFile ? uploadFile.name : "",
      content_file_mime_type: uploadFile ? uploadFile.type : "",
      is_create: contentFileMei ? false : true,
    }

    // 署名付きURLを取得
    const result = await putJackContentUpload(contentUploadPostData);
    reloadContext?.setReload(reloadContext?.reload + 1);
    if (result.isError) {
      setLoad(false);
      return reloadContext?.setSnackbarInfo({
        isOpen: true,
        type: "error",
        message: result.message,
      });
    }

    // アップロード開始時処理
    onUploadStart();
    let resultUploadS3;

    try {
      // S3アップロード処理、アップロードステータス更新処理呼び出し
      resultUploadS3 = await uploadS3(result.newContentId, result.signedUrlContent);
    } catch (error) {
      // アップロードステータス失敗に更新
      await putJackContentUploadStatus(result.newContentId, JACK_UPLOAD_STATUS.UPLOAD_FAILED);
      return reloadContext?.setSnackbarInfo({
        isOpen: true,
        type: `error`,
        message: SYSTEM_ERROR,
      });
    } finally {
      // アップロード終了時処理
      setLoad(false);
      onUploadEnd();
      reloadContext?.setReload(reloadContext?.reload + 1);
    }

    reloadContext?.setSnackbarInfo({
      isOpen: true,
      type: resultUploadS3 === JACK_UPLOAD_STATUS.UPLOAD_SUCCESS ? `success` : `error`,
      message: resultUploadS3 === JACK_UPLOAD_STATUS.UPLOAD_SUCCESS ? `アップロードが完了しました。` : `アップロードに失敗しました。`,
    });
    return handleClose();
  }

  /**
   * S3アップロード処理
   * 署名付きURLに対してアップロードを行う
   * アップロード結果に応じて、アップロードステータス更新を行う
   *
   * @param {number} contentId
   * @param {string} signedUrlContent
   * @return {number} 
   */
  const uploadS3 = async (contentId: number, signedUrlContent: string): Promise<number> => {
    // コンテンツ
    const resultUploadContent = await axios.put(
      signedUrlContent,
      uploadFile,
      {
        headers: {
          'Content-Type': uploadFile?.type,
        },
      }
    );
    const uploadStatus = (resultUploadContent?.status === HTTP_STATUS_CODE.OK) ? JACK_UPLOAD_STATUS.UPLOAD_SUCCESS : JACK_UPLOAD_STATUS.UPLOAD_FAILED;

    const resultContentUploadStatus = await putJackContentUploadStatus(contentId, uploadStatus);
    return (resultContentUploadStatus?.status === HTTP_STATUS_CODE.OK) ? uploadStatus : JACK_UPLOAD_STATUS.UPLOAD_FAILED;
  }

  const isAllowedMimeType = (mimeType: string) => {
    // 許可配列から一致する値を検索
    return (ALLOW_MIME_TYPES.includes(mimeType.toLowerCase()));
  }

  return (
    <Box sx={{ textAlign: 'right', display: 'flex' }}>
      <Upload color="primary" fontSize='large'
        onClick={() => handleClickOpen()}
      />
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            width: "50vw",
            Height: "30vh"
          }
        }}
      >
        <Typography component="h3" variant="subtitle1" mt={2} ml={2}>
          コンテンツアップロード
        </Typography>
        {errorMessage && (
          <small style={{ color: "red", marginLeft: "16px" }}>{errorMessage}</small>
        )}
        <DialogContent>
          <FileUpload contentFileMei={contentFileMei} setUploadFile={setUploadFile} contentByosu={contentByosu} />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>閉じる</Button>
          <LoadingButton
            loading={load}
            variant="contained"
            onClick={handleUpdate}
            disabled={(!uploadFile)}
          >
            登録
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box >
  );
}

export default JackUploadDialog;