import React, { useEffect, useState, useContext } from "react";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { AuthInfoContext } from "../../context/AuthContext";
import { getLoginUser } from "../../api/loginuser";
import { UserSettingData, UserSettingDataSet } from "../../types/WebData";
import { ReloadContext } from "../../context/ReloadContext";

type Props = {
  loginUser: UserSettingDataSet | null;
  setLoginUser: React.Dispatch<React.SetStateAction<UserSettingDataSet | null>>;
  label?: string;
  disabled?: boolean;
};

/**
 * ログイン担当者セレクトボックスコンポーネント
 *
 * @param {Props} { loginUser, setLoginUser, label, disabled = false }
 * @return {*} 
 */
const LoginUserSelect = ({ loginUser, setLoginUser, label = "氏名", disabled = false }: Props) => {
  const [authInfo] = useContext(AuthInfoContext);
  const reloadContext = useContext(ReloadContext);
  const [loginUserData, setLoginUserData] = useState<UserSettingData>({ data: [] });

  // 初期化処理
  useEffect(() => {
    let isMounted = true;
    const getLoginUserData = async () => {
      const result = await getLoginUser(loginUser ? loginUser.id : authInfo.id, authInfo.kengen);
      if (result.isError) {
        return reloadContext?.setSnackbarInfo({
          isOpen: true,
          type: "error",
          message: result.message,
        });
      }
      setLoginUserData(result);
    };
    if (isMounted) getLoginUserData();
    return () => { isMounted = false };
  }, []);

  const handleChange = (event: SelectChangeEvent) => {
    const loginUser = loginUserData.data.find(loginUser => event.target.value === String(loginUser.id))
    if (!loginUser) return;
    setLoginUser(loginUser);
  };

  return (
    <Box sx={{ minWidth: 120 }} mt={1}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={(loginUser) ? String(loginUser.id) : String(authInfo.id)}
          label={label}
          onChange={handleChange}
          disabled={disabled}
        >
          {loginUserData.data.map((loginUser) => {
            return (
              <MenuItem key={loginUser.id} value={String(loginUser.id)} sx={{ height: "36px" }}>
                {loginUser.shimei}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}

export default LoginUserSelect;