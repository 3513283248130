import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { KIKI_HIMODUKESAKI_CODE, KikiHimozukesakiCode } from "../../const/index";

type Props = {
    kikiHimozukesakiCode: KikiHimozukesakiCode | null;
    setKikiHimozukesakiCode: React.Dispatch<React.SetStateAction<KikiHimozukesakiCode | null>>;
    label?: string;
    disabled?: boolean;
};

/**
 * 機器紐づけ先コードセレクトボックスコンポーネント
 *
 * @param {Props} { kikiHimozukesakiCode, setKikiHimozukesakiCode, label, disabled = false }
 * @return {*} 
 */
const KikiHimozukesakiCodeSelect = ({ kikiHimozukesakiCode, setKikiHimozukesakiCode, label = "機器紐づけ先", disabled = false }: Props) => {
    const [kikiHimozukesakiCodeData, setKikiHimozukesakiCodeData] = useState<any[]>([]);

    // 初期化処理
    useEffect(() => {
        let isMounted = true;
        const getKikiKatabanData = async () => {
            setKikiHimozukesakiCodeData([KIKI_HIMODUKESAKI_CODE.BAITAI_SHOSAI, KIKI_HIMODUKESAKI_CODE.BAITAI]);
        };
        if (isMounted) getKikiKatabanData();
        return () => { isMounted = false };
    }, []);

    const handleChange = (event: SelectChangeEvent) => {
        const kikiHimozukesakiCode = kikiHimozukesakiCodeData.find(kikiHimozukesakiCode => event.target.value === String(kikiHimozukesakiCode.code))
        if (!kikiHimozukesakiCode) return;
        setKikiHimozukesakiCode(kikiHimozukesakiCode);
    };

    return (
        <Box sx={{ minWidth: 120 }} mt={1}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label" >{label}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={(kikiHimozukesakiCode) ? String(kikiHimozukesakiCode.code) : ""}
                    label={label}
                    onChange={handleChange}
                    disabled={disabled}
                >
                    {kikiHimozukesakiCodeData.map((kikiHimozukesakiCodeData) => {
                        return (
                            <MenuItem key={kikiHimozukesakiCodeData.code} value={String(kikiHimozukesakiCodeData.code)} sx={{ height: "36px" }}>
                                {kikiHimozukesakiCodeData.name}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </Box>
    );
}

export default KikiHimozukesakiCodeSelect;