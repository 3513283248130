import { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import {
  Box,
  Typography,
  Container,
} from "@mui/material";

import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { Copyright } from '../components/Common/Copyright';
import { ReloadContext } from "../context/ReloadContext";
import { putPasswordReSetting } from "../api/loginuser";
import LoadingButton from '@mui/lab/LoadingButton';

const md5 = require("md5");

interface State {
  password: string;
  showPassword: boolean;
}

/**
 * パスワード再設定画面コンポーネント
 *
 * @return {*} 
 */
export const PasswordReSetting = () => {
  const navigate = useNavigate();
  const reloadContext = useContext(ReloadContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [load, setLoad] = useState<boolean>(false);
  const [values, setValues] = useState<State>({
    password: '',
    showPassword: false,
  });
  const [confirmationValues, setConfirmationValues] = useState<State>({
    password: '',
    showPassword: false,
  });
  // 処理中フラグ（ボタン連打対策）
  const processing = useRef(false);

  // クエリストリングから担当者IDを取得
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const userId = query.get('userId');

  useEffect(() => {
    if (!userId) {
      reloadContext?.setSnackbarInfo({
        isOpen: true,
        type: "error",
        message: "不正な画面遷移です。",
      });
      navigate("/"); // 画面遷移
    }
  }, []);

  const handleClickRegister = async () => {
    // バリデーションチェック
    setErrorMessage("");
    if (!userId) return;
    if (!values.password) {
      return setErrorMessage("パスワードを入力して下さい。");
    }
    if (values.password.length < 4) {
      return setErrorMessage("4文字以上で入力してください。");
    }
    if (!values.password.match(/^[A-Za-z0-9]*$/)) {
      return setErrorMessage("半角英数字のみ使用可能です。");
    }
    if (!confirmationValues.password) {
      return setErrorMessage("確認用パスワードを入力して下さい。");
    }
    if (values.password !== confirmationValues.password) {
      return setErrorMessage("確認用パスワードが一致していません。");
    }

    // 処理中(true)なら非同期処理せずに抜ける
    if (processing.current) return;
    // 処理中フラグを上げる
    processing.current = true;
    // 疑似非同期処理
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 1000);
    setLoad(true);
    const postData = {
      user_id: userId,
      password: md5(values.password),
    };
    const result = await putPasswordReSetting(postData);
    reloadContext?.setSnackbarInfo({
      isOpen: true,
      type: result.isError ? "error" : "success",
      message: result.message,
    });
    setLoad(false);
    navigate("/"); // 画面遷移
  }

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleChangeConfirmation =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setConfirmationValues({ ...confirmationValues, [prop]: event.target.value });
    };
  const handleClickShowConfirmationPassword = () => {
    setConfirmationValues({
      ...confirmationValues,
      showPassword: !confirmationValues.showPassword,
    });
  };

  const theme = createTheme();

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main"
        sx={{
          marginTop: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: "60vw",
          height: "40vh",
          p: 2
        }}
      >
        <CssBaseline />
        <Box>
          <Typography component="h3" variant="h6" mt={1} ml={2}>
            パスワード再設定
          </Typography>
          <Typography component="h3" variant="subtitle2" mt={2} ml={2}>
            登録ボタンを押して、パスワードを再設定します。
          </Typography>
          <Typography component="h3" variant="subtitle2" mb={2} ml={2}>
            再設定後、ログイン画面から再度ログインしてください。
          </Typography>


          <Box sx={{ paddingTop: 0 }}>
            <FormControl sx={{ mt: 1 }} fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">新しいパスワード *</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={values.showPassword ? 'text' : 'password'}
                label="新しいパスワード *"
                value={values.password}
                required
                onChange={handleChange('password')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl sx={{ mt: 2 }} fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">新しいパスワード(確認用) *</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={confirmationValues.showPassword ? 'text' : 'password'}
                label="新しいパスワード(確認用) *"
                value={confirmationValues.password}
                required
                onChange={handleChangeConfirmation('password')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmationPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {confirmationValues.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box>
          <p>
            <span className={"error-message"}>{errorMessage}</span>
          </p>
          <Box mt={2} textAlign="right">
            <LoadingButton loading={load} variant="contained" onClick={handleClickRegister}>登録</LoadingButton>
          </Box>
          <Copyright />
        </Box>
      </Container>
    </ThemeProvider >
  );
}