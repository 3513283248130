import React, { useEffect, useState, useContext } from "react";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { ReloadContext } from "../../context/ReloadContext";
import { postSearchKaisha } from "../../api/kaisha";
import { KaishaData, KaishaDataSet } from "../../types/WebData";

type Props = {
    kaisha: KaishaDataSet | null;
    setKaisha: React.Dispatch<React.SetStateAction<KaishaDataSet | null>>;
    label?: string;
    setHenkoFlg?: React.Dispatch<React.SetStateAction<boolean>>;
    disabled?: boolean;
};

/**
 * 会社セレクトボックスコンポーネント
 *
 * @param {Props} { kaisha, setKaisha, label, setHenkoFlg, disabled = false }
 * @return {*} 
 */
const KaishaSelect = ({ kaisha, setKaisha, label = "会社名", setHenkoFlg, disabled = false }: Props) => {
    const reloadContext = useContext(ReloadContext);
    const [kaishaData, setKaishaData] = useState<KaishaData>({ data: [] });

    // 初期化処理
    useEffect(() => {
        let isMounted = true;
        setKaishaData({ data: [] });
        const getKaishaData = async () => {
            const searchParam = { id: kaisha?.id, jusho: "", kaisha_mei: "", is_visible: false };
            const result = await postSearchKaisha(searchParam);
            if (result.isError) {
                return reloadContext?.setSnackbarInfo({
                    isOpen: true,
                    type: "error",
                    message: result.message,
                });
            }
            const blank = { id: 0 } as KaishaDataSet;
            result.data.unshift(blank);
            setKaishaData(result);
        };
        if (isMounted) getKaishaData();
        return () => { isMounted = false };
    }, []);

    const handleChange = (event: SelectChangeEvent) => {
        const kaisha = kaishaData.data.find(kaisha => event.target.value === String(kaisha.id))
        if (!kaisha) return;
        if (setHenkoFlg) setHenkoFlg(true);
        setKaisha(kaisha);
    };

    return (
        <Box sx={{ minWidth: 120 }} mt={1}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label" >{label}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={(kaisha) ? String(kaisha.id) : ""}
                    label={label}
                    onChange={handleChange}
                    disabled={disabled}
                >
                    {kaishaData.data.map((kaishaData) => {
                        return (
                            <MenuItem key={kaishaData.id} value={String(kaishaData.id)} sx={{ height: "36px" }}>
                                {kaishaData.kaisha_mei}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </Box>
    );
}

export default KaishaSelect;