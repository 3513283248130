import React, { useEffect, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';

import {
  Box,
  Button,
  Typography
} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import moment from "moment";
import Item from './Item';
import Draggable from './Draggable';
import { Item as _Item, ItemWithIndex, GroupType, ItemTypes, TitleMap, MoveHandler } from '../Common/Preview/data';
import DogaSaiseiDialog from '../Common/Preview/DogaSaiseiDialog';

import GroupModule from './Group.module.css';
import { BangumihyoShosaiProps, BangumihyoShosaiScroll } from "../../types/WebData";

const Group: React.FC<{
  bangumiItems: _Item[];
  items: _Item[];
  state: BangumihyoShosaiProps
  groupType: GroupType;
  firstIndex: number;
  onMove: MoveHandler;
  onMoveGroup: MoveHandler;
  draggingItem: _Item | null;
  setDraggingItem: React.Dispatch<React.SetStateAction<_Item | null>>;
  isDragging: boolean;
  setIsDragging: React.Dispatch<React.SetStateAction<boolean>>;
  bangumiSelectedId: number;
  setBangumiSelectedId: React.Dispatch<React.SetStateAction<number>>;
  contentSelectedId: number;
  setContentSelectedId: React.Dispatch<React.SetStateAction<number>>;
  bangumihyoShosaiScroll: BangumihyoShosaiScroll | null;
  setBangumihyoShosaiScroll: React.Dispatch<React.SetStateAction<BangumihyoShosaiScroll | null>>;
}> = ({
  bangumiItems,
  items,
  groupType,
  firstIndex,
  onMove,
  onMoveGroup,
  state,
  draggingItem,
  setDraggingItem,
  isDragging,
  setIsDragging,
  bangumiSelectedId,
  setBangumiSelectedId,
  contentSelectedId,
  setContentSelectedId,
  bangumihyoShosaiScroll,
  setBangumihyoShosaiScroll }) => {
    const bangumihyoRef = useRef<HTMLUListElement>(null);

    let beforeIndex = 0;
    const contents = items.map(i => i.content);
    const [, ref] = useDrop({
      accept: ItemTypes,
      hover(dragItem: ItemWithIndex) {
        const dragIndex = dragItem.index;
        if (beforeIndex === dragIndex && (items.find(i => i.id === dragIndex)?.group === groupType && items.length !== 0)) {
          return
        }
        beforeIndex = dragIndex;
        if (dragItem.group === groupType) return;
        const targetIndex = dragIndex < firstIndex ?
          // forward
          firstIndex + items.length - 1 :
          // backward
          firstIndex + items.length;
        if (draggingItem) { // setStateが間に合っていない場合は無処理とする
          onMove(dragIndex, targetIndex, groupType);
          dragItem.index = targetIndex;
          dragItem.group = groupType;
        }
      },
      drop: () => {
        setDraggingItem(null)
      }
    });

    // ドラッグ時に選択状態を解除する
    useEffect(() => {
      setBangumiSelectedId(0);
      setContentSelectedId(0);
    }, [isDragging]);

    // コンテンツ移動時に画面を追随する
    useEffect(() => {
      if (!bangumihyoShosaiScroll || groupType === 'content') return;
      scrollView(bangumihyoShosaiScroll)
      setBangumihyoShosaiScroll(null);
    }, [items]);

    // スクロール処理を行いコンテンツに追従する
    const scrollView = (bangumihyoShosaiScroll: BangumihyoShosaiScroll) => {
      const width = bangumihyoRef.current?.scrollWidth;
      if (width) {
        const cardWidth = 244; // カード自体のサイズ
        const cardMarginWidth = 20; // カードの左右のマージン
        const windowWidth = window.innerWidth; // 番組表エリアのウインドウ全体のサイズ
        const paddingWidth = 16 * 2; // pages/bangumihyoShosai.tsxのパディング(白い部分)
        const previewButtonWidth = 100; // 番組再生ボタンのサイズ
        const previewButtonMarginWidth = 10 * 2; // 番組再生ボタンの左右のマージン
        const previewAreaWidth = previewButtonWidth + previewButtonMarginWidth;
        const viewWidth = windowWidth * 0.8 - paddingWidth - previewAreaWidth; // 番組表エリアの表示領域のサイズ
        const itemWidth = cardWidth + cardMarginWidth; // カードとカードのマージンを足したサイズ
        const scrollWidth = itemWidth * (bangumihyoShosaiScroll.targetIndex + 1) - (viewWidth * 0.5); // 表示領域の真ん中を超える場合はスクロール
        bangumihyoRef.current?.scrollTo(scrollWidth, 0);
      }
    }
    const moveFirst = () => {
      if (!bangumiSelectedId) return;
      let selectedIndex = 0;
      for (let index = 0; index < items.length; index++) {
        if (items[index].id === bangumiSelectedId) {
          selectedIndex = index;
          break;
        }
      }
      // 先頭の要素を選択した場合、処理スキップ
      if (selectedIndex === 0) return setBangumihyoShosaiScroll({ targetIndex: 0, direction: 'left' })

      const targetIndex = 0;
      onMove(selectedIndex, targetIndex, groupType);
      setBangumihyoShosaiScroll({ targetIndex: 0, direction: 'left' })
    }
    const moveLeft10 = () => {
      if (!bangumiSelectedId) return;
      let selectedIndex = 0;
      for (let index = 0; index < items.length; index++) {
        if (items[index].id === bangumiSelectedId) {
          selectedIndex = index;
          break;
        }
      }
      // 先頭の要素を選択した場合、処理スキップ
      if (selectedIndex === 0) return;
      const targetIndex = (selectedIndex > 10) ? selectedIndex - 10 : 0;
      onMove(selectedIndex, targetIndex, groupType);
      setBangumihyoShosaiScroll({ targetIndex: targetIndex, direction: 'left' })
    }
    const moveLeft = () => {
      if (!bangumiSelectedId) return;
      let selectedIndex = 0;
      for (let index = 0; index < items.length; index++) {
        if (items[index].id === bangumiSelectedId) {
          selectedIndex = index;
          break;
        }
      }
      // 先頭の要素を選択した場合、処理スキップ
      if (selectedIndex === 0) return;
      const targetIndex = selectedIndex - 1;
      onMove(selectedIndex, targetIndex, groupType);
      setBangumihyoShosaiScroll({ targetIndex: targetIndex, direction: 'left' })
    }
    const moveRight = () => {
      if (!bangumiSelectedId) return;
      let selectedIndex = 0;
      for (let index = 0; index < items.length; index++) {
        if (items[index].id === bangumiSelectedId) {
          selectedIndex = index;
          break;
        }
      }
      // 末尾の要素を選択した場合、処理スキップ
      if (selectedIndex === items.length - 1) return;
      const targetIndex = selectedIndex + 1;
      onMove(selectedIndex, targetIndex, groupType);
      setBangumihyoShosaiScroll({ targetIndex: targetIndex, direction: 'right' })
    }
    const moveRight10 = () => {
      if (!bangumiSelectedId) return;
      let selectedIndex = 0;
      for (let index = 0; index < items.length; index++) {
        if (items[index].id === bangumiSelectedId) {
          selectedIndex = index;
          break;
        }
      }
      // 末尾の要素を選択した場合、処理スキップ
      if (selectedIndex === items.length - 1) return setBangumihyoShosaiScroll({ targetIndex: items.length, direction: 'right' })
      const targetIndex = (selectedIndex < (items.length - 11)) ? selectedIndex + 10 : items.length - 1;
      onMove(selectedIndex, targetIndex, groupType);
      setBangumihyoShosaiScroll({ targetIndex: targetIndex, direction: 'right' })
    }
    const moveLast = () => {
      if (!bangumiSelectedId) return;
      let selectedIndex = 0;
      for (let index = 0; index < items.length; index++) {
        if (items[index].id === bangumiSelectedId) {
          selectedIndex = index;
          break;
        }
      }
      // 末尾の要素を選択した場合、処理スキップ
      if (selectedIndex === items.length - 1) return setBangumihyoShosaiScroll({ targetIndex: items.length, direction: 'right' })
      const targetIndex = items.length - 1;
      onMove(selectedIndex, targetIndex, groupType);
      setBangumihyoShosaiScroll({ targetIndex: 99999, direction: 'right' })
    }
    // コンテンツエリアから全コンテンツを番組表エリアに移動する
    const moveAllLeft = () => {
      for (let index = items.length - 1; index >= 0; index--) {
        onMoveGroup(index, 0, 'bangumihyo');
      }
      setBangumihyoShosaiScroll({ targetIndex: 0, direction: 'left' })
      // コンテンツエリアの選択状態を解除
      setContentSelectedId(0);
    }
    // コンテンツエリアから全コンテンツを番組表エリアに移動する
    const moveAllRight = () => {
      const targetIndex = bangumiItems.length;

      for (let index = items.length - 1; index >= 0; index--) {
        onMoveGroup(index, targetIndex, 'bangumihyo');
      }
      setBangumihyoShosaiScroll({ targetIndex: 99999, direction: 'right' })
      // コンテンツエリアの選択状態を解除
      setContentSelectedId(0);
    }
    // コンテンツエリアで選択したコンテンツを番組表で選択したコンテンツの次に移動する
    const moveNext = () => {
      if (!contentSelectedId || !bangumiSelectedId) return;
      // 移動するコンテンツのindex判定
      let selectedIndex = 0;
      for (let index = 0; index < items.length; index++) {
        if (items[index].id === contentSelectedId) {
          selectedIndex = index;
          break;
        }
      }
      // 移動先のコンテンツのindex判定
      let bangumiSelectedIndex = 0;
      for (let bangumiIndex = 0; bangumiIndex < bangumiItems.length; bangumiIndex++) {
        if (bangumiItems[bangumiIndex].id === bangumiSelectedId) {
          bangumiSelectedIndex = bangumiIndex;
          break;
        }
      }
      const targetIndex = bangumiSelectedIndex + 1;

      onMoveGroup(selectedIndex, targetIndex, bangumiItems[bangumiSelectedIndex].group);
      setBangumihyoShosaiScroll({ targetIndex: targetIndex, direction: 'left' })
      // コンテンツエリアの選択状態を解除
      setContentSelectedId(0);
    }

    const handleDragStart = () => {
      setBangumiSelectedId(0);
      setContentSelectedId(0);
    }

    return (
      <div>
        <div className={GroupModule['hedding']}>
          <span className={GroupModule['count']}>{items.length}</span>
          <Typography component="h3" variant="h5" mb={1} sx={{
            display: "inline-block",
            width: '200px',
            background: 'linear-gradient(transparent 60%, #a8eaff 100%)'
          }}>
            {TitleMap[groupType]}
          </Typography>
          {
            groupType === "bangumihyo" ?
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexGrow: '1' }}>
                <Typography component="h3" variant="h6" ml={2}>
                  合計再生時間：{items.reduce((sum, item) => sum + item.content.content_byosu, 0)}秒
                </Typography>
                <div>
                  <Box
                    display="flex"
                    alignItems="center">
                    <Tooltip title="一番左に移動する" placement='top'>
                      <Button className={GroupModule['arrow-button']} variant="contained" onClick={() => moveFirst()}>
                        <KeyboardDoubleArrowDownIcon fontSize="large" sx={{ transform: "rotate(90deg)" }} />
                      </Button>
                    </Tooltip>
                    <Tooltip title="10個左に移動する" placement='top'>
                      <Button className={GroupModule['arrow-button']} variant="contained" onClick={() => moveLeft10()}>
                        <Box sx={{ mr: 2, height: '35px' }}>
                          <KeyboardArrowDownIcon fontSize="large" sx={{ transform: "rotate(90deg)" }} />
                        </Box>
                        <Box sx={{ position: 'absolute', left: '25px' }}>
                          10
                        </Box>
                      </Button>
                    </Tooltip>
                    <Tooltip title="左に移動する" placement='top'>
                      <Button className={GroupModule['arrow-button']} variant="contained" onClick={() => moveLeft()}>
                        <KeyboardArrowDownIcon fontSize="large" sx={{ transform: "rotate(90deg)" }} />
                      </Button>
                    </Tooltip>
                    <Tooltip title="右に移動する" placement='top'>
                      <Button className={GroupModule['arrow-button']} variant="contained" onClick={() => moveRight()}>
                        <KeyboardArrowUpIcon fontSize="large" sx={{ transform: "rotate(90deg)" }} />
                      </Button>
                    </Tooltip >
                    <Tooltip title="10個右に移動する" placement='top'>
                      <Button className={GroupModule['arrow-button']} variant="contained" onClick={() => moveRight10()} sx={{ justifyContent: 'left' }}>
                        <Box sx={{ height: '35px', display: 'flex', alignItems: 'center' }}>
                          10
                        </Box>
                        <Box sx={{ position: 'absolute', left: '18px', display: 'flex' }}>
                          <KeyboardArrowUpIcon fontSize="large" sx={{ transform: "rotate(90deg)" }} />
                        </Box>
                      </Button>
                    </Tooltip>
                    <Tooltip title="一番右に移動する" placement='top'>
                      <Button className={GroupModule['arrow-button']} variant="contained" onClick={() => moveLast()}>
                        <KeyboardDoubleArrowUpIcon fontSize="large" sx={{ transform: "rotate(90deg)" }} />
                      </Button>
                    </Tooltip>
                  </Box>
                </div>
              </div>
              :
              <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center', flexGrow: '1' }}>
                <div>
                  <Box
                    display="flex"
                    alignItems="center">
                    <Tooltip title="全コンテンツを番組表エリアの先頭に移動する" placement='top'>
                      <Button className={GroupModule['arrow-button']} variant="contained" onClick={() => moveAllLeft()}>
                        <KeyboardDoubleArrowDownIcon fontSize="large" sx={{ transform: "rotate(150deg)" }} />
                      </Button>
                    </Tooltip>
                    <Tooltip title="番組表エリアで選択したコンテンツの次に移動する" placement='top'>
                      <Button className={GroupModule['arrow-button']} variant="contained" onClick={() => moveNext()}>
                        <KeyboardArrowDownIcon fontSize="large" sx={{ transform: "rotate(180deg)" }} />
                      </Button>
                    </Tooltip>
                    <Tooltip title="全コンテンツを番組表エリアの末尾に移動する" placement='top'>
                      <Button className={GroupModule['arrow-button']} variant="contained" onClick={() => moveAllRight()}>
                        <KeyboardDoubleArrowDownIcon fontSize="large" sx={{ transform: "rotate(210deg)" }} />
                      </Button>
                    </Tooltip>
                  </Box>
                </div>
              </div>
          }
        </div>

        {
          groupType === "bangumihyo" ?
            <div className={`${GroupModule['group']} ${GroupModule[groupType]}`}>
              <div className={GroupModule['bangumihyo-container']} ref={ref}>
                <ul className={GroupModule['list']} ref={bangumihyoRef}>
                  {items.map((item, i) => {
                    return (
                      <li key={item.id} className={GroupModule['item-wrapper']} onDragStart={handleDragStart}>
                        <div className={GroupModule['saiseijun-container']}>
                          <span className={`${GroupModule['count']} ${GroupModule['saiseijun']}`} >{i + 1}</span>
                        </div>
                        {/* 先ほどの２つのコンポーネントを利用 */}
                        <Draggable item={item} index={firstIndex + i} onMove={onMove} draggingItem={draggingItem} setDraggingItem={setDraggingItem}>
                          <Item items={items} index={i} id={item.id} contents={item.content} groupName={groupType} selectedId={bangumiSelectedId} setSelectedId={setBangumiSelectedId} />
                        </Draggable>
                      </li>
                    );
                  })}
                </ul>
                <Box
                  sx={{
                    minWidth: '100px',
                    maxWidth: '100px',
                    margin: '0px 10px 10px;'
                  }}
                >
                  <DogaSaiseiDialog contents={contents} haishinKaishibi={moment(state.haishin_kaishibi).format('YYYY/MM/DD')} />
                </Box>
              </div>
            </div>
            :
            <div className={`${GroupModule['group']} ${GroupModule[groupType]}`}>
              <ul className={GroupModule['list']} ref={ref}>
                {items.map((item, i) => {
                  return (
                    <li key={item.id} className={GroupModule['item-wrapper']} onDragStart={handleDragStart}>
                      {/* 先ほどの２つのコンポーネントを利用 */}
                      <Draggable item={item} index={i} onMove={onMove} draggingItem={draggingItem} setDraggingItem={setDraggingItem}>
                        <Item items={items} index={i} id={item.id} contents={item.content} groupName={groupType} selectedId={contentSelectedId} setSelectedId={setContentSelectedId} />
                      </Draggable>
                    </li>
                  );
                })}
              </ul>
            </div>
        }
      </div >
    );
  };

export default Group;
