import { useState, useRef } from "react";

import {
  Button,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";

import LoadingButton from '@mui/lab/LoadingButton';

type Props = {
  childRef: React.MutableRefObject<any>;
};

/**
 * 番組表保存確認ダイアログコンポーネント
 *
 * @param {Props} { contentId, filePath }
 * @return {*} 
 */
const SaveConfirmDialog = ({ childRef }: Props) => {
  const [open, setOpen] = useState(false);
  // 処理中フラグ（ボタン連打対策）
  const processing = useRef(false);
  const [load, setLoad] = useState<boolean>(false);

  const handleClickOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    // 処理中(true)なら非同期処理せずに抜ける
    if (processing.current) return;
    // 処理中フラグを上げる
    processing.current = true;
    // 疑似非同期処理
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 1000);
    setLoad(true);
    await childRef?.current?.handleSave(setLoad);

    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <LoadingButton
        loading={load}
        type="submit"
        variant="contained"
        onClick={handleClickOpen}
        sx={{ ml: 1, mt: 2, mb: 2 }}
      >
        保存
      </LoadingButton>
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            width: "50vw"
          }
        }}
      >
        <Typography component="h3" variant="h6" mt={2} ml={2}>
          番組表保存確認
        </Typography>
        <DialogContent sx={{ display: "flex", flexFlow: "column", padding: "15px 24px 0px" }}>
          <Typography component="h3" variant="subtitle1" mt={1} sx={{ marginTop: 0, fontSize: "1.1rem" }}>
            番組表を保存してもよろしいですか？
          </Typography>
          <Typography component="h3" variant="subtitle2" mt={1} sx={{ color: "rgba(0, 0, 0, 0.7)" }}>
            ※注意事項
          </Typography>
          <Typography component="h3" variant="subtitle2" mt={1} sx={{ color: "rgba(0, 0, 0, 0.7)" }}>
            ・番組の担当者に番組表確認依頼メールを送信します。
          </Typography>
          <Typography component="h3" variant="subtitle2" mt={1} sx={{ color: "rgba(0, 0, 0, 0.7)" }}>
            ・未来の番組表に同じ番組が含まれていても再生順は更新されません。未来の番組表を確認してください。
          </Typography>
          <Typography component="h3" variant="subtitle2" mt={1} sx={{ color: "rgba(0, 0, 0, 0.7)" }}>
            ・番組表保存後の一時保存はできません。
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>閉じる</Button>
          <LoadingButton loading={load} variant="contained" onClick={handleSave}>保存する</LoadingButton>
        </DialogActions>
      </Dialog>
    </Box >
  );
}

export default SaveConfirmDialog;
