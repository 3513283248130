import React, { useState, useContext, useEffect } from "react";

import {
  DataGrid,
  GridColDef,
  jaJP,
} from "@mui/x-data-grid";

import moment from "moment";

import { ReloadContext } from "../../context/ReloadContext";
import { getLoginUser } from "../../api/loginuser";
import { postSearchUserSetting } from "../../api/loginuser";
import { renderCellExpand } from "../Common/GridCellExpand";
import { UserSettingEditDialog } from './UserSettingEditDialog'
import ToggleVisibilityDialog from '../Common/ToggleVisibilityDialog'
import { AuthInfoContext } from "../../context/AuthContext";
import {
  UserSettingData, UserSettingDataSet, UserSettingSearchParam
} from "../../types/WebData";
import { KENGEN } from "../../const/index";

/**
 * DataGrid用にデータを編集
 *
 * @param {UserSettingDataSet[]} userSettingDatas
 * @return {*} 
 */
const makeGridData = (userSettingDatas: UserSettingDataSet[]) => {
  return userSettingDatas.map((userSetting) => {
    // 日付フォーマット
    const updateDate = moment(userSetting.update_dt);
    return {
      ...userSetting,
      update_dt: updateDate.format("YYYY/MM/DD"),
    };
  });
};

/**
 * 全件データを１ページ分の行にsliceしている
 * 値に単位をつけるなどのデータの編集も、ここで１ページ分のみ行う
 */
const loadServerRows = (
  page: number,
  pageSize: number,
  allRows: UserSettingDataSet[]
): Promise<UserSettingDataSet[]> =>
  new Promise<UserSettingDataSet[]>((resolve) => {
    if (!allRows) return;
    resolve(
      // １ページ分にsliceした後に、データを編集するfunctionに渡している
      makeGridData(allRows.slice(page * pageSize, (page + 1) * pageSize))
    );
  });

const useQuery = (page: number, pageSize: number, allRows: any[]) => {
  const [rowCount, setRowCount] = React.useState<number | undefined>(undefined);
  const [data, setData] = React.useState<UserSettingDataSet[]>([]);

  useEffect(() => {
    let active = true;

    setRowCount(undefined);
    loadServerRows(page, pageSize, allRows).then((newRows) => {
      if (!active) {
        return;
      }
      setData(newRows);
      setRowCount(allRows.length);
    });

    return () => {
      active = false;
    };
  }, [page, pageSize, allRows]);

  return { data, rowCount };
};

interface RowsState {
  page: number;
  pageSize: number;
}

type Props = {
  searchParam: UserSettingSearchParam | null;
};

/**
 * 担当者設定一覧コンポーネント
 *
 * @return {Props} { searchParam }
 */
export const UserSettingGrid = ({ searchParam }: Props) => {
  const [authInfo] = useContext(AuthInfoContext);
  const reloadContext = useContext(ReloadContext);
  const [userSettingData, setUserSettingData] = useState<UserSettingData>({ data: [] });

  useEffect(() => {
    const getSearchData = async (param: UserSettingSearchParam) => {
      const result = await postSearchUserSetting(param);
      setUserSettingData(result);
      if (result?.isError) {
        await reloadContext?.setSnackbarInfo({
          isOpen: true,
          type: "error",
          message: result.message,
        });
      }
    };

    const getListData = async () => {
      const result = await getLoginUser(authInfo.id, authInfo.kengen);
      setUserSettingData(result);
      if (result?.isError) {
        await reloadContext?.setSnackbarInfo({
          isOpen: true,
          type: "error",
          message: result.message,
        });
      }
    };

    if (authInfo.kengen === KENGEN.ADMIN) {
      if (!searchParam) {
        getSearchData({ kaisha_mei: "", shimei: "", user_id: "", is_visible: false })
      } else {
        getSearchData(searchParam);
      }
    } else {
      getListData()
    }
  }, [searchParam, reloadContext?.reload]);

  const columns: GridColDef[] =
    [
      {
        field: "id",
        headerName: "ID",
        hide: true,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "kaisha_mei",
        headerName: "会社名",
        width: 200,
        sortable: false,
        disableColumnMenu: true,
        renderCell: renderCellExpand,
      },
      {
        field: "shimei",
        headerName: "担当者氏名",
        width: 200,
        sortable: false,
        disableColumnMenu: true,
        renderCell: renderCellExpand,
      },
      {
        field: "user_id",
        headerName: "担当者ID",
        width: 150,
        sortable: false,
        disableColumnMenu: true,
        renderCell: renderCellExpand,
      },
      {
        field: "mail_address",
        headerName: "メールアドレス",
        width: 150,
        sortable: false,
        disableColumnMenu: true,
        hide: authInfo.kengen === KENGEN.ADMIN ? true : false,
        renderCell: renderCellExpand,
      },
      {
        field: "update_dt",
        headerName: "更新日",
        width: 100,
        sortable: false,
        disableColumnMenu: true,
      },
      {
        field: 'editButton',
        headerName: "",
        width: 20,
        align: "center",
        sortable: false,
        disableColumnMenu: true,
        disableExport: true,
        renderCell: (params) => (
          <UserSettingEditDialog
            loginuserId={params.row.id} />
        )
      },
      {
        field: "toggleVisibilityDialog",
        headerName: "",
        width: 20,
        align: "center",
        filterable: false,
        sortable: false,
        hide: authInfo.kengen === KENGEN.ADMIN ? false : true,
        disableColumnMenu: true,
        disableExport: true,
        renderCell: (params) => (
          <ToggleVisibilityDialog deleteId={params.row.id} isVisible={!params.row.del_flg} tableName="loginuser" />
        ),
      },
    ];

  // ページのstate　１ページに１０行
  const [rowsState, setRowsState] = React.useState<RowsState>({
    page: 0,
    pageSize: 10,
  });

  const { data, rowCount } = useQuery(
    rowsState.page,
    rowsState.pageSize,
    userSettingData.data
  );

  // データの読み込みが間に合ってなくてrowCountがundefinedのままの場合の対策
  const [rowCountState, setRowCountState] = React.useState(rowCount || 0);
  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      rowCount !== undefined ? rowCount : prevRowCountState
    );
  }, [rowCount, setRowCountState]);

  return (
    <div style={{ height: 650, width: "100%" }}>
      <DataGrid
        rows={data}
        columns={columns}
        rowCount={rowCountState}
        {...rowsState}
        rowsPerPageOptions={[10]}
        pagination
        paginationMode="server"
        sortModel={[{ field: "id", sort: "asc" }]}
        onPageChange={(page) => setRowsState((prev) => ({ ...prev, page }))}
        onPageSizeChange={(pageSize) =>
          setRowsState((prev) => ({ ...prev, pageSize }))
        }
        localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
      />
    </div>
  );
};