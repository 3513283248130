import { useEffect, useState } from "react";

import {
  Button,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Card,
  CardContent,
  Grid
} from "@mui/material";
import { Report } from '@mui/icons-material';

import Thumbnail from "../Common/Thumbnail";

import BangumiReadOnlyDialogModule from './BangumiReadOnlyDialog.module.css'
import { Item as _Item } from '../Common/Preview/data';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';
interface Props {
  items: _Item[];
  index: number;
  id: number;
  bangumi_mei: string;
  kokyaku_mei: string;
  kaisha_mei: string;
  content_file_mei: string;
  thumbnail_file_path: string;
  content_byosu: number;
  groupName: string;
  haishin_kaishibi: string;
  haishin_shuryobi: string;
  haishin_kaishijikan: string;
  haishin_shuryojikan: string;
  selectedId: number;
  setSelectedId: React.Dispatch<React.SetStateAction<number>>
}

/**
 * 番組詳細ダイアログコンポーネント
 *
 * @param {Props}
 * @return {*} 
 */
const BangumiReadOnlyDialog = ({ items, index, id, bangumi_mei, kokyaku_mei, kaisha_mei, content_file_mei, thumbnail_file_path, content_byosu, groupName, haishin_kaishibi, haishin_shuryobi, haishin_kaishijikan, haishin_shuryojikan, selectedId, setSelectedId }: Props) => {
  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(index);
  const navigate = useNavigate();
  useEffect(() => {
    setCurrentIndex(index);
  }, [index]);
  const handleClickCard = (id: number) => {
    setSelectedId(id);
  }
  const handleClickOpen = (id: number) => {
    setSelectedId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedId(0);
    setOpen(false);
    setCurrentIndex(index);
  };
  const handlePrevious = () => {
    const newIndex = Math.max(currentIndex - 1, 0);
    setCurrentIndex(newIndex);
    setSelectedId(items[newIndex].id);
  };
  
  const handleNext = () => {
    const newIndex = Math.min(currentIndex + 1, items.length - 1);
    setCurrentIndex(newIndex);
    setSelectedId(items[newIndex].id);
  };

  const handleEdit = () => {
    // 編集ボタンを押した時
    const confirm = window.confirm("このページを離れますか？\n行った変更が保存されない可能性があります。");
    if (confirm) {
      navigate('/hoeimoshikomi', { state: { hoeimoshikomiId: hoeimoshikomiId } });
    }    
    
  };

  let bangumiMeiShosai = (items && items[currentIndex] && items[currentIndex].content && items[currentIndex].content.bangumi_mei) 
  ? items[currentIndex].content.bangumi_mei 
  : bangumi_mei;
  let haishinKaishibiShosai = (items && items[currentIndex] && items[currentIndex].content && items[currentIndex].content.haishin_kaishibi) 
  ? items[currentIndex].content.haishin_kaishibi 
  : haishin_kaishibi;
  let haishinShuryobiShosai = (items && items[currentIndex] && items[currentIndex].content && items[currentIndex].content.haishin_shuryobi) 
  ? items[currentIndex].content.haishin_shuryobi 
  : haishin_shuryobi;
  let haishinKaishijikanShosai = (items && items[currentIndex] && items[currentIndex].content && items[currentIndex].content.haishin_kaishijikan) 
  ? items[currentIndex].content.haishin_kaishijikan 
  : haishin_kaishijikan;
  let haishinShuryojikanShosai = (items && items[currentIndex] && items[currentIndex].content && items[currentIndex].content.haishin_shuryojikan) 
  ? items[currentIndex].content.haishin_shuryojikan 
  : haishin_shuryojikan;
  let kokyakuMeiShosai = (items && items[currentIndex] && items[currentIndex].content && items[currentIndex].content.kokyaku_mei) 
  ? items[currentIndex].content.kokyaku_mei 
  : kokyaku_mei;
  let kaishaMeiShosai = (items && items[currentIndex] && items[currentIndex].content && items[currentIndex].content.kaisha_mei) 
  ? items[currentIndex].content.kaisha_mei 
  : kaisha_mei;
  let contentFileMeiShosai = (items && items[currentIndex] && items[currentIndex].content && items[currentIndex].content.content_file_mei) 
  ? items[currentIndex].content.content_file_mei 
  : content_file_mei;
  let contentByosuShosai = (items && items[currentIndex] && items[currentIndex].content && items[currentIndex].content.content_byosu) 
  ? items[currentIndex].content.content_byosu 
  : content_byosu;
  let hoeimoshikomiId = (items && items[currentIndex] && items[currentIndex].content && items[currentIndex].content.hoeimoshikomi_id) 
  ? items[currentIndex].content.hoeimoshikomi_id 
  : 0;
  return (
    <Box sx={{ display: "flex" }}>
      <Card className={id === selectedId ? `${BangumiReadOnlyDialogModule[`contents`]} ${BangumiReadOnlyDialogModule[`selected`]}` : BangumiReadOnlyDialogModule[`contents`]}
       onClick={() => handleClickCard(items[currentIndex].id)}
       >
        <CardContent sx={{ padding: '7px', paddingBottom: '0px !important' }}>
          <div>
            <Thumbnail src={thumbnail_file_path} />
            <div className={BangumiReadOnlyDialogModule['thumbnail-text']}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{bangumi_mei}</span>
                <Button variant="contained" size="small" onClick={() => handleClickOpen(id)}>詳細</Button>
              </div>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: 'end' }}>
                <p>{haishin_kaishibi} - {haishin_shuryobi ? haishin_shuryobi : "終了日未定"}</p>
              </Box>
           {/*   <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: 'end' }}>
                <p>{index}</p>
              </Box>  */}             
              <Box sx={{ display: "flex", justifyContent: 'end' }}>
                {(haishin_kaishijikan && haishin_shuryojikan) ?
                  <p style={{ display: "flex", alignItems: "end" }}><Report color="primary" sx={{ ml: 1 }} /> {`${haishinKaishijikanShosai}-${haishinShuryojikanShosai}`}</p>
                  : <p>　</p>
                }
              </Box>
            </div>
          </div>
        </CardContent>
      </Card>

      <Dialog
        open={open}
        onClose={handleClose}
        className={groupName === "bangumihyo" ? "bangumihyo-dialog" : "content-dialog"}
        PaperProps={{
          sx: {
            maxWidth: "50vw"
          }
        }}
      >
        <Typography component="h3" variant="h6" mt={1} ml={2} mb={2}>
          番組情報
        </Typography>
        <DialogContent sx={{ paddingTop: 0 }}>
          <Grid container spacing={1}>
            <Grid item xs={3} sx={{ mb: 1 }}>
              <Typography component="h3" variant="subtitle1" mt={1} ml={1} sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                番組名
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography component="h3" variant="subtitle1" mt={1} sx={{ minWidth: '80%', borderBottom: 'rgba(0, 0, 0, 0.3) 1px solid' }}>
                {bangumiMeiShosai || '　'}
              </Typography>
            </Grid>
            <Grid item xs={3} sx={{ mb: 1 }}>
              <Typography component="h3" variant="subtitle1" mt={1} ml={1} sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                広告主名
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography component="h3" variant="subtitle1" mt={1} sx={{ minWidth: '80%', borderBottom: 'rgba(0, 0, 0, 0.3) 1px solid' }}>
                {kokyakuMeiShosai || '　'}
              </Typography>
            </Grid>
            <Grid item xs={3} sx={{ mb: 1 }}>
              <Typography component="h3" variant="subtitle1" mt={1} ml={1} sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                会社名
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography component="h3" variant="subtitle1" mt={1} sx={{ minWidth: '80%', borderBottom: 'rgba(0, 0, 0, 0.3) 1px solid' }}>
                {kaishaMeiShosai || '　'}
              </Typography>
            </Grid>
            <Grid item xs={3} sx={{ mb: 1 }}>
              <Typography component="h3" variant="subtitle1" mt={1} ml={1} sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                ファイル名
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography component="h3" variant="subtitle1" mt={1} sx={{ minWidth: '80%', borderBottom: 'rgba(0, 0, 0, 0.3) 1px solid' }}>
                {contentFileMeiShosai || '　'}
              </Typography>
            </Grid>
            <Grid item xs={3} sx={{ mb: 1 }}>
              <Typography component="h3" variant="subtitle1" mt={1} ml={1} sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                コンテンツ秒数
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography component="h3" variant="subtitle1" mt={1} sx={{ minWidth: '80%', borderBottom: 'rgba(0, 0, 0, 0.3) 1px solid' }}>
                {contentByosuShosai || '　'}
              </Typography>
            </Grid>
            <Grid item xs={3} sx={{ mb: 1 }}>
              <Typography component="h3" variant="subtitle1" mt={1} ml={1} sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                放映開始日
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography component="h3" variant="subtitle1" mt={1} sx={{ minWidth: '80%', borderBottom: 'rgba(0, 0, 0, 0.3) 1px solid' }}>
                {haishinKaishibiShosai || '　'}
              </Typography>
            </Grid>
            <Grid item xs={3} sx={{ mb: 1 }}>
              <Typography component="h3" variant="subtitle1" mt={1} ml={1} sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                放映終了日
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography component="h3" variant="subtitle1" mt={1} sx={{ minWidth: '80%', borderBottom: 'rgba(0, 0, 0, 0.3) 1px solid' }}>
                {haishinShuryobiShosai || '　'}
              </Typography>
            </Grid>
            <Grid item xs={3} sx={{ mb: 1 }}>
              <Typography component="h3" variant="subtitle1" mt={1} ml={1} sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                時間帯放映開始時間
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography component="h3" variant="subtitle1" mt={1} sx={{ minWidth: '80%', borderBottom: 'rgba(0, 0, 0, 0.3) 1px solid' }}>
                {haishinKaishijikanShosai || '　'}
              </Typography>
            </Grid>
            <Grid item xs={3} sx={{ mb: 1 }}>
              <Typography component="h3" variant="subtitle1" mt={1} ml={1} sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                時間帯放映終了時間
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography component="h3" variant="subtitle1" mt={1} sx={{ minWidth: '80%', borderBottom: 'rgba(0, 0, 0, 0.3) 1px solid' }}>
                {haishinShuryojikanShosai || '　'}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
  <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
    <LoadingButton
    disabled={currentIndex === 0}
    variant="contained"
    sx={{ mr: 2 }}
    onClick={handlePrevious}
    >
      前へ
    </LoadingButton>
    <LoadingButton
      disabled={currentIndex === items.length - 1}
      variant="contained"
      onClick={handleNext}
    >
      次へ
    </LoadingButton>
  </Box>
  <Button variant="outlined" onClick={handleClose}>閉じる</Button>
  <LoadingButton variant="contained" type="submit" onClick={handleEdit}>編集</LoadingButton> 
</DialogActions>  

      </Dialog>
    </Box >
  );
}

export default BangumiReadOnlyDialog;
