import React, { useState, useContext, useRef } from "react";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import { RestoreFromTrash } from '@mui/icons-material';

import { ReloadContext } from "../../context/ReloadContext";
import { deleteBaitai, isBaitai } from "../../api/baitai";
import { isBaitaiShosaiKaizodo } from "../../api/baitaiShosai";
import { deleteKikiKataban } from "../../api/kikiKataban";
import { deleteKokyaku } from "../../api/kokyaku";
import { deleteKaisha } from "../../api/kaisha";
import { deleteLoginUser, isKaishaUser } from "../../api/loginuser";
import { deleteArea } from "../../api/area";
import { deleteLocation, isAreaLocation } from "../../api/location";
import { deleteKaizodo } from "../../api/kaizodo";
import { deleteBaitaiShubetsu } from "../../api/baitaiShubetsu";
import { isBaitaiKikiAssociate } from "../../api/baitaiKikiAssociate";
import LoadingButton from '@mui/lab/LoadingButton';

interface Props {
  deleteId: number;
  // 削除フラグON…false、削除フラグOFF…true
  isVisible: boolean;
  tableName: string;
}

/**
 * 表示切替コンポーネント
 * 表示　 del_flg: true
 * 非表示 del_flg: false
 *
 * @param {Props} { deleteId, isVisible, tableName }
 * @return {*} 
 */
const ToggleVisibilityDialog = ({ deleteId, isVisible, tableName }: Props) => {
  const reload = useContext(ReloadContext);
  const [open, setOpen] = useState(false);
  // 処理中フラグ（ボタン連打対策）
  const processing = useRef(false);
  const [load, setLoad] = useState<boolean>(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteRow = async (
    id: number,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    // 処理中(true)なら非同期処理せずに抜ける
    if (processing.current) return;
    // 処理中フラグを上げる
    processing.current = true;
    // 疑似非同期処理
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 1000);
    setLoad(true);
    // 各テーブルdelete api
    const deleteApi = (tableName: string) => {
      switch (tableName) {
        case "baitai":
          return deleteBaitai(deleteId, isVisible);
        case "kikiKataban":
          return deleteKikiKataban(deleteId, isVisible);
        case "kokyaku":
          return deleteKokyaku(deleteId, isVisible);
        case "kaisha":
          return deleteKaisha(deleteId, isVisible);
        case "loginuser":
          return deleteLoginUser(deleteId, isVisible);
        case "area":
          return deleteArea(deleteId, isVisible);
        case "location":
          return deleteLocation(deleteId, isVisible);
        case "kaizodo":
          return deleteKaizodo(deleteId, isVisible);
        case "baitaiShubetsu":
          return deleteBaitaiShubetsu(deleteId, isVisible);
        default:
          break;
      }
    };

    // 各テーブル子テーブルの存在チェック
    const existenceApi = (tableName: string) => {
      switch (tableName) {
        case "kaisha":
          return isKaishaUser(deleteId);
        case "area":
          return isAreaLocation(deleteId);
        case "location":
          return isBaitai(deleteId, undefined);
        case "baitaiShubetsu":
          return isBaitai(undefined, deleteId);
        case "kaizodo":
          return isBaitaiShosaiKaizodo(deleteId);
        case "kikiKataban":
          return isBaitaiKikiAssociate(deleteId);
        default:
          break;
      }
    };
    const existenceResult = await existenceApi(tableName);
    if (existenceResult?.isError) {
      await reload?.setSnackbarInfo({
        isOpen: true,
        type: "error",
        message: existenceResult?.message,
      });
    } else if (existenceResult?.message) {
      await reload?.setSnackbarInfo({
        isOpen: true,
        type: "error",
        message: existenceResult.message,
      });
    } else {
      const result = await deleteApi(tableName);
      await reload?.setSnackbarInfo({
        isOpen: true,
        type: result?.isError ? "error" : "success",
        message: result?.isError ? result?.message : isVisible ? "非表示に切り替えました。" : "表示に切り替えました。",
      });
    }
    await reloadSetting();
    setLoad(false);
  };

  // モーダル→画面戻り時
  const reloadSetting = () => {
    reload?.setReload(reload?.reload + 1);
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      {isVisible ?
        <DeleteIcon color="primary" fontSize='large'
          onClick={() => handleClickOpen()}
        />
        :
        <RestoreFromTrash color="primary" fontSize='large'
          onClick={() => handleClickOpen()}
        />
      }
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            width: "20vw",
          }
        }}
      >
        <Typography component="h3" variant="subtitle1" mt={2} ml={2}>
          表示切替確認
        </Typography>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {isVisible ? "非表示" : "表示"}に切り替えてもよろしいですか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>閉じる</Button>
          <LoadingButton loading={load} variant="contained" onClick={(e) => deleteRow(deleteId, e)}>切り替える</LoadingButton>
        </DialogActions>
      </Dialog>
    </Box >
  );
}

export default ToggleVisibilityDialog;