import { useContext, useState, useRef } from "react";
import { useForm } from "react-hook-form";

import {
  Button,
  Box,
  TextField,
  Typography,
  Dialog,
  Checkbox,
  InputLabel,
  FormControl,
  InputAdornment,
  OutlinedInput,
  IconButton,
  FormControlLabel,
  DialogActions,
  DialogContent,
} from "@mui/material";

import Edit from '@mui/icons-material/Edit';

import moment from "moment";

import { ReloadContext } from "../../context/ReloadContext";
import { updateLoginUser, getLoginUserId } from "../../api/loginuser";
import KaishaSelect from '../Common/KaishaSelect';
import { KaishaDataSet } from "../../types/WebData";
import { AuthInfoContext } from "../../context/AuthContext";
import { KENGEN } from "../../const/index";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';


const md5 = require("md5");

interface Props {
  loginuserId: number;
}

interface State {
  password: string;
  showPassword: boolean;
}

/**
 * 担当者設定ダイアログコンポーネント
 *
 * @param {Props} { loginuserId }
 * @return {*} 
 */
export const UserSettingEditDialog = ({ loginuserId }: Props) => {
  const [authInfo] = useContext(AuthInfoContext);
  const reloadContext = useContext(ReloadContext);
  const [open, setOpen] = useState(false);
  const { handleSubmit } = useForm<any>();
  const [confirmationValues, setConfirmationValues] = useState<State>({
    password: '',
    showPassword: false,
  });
  const [kaisha, setKaisha] = useState<KaishaDataSet | null>({ id: 0 })
  const [userId, setUserId] = useState<string>("");
  const [shimei, setShimei] = useState<string>("");
  const [mailAddress, setMailAddress] = useState<string>("");
  const [updateDt, setUpdateDt] = useState<Date | null>(null);
  const [haishinTantoFlg, setHaishinTantoFlg] = useState<boolean>(false);
  const [load, setLoad] = useState<boolean>(false);

  // エラーメッセージ表示用
  // 各入力項目ごとのメッセージ
  const [errorMessageKaishaId, setErrorMessageKaishaId] = useState<string>("");
  const [errorMessageShimei, setErrorMessageShimei] = useState<string>("");
  const [errorMessagePassword, setErrorMessagePassword] = useState<string>("");
  const [errorMessageShowPassword, setErrorMessageShowPassword] = useState<string>("");
  const [errorMessageUserId, setErrorMessageUserId] = useState<string>("");
  const [values, setValues] = useState<State>({
    password: '',
    showPassword: false,
  });

  const isCreate = loginuserId === 0;
  // 処理中フラグ（ボタン連打対策）
  const processing = useRef(false);

  const handleClickShowConfirmationPassword = () => {
    setConfirmationValues({
      ...confirmationValues,
      showPassword: !confirmationValues.showPassword,
    });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleChangeConfirmation =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setConfirmationValues({ ...confirmationValues, [prop]: event.target.value });
    };

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleClickOpen = async () => {
    // エラーメッセージ初期化
    initErrorMessage();

    if (isCreate) { // 新規登録
      setUserId("");
      setShimei("")
      setMailAddress("");
      setKaisha({ id: 0 })
      setHaishinTantoFlg(false)
      setUpdateDt(null);
    } else {
      // 担当者設定情報取得
      const res = await getLoginUserId(loginuserId, authInfo.id, authInfo.kengen);
      if (res?.isError) {
        await reloadContext?.setSnackbarInfo({
          isOpen: true,
          type: "error",
          message: res?.message,
        });
      }
      setUserId(res.data[0].user_id)
      setShimei(res.data[0].shimei)
      setKaisha({ id: res.data[0].kaisha_id })
      setHaishinTantoFlg(res.data[0].haishin_tanto_flg)
      setMailAddress(res.data[0].mail_address);
      setUpdateDt(res.data[0].update_dt);
    }
    setOpen(true);
  };

  /**
 * エラーメッセージ初期化処理
 */
  const initErrorMessage = () => {
    setErrorMessageKaishaId("")
    setErrorMessageShimei("")
    setErrorMessageUserId("")
    setErrorMessagePassword("")
    setErrorMessageShowPassword("")
  }

  const handleClose = () => {
    setOpen(false);
  };

  // テーブル更新・一覧更新
  const handleUpdate = async () => {
    // バリデーションチェック
    initErrorMessage()

    if (kaisha?.id === 0) {
      return setErrorMessageKaishaId("会社名を選択してください。");
    }
    if (shimei === "") {
      return setErrorMessageShimei("担当者氏名を入力してください");
    }
    if (userId === "") {
      return setErrorMessageUserId("担当者IDを入力してください。");
    }
    if (!userId.match(/^[A-Za-z0-9_]*$/)) {
      return setErrorMessageUserId("半角英数字のみ使用可能です。");
    }
    if ((isCreate && values.password === "") || (values.password === "" && confirmationValues.password !== "")) {
      return setErrorMessagePassword("パスワードを入力して下さい。");
    }
    if ((isCreate && !values.password.match(/^[A-Za-z0-9]*$/)) || (!isCreate && values.password && !values.password.match(/^[A-Za-z0-9]*$/))) {
      return setErrorMessagePassword("半角英数字のみ使用可能です。");
    }
    if ((isCreate && values.password.length < 4) || (!isCreate && values.password && values.password.length < 4)) {
      return setErrorMessagePassword("4文字以上で入力してください。");
    }
    if ((isCreate && !confirmationValues.password) || (!isCreate && values.password && !confirmationValues.password)) {
      return setErrorMessageShowPassword("確認用パスワードを入力して下さい。");
    }
    if ((isCreate && values.password !== confirmationValues.password) || (!isCreate && values.password && values.password !== confirmationValues.password)) {
      return setErrorMessageShowPassword("確認用パスワードが一致していません。");
    }

    // 処理中(true)なら非同期処理せずに抜ける
    if (processing.current) return;
    // 処理中フラグを上げる
    processing.current = true;
    // 疑似非同期処理
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 1000);
    setLoad(true);
    const result = await updateLoginUser({
      id: loginuserId,
      user_id: userId,
      password: values.password === "" ? "" : md5(values.password),
      kaisha_mei: "",
      shimei: shimei,
      haishin_tanto_flg: haishinTantoFlg,
      kaisha_id: kaisha?.id,
      mail_address: isCreate || loginuserId === authInfo.id ? mailAddress : undefined,
      update_dt: (updateDt) ? moment(updateDt).format("YYYY-MM-DD HH:mm:ss") : undefined,
    });

    await reloadContext?.setSnackbarInfo({
      isOpen: true,
      type: result.isError ? "error" : "success",
      message: result.message,
    });
    setLoad(false);
    await reloadSetting();
  };

  // モーダル→画面戻り時
  const reloadSetting = () => {
    reloadContext?.setReload(reloadContext?.reload + 1);
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      {
        isCreate ?
          <Button
            type="button"
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
            onClick={() => handleClickOpen()}
          >
            新規担当者登録
          </Button>
          :
          <Edit color="primary" fontSize='large'
            onClick={() => handleClickOpen()}
          />
      }
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            width: "50vw",
          }
        }}
      >
        <form onSubmit={handleSubmit(handleUpdate)}>
          <Typography component="h3" variant="h6" mt={1} ml={2}>
            {isCreate ? "新規担当者登録" : "担当者情報編集"}
          </Typography>
          <DialogContent sx={{ paddingTop: 0 }}>
            <KaishaSelect kaisha={kaisha} setKaisha={setKaisha} label={"会社名 *"} disabled={authInfo.kengen === KENGEN.ADMIN ? false : true} />
            {errorMessageKaishaId && (
              <small className={"error-message"}>{errorMessageKaishaId}</small>
            )}
            <TextField
              label="担当者氏名 *"
              value={shimei}
              onChange={e => setShimei(e.target.value)}
              fullWidth
              variant="outlined"
              sx={{ marginTop: 1 }}
            />
            {errorMessageShimei && (
              <small className={"error-message"}>{errorMessageShimei}</small>
            )}
            <TextField
              label="担当者ID *"
              value={userId}
              onChange={e => setUserId(e.target.value)}
              fullWidth
              disabled={isCreate ? false : true}
              variant="outlined"
              sx={{ marginTop: 1 }}
            />
            {errorMessageUserId && (
              <small className={"error-message"}>{errorMessageUserId}</small>
            )}
            <FormControl sx={{ mt: 1 }} fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                {isCreate ? "パスワード *" : "パスワード"}
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={values.showPassword ? 'text' : 'password'}
                label={isCreate ? "パスワード *" : "パスワード"}
                value={values.password}
                onChange={handleChange('password')}
                autoComplete='new-password'
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            {errorMessagePassword && (
              <small className={"error-message"}>{errorMessagePassword}</small>
            )}
            <FormControl sx={{ mt: 1 }} fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                {isCreate ? "パスワード(確認用) *" : "パスワード(確認用)"}
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={confirmationValues.showPassword ? 'text' : 'password'}
                label={isCreate ? "パスワード(確認用) *" : "パスワード(確認用)"}
                value={confirmationValues.password}
                onChange={handleChangeConfirmation('password')}
                autoComplete='new-password'
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmationPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {confirmationValues.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            {errorMessageShowPassword && (
              <small className={"error-message"}>{errorMessageShowPassword}</small>
            )}
            {isCreate || loginuserId === authInfo.id ?
              <TextField
                label="メールアドレス"
                value={mailAddress}
                onChange={e => setMailAddress(e.target.value)}
                fullWidth
                variant="outlined"
                sx={{ marginTop: 1 }}
              />
              : ""
            }
            <FormControlLabel
              control={
                <Checkbox checked={haishinTantoFlg} disabled={authInfo.kengen === KENGEN.ADMIN ? false : true} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setHaishinTantoFlg(event.target.checked);
                }}
                />
              }
              label="配信権限"
              sx={{ marginTop: 1 }}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>閉じる</Button>
            <LoadingButton loading={load} variant="contained" type="submit">登録</LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}