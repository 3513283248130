import React, { useEffect, useContext } from "react";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Checkbox } from "@mui/material";

import { ReloadContext } from "../../context/ReloadContext";
import { MenuData } from "../../types/WebData";
import { getMenuList } from "../../api/menu";

type Props = {
  menuData: MenuData;
  setMenuData: React.Dispatch<React.SetStateAction<MenuData>>;
  checkedMenu: string[];
  setCheckedMenu: React.Dispatch<React.SetStateAction<string[]>>;
  kengen: number;
};

/**
 * メニュー利用チェックボックスコンポーネント
 *
 * @param {Props} { menuData, setMenuData, checkedMenu, setCheckedMenu, kengen }
 * @return {*} 
 */
const MenuUsageCheck = ({ menuData, setMenuData, checkedMenu, setCheckedMenu, kengen }: Props) => {
  const reload = useContext(ReloadContext);

  useEffect(() => {
    const getMenuData = async () => {
      const result = await getMenuList(kengen);
      if (result.isError) {
        return reload?.setSnackbarInfo({
          isOpen: true,
          type: "error",
          message: result.message,
        });
      }
      setMenuData(result);
    };
    getMenuData();
    setCheckedMenu([]);
    return () => {
      reload?.setReload(0);
    };
  }, [kengen]);

  useEffect(() => {
    const defaultChecked: string[] = [];
    menuData.data
      .filter((option) => option.kengen_id === kengen)
      .forEach((menu) => {
        if (menu.riyo_flg) {
          defaultChecked.push(String(menu.id));
        }
        setCheckedMenu(defaultChecked);
      });
  }, [menuData, kengen]);

  const handleCheck = (value: string) => () => {
    const currentIndex = checkedMenu.indexOf(value);
    const newChecked = [...checkedMenu];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedMenu(newChecked);
  };

  return (
    <>
      <FormControl
        required
        component="fieldset"
        sx={{ width: "100%", bgcolor: "background.paper", m: 3, maxHeight: '45vh', overflowY: 'auto' }}
      >
        <FormLabel component="legend">メニュー利用</FormLabel>
        <FormGroup>
          {menuData.data
            .map((menu) => {
              return (
                <FormControlLabel
                  key={`${kengen}-${menu.id}`}
                  control={
                    <Checkbox
                      checked={checkedMenu.indexOf(String(menu.id)) !== -1}
                      disabled={false}
                      onChange={handleCheck(`${menu.id}`)}
                    />
                  }
                  label={menu.menu_name}
                />
              );
            })
          }
        </FormGroup>
      </FormControl>
    </>
  );
};

export default MenuUsageCheck;
