import { useContext, useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";

import {
  Button,
  Box,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  FormGroup,
  FormControlLabel,
  Switch,
  Checkbox,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRowParams,
  jaJP,
} from "@mui/x-data-grid";
import LoadingButton from '@mui/lab/LoadingButton';
import { Edit } from "@mui/icons-material";
import moment from "moment";

import { postSearchCodemaster } from '../../api/codemaster';
import { AuthInfoContext } from "../../context/AuthContext";
import { ReloadContext } from "../../context/ReloadContext";
import { renderCellExpand } from "../Common/GridCellExpand";
import { getJackId, updateJack } from "../../api/jack";
import { postSearchJackBaitaiShosai } from "../../api/baitaiShosai";
import {
  JackEditData,
  KaizodoDataSet,
  JackBaitaiShosaiSearchParam,
  BaitaiShosaiData,
  BaitaiShosaiDataSet,
  CodeMasterSearchParam,
} from "../../types/WebData";
import SimpleDatePicker from '../Common/DatePicker/SimpleDatePicker';
import SimpleTimePicker from '../Common/SimpleTimePicker';
import KaizodoSelect from '../Common/KaizodoSelect';
import ContentByosu from '../Common/ContentByosu';
import { CODE_MASTER_CATEGORY } from "../../const/index";

interface Props {
  jackId: number;
}
const JACK_BYOSU_MAX = 3600;

/**
 * ジャック編集ダイアログコンポーネント
 *
 * @param {Props} { jackId }
 * @return {*} 
 */
const JackEditDialog = ({ jackId }: Props) => {
  const [authInfo] = useContext(AuthInfoContext);
  const reload = useContext(ReloadContext);
  const [open, setOpen] = useState(false);
  const { handleSubmit } = useForm<any>();

  // --- ジャック登録制限時間 ---
  const [jackLimitMinutes, setJackLimitMinutes] = useState<number>(0);

  // --- ジャックフォーム用 start ---
  const [jackMei, setJackMei] = useState<string>("");
  const [jackKokokunushiMei, setJackKokokunushiMei] = useState<string>("");
  const [jackHaishinbi, setJackHaishinbi] = useState<Date | null>(null);
  const [jackKaishijikan, setJackKaishijikan] = useState<Date | null>(null);
  const [jackShuryojikan, setJackShuryojikan] = useState<Date | null>(null);
  const [contentByosu, setContentByosu] = useState<number>(0);

  // 変更前データ　入力値の初期化に使う
  const [henkomaeData, setHenkomaeData] = useState<JackEditData | null>(null);
  // --- ジャックフォーム用 end ---

  // --- ジャック媒体フォーム用 start ---
  const [baitaiMei, setBaitaiMei] = useState<string>("");
  const [areaMei, setAreaMei] = useState<string>("");
  const [locationMei, setLocationMei] = useState<string>("");
  const [kaizodo, setKaizodo] = useState<KaizodoDataSet | null>(null);
  const [isOnlySelectedData, setIsOnlySelectedData] = useState<boolean>(false);
  const [gamenList, setGamenList] = useState<BaitaiShosaiData>({ data: [] });
  const [gamenSelectedList, setGamenSelectedList] = useState<BaitaiShosaiData>({ data: [] });
  const [gamenUnselectedList, setGamenUnselectedList] = useState<BaitaiShosaiData>({ data: [] });
  // --- ジャック媒体フォーム用 end ---

  // 処理中フラグ（ボタン連打対策）
  const processing = useRef(false);
  const [load, setLoad] = useState<boolean>(false);

  const isCreate = jackId === 0;

  // エラーメッセージ表示用
  // 登録ボタンの近くに表示するメッセージ
  const [errorMessage, setErrorMessage] = useState<string>("");
  // 項目ごとのメッセージ
  const [errorMessageJackMei, setErrorMessageJackMei] = useState<string>("");
  const [errorMessageJackKokokunushiMei, setErrorMessageJackKokokunushiMei] = useState<string>("");
  const [errorMessageJackHaishinbi, setErrorMessageJackHaishinbi] = useState<string>("");
  const [errorMessageJackKaishijikan, setErrorMessageJackKaishijikan] = useState<string>("");
  const [errorMessageContentByosu, setErrorMessageContentByosu] = useState<string>("");

  // ステッパー表示用
  const [steps, setSteps] = useState<string[]>([]);
  const [activeStep, setActiveStep] = useState<number>(0);

  useEffect(() => {
    const steps = ["ジャック情報登録", "ジャック媒体登録"]
    setSteps(steps);
  }, [])

  useEffect(() => {
    // 開始時間、コンテンツ再生時間を変更する度にジャック終了時間を計算しなおす
    if (jackKaishijikan && contentByosu !== 0) {
      const jackShuryojikan = moment(jackKaishijikan).add(contentByosu, 's').toDate();
      setJackShuryojikan(jackShuryojikan);
    }
  }, [jackKaishijikan, contentByosu])

  const handleClickOpen = async () => {
    // エラーメッセージ初期化
    initErrorMessage();

    setJackMei("");
    setJackKokokunushiMei("");
    setJackHaishinbi(null);
    setJackKaishijikan(null);
    setJackShuryojikan(null);
    setContentByosu(0);
    setBaitaiMei("");
    setAreaMei("");
    setLocationMei("");
    setKaizodo(null);
    setIsOnlySelectedData(false);
    setGamenList({ data: [] });
    setGamenSelectedList({ data: [] });
    setGamenUnselectedList({ data: [] });

    // 表示ステップを先頭（ジャックフォーム）にする
    setActiveStep(0);
    // ジャック登録制限時間取得
    const searchParam: CodeMasterSearchParam = { category_id: CODE_MASTER_CATEGORY.JACK }
    const result = await postSearchCodemaster(searchParam);
    if (result?.isError) {
      await reload?.setSnackbarInfo({
        isOpen: true,
        type: "error",
        message: result?.message,
      });
    }
    setJackLimitMinutes(Number(result.data[0].code_name))

    if (isCreate) { // 新規登録
      setHenkomaeData({
        id: 0,
        baitai_shosai_ids: [],
      });
    } else {
      // 媒体情報取得
      const res = await getJackId(jackId);
      if (res?.isError) {
        await reload?.setSnackbarInfo({
          isOpen: true,
          type: "error",
          message: res?.message,
        });
      }

      const jack = res.data[0];

      // 媒体詳細IDのリストを取得
      const baitaiShosaiIds = jack.gamen_list.map((gamen: any) => gamen.id)

      setJackMei(jack.jack_mei)
      setJackKokokunushiMei(jack.jack_kokokunushi_mei)
      setJackHaishinbi(jack.jack_haishinbi)
      setJackKaishijikan(jack.jack_kaishijikan)
      setKaizodo(jack.kaizodo);
      setContentByosu(jack.content_byosu);
      // 選択済みリスト更新
      setGamenSelectedList({ data: jack.gamen_list });

      setHenkomaeData({
        id: jack.id,
        baitai_shosai_ids: baitaiShosaiIds,
        update_dt: jack.update_dt
      });
    }

    // 機器フォーム初期化
    await initJackBaitaiForm();

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  /**
   * エラーメッセージ初期化処理
   */
  const initErrorMessage = () => {
    setErrorMessage("");
    setErrorMessageJackMei("");
    setErrorMessageJackKokokunushiMei("");
    setErrorMessageJackHaishinbi("");
    setErrorMessageJackKaishijikan("");
    setErrorMessageContentByosu("");
  }

  // テーブル更新・一覧更新
  const handleUpdate = async () => {
    // 処理中(true)なら非同期処理せずに抜ける
    if (processing.current) return;
    // 処理中フラグを上げる
    processing.current = true;
    // 疑似非同期処理
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 1000);
    setLoad(true);

    // 媒体選択チェック
    if (gamenSelectedList.data.length === 0) {
      setLoad(false);
      return setErrorMessage("ジャックする画面を選択してください。");
    }

    // 新規の場合のみ
    if (isCreate) {
      // 制限時間チェック
      const setingDateTime = `${moment(jackHaishinbi).format("YYYY-MM-DD")} ${moment(jackShuryojikan).format("HH:mm:ssZ")}`

      const nowDateTime = moment();
      // 現在時間に制限時間を足す
      const jackLimitDateMinutes = nowDateTime.subtract((-1 * jackLimitMinutes), 'minutes').format("YYYY-MM-DD HH:mm:ssZ")

      if (setingDateTime <= jackLimitDateMinutes) {
        setLoad(false);
        return reload?.setSnackbarInfo({
          isOpen: true,
          type: "error",
          message: `配信期限（配信の${jackLimitMinutes}分前）までに配信できません。配信開始時間を確認してください。`
        });
      }
    }

    // ジャック媒体の選択に変更があった場合のみ送信する
    const before = henkomaeData?.baitai_shosai_ids?.sort(((a, b) => a - b));
    const after = gamenSelectedList.data.map(d => d.id).sort(((a, b) => a - b));
    const isGamenChanged = JSON.stringify(after) !== JSON.stringify(before);

    // 送信データ作成
    const postData = {
      id: jackId,
      loginuser_id: authInfo.id,
      kaisha_id: authInfo.kaisha_id,
      jack_mei: jackMei,
      jack_kokokunushi_mei: jackKokokunushiMei,
      jack_haishinbi: jackHaishinbi ? moment(jackHaishinbi).format("YYYY-MM-DD HH:mm:ssZ") : "",
      jack_kaishijikan: jackKaishijikan ? `${moment(jackHaishinbi).format("YYYY-MM-DD")} ${moment(jackKaishijikan).format("HH:mm:ssZ")}` : "",
      jack_shuryojikan: jackShuryojikan ? `${moment(jackHaishinbi).format("YYYY-MM-DD")} ${moment(jackShuryojikan).format("HH:mm:ssZ")}` : "",
      content_byosu: contentByosu,
      gamen_list: isGamenChanged ? gamenSelectedList?.data : null,
      update_dt: isCreate ? null : moment(henkomaeData?.update_dt).format("YYYY-MM-DD HH:mm:ss")
    } as JackEditData;

    const result = await updateJack(postData);

    setLoad(false);
    if (result.jackMeiKaburiList.length > 0) {
      await reload?.setSnackbarInfo({
        isOpen: true,
        type: "warning",
        message: `以下のジャックと再生時間が被っているため登録できませんでした。\n・${result.jackMeiKaburiList.join(`\n・`)}`,
      });
    } else {
      await reload?.setSnackbarInfo({
        isOpen: true,
        type: result.isError ? "error" : "success",
        message: result.message,
      });
      await reloadSetting();
    }
  };

  // モーダル→画面戻り時
  const reloadSetting = () => {
    reload?.setReload(reload?.reload + 1);
    setOpen(false);
  };

  /**
   * 媒体詳細フォーム初期化処理
   *
   */
  const initJackBaitaiForm = async () => {
    const searchParam: JackBaitaiShosaiSearchParam = {
      jack_id: henkomaeData?.id ? henkomaeData?.id : 0,
      jack_haishinbi: jackHaishinbi ? jackHaishinbi : new Date(),
      jack_kaishijikan: jackKaishijikan ? jackKaishijikan : new Date(),
      baitai_mei: "",
      area_mei: "",
      location_mei: "",
      kaizodo_id: 0,
      is_only_selected_data: false,
    };

    // 検索結果は全件表示、追加機器は空にしておく
    const result = await postSearchJackBaitaiShosai(searchParam);
    if (result?.isError) {
      await reload?.setSnackbarInfo({
        isOpen: true,
        type: "error",
        message: result?.message,
      });
    }
    setGamenList(result);
  }

  /**
   * 次のステップに進む
   *
   */
  const handleNext = async () => {
    // バリデーションチェック
    if (activeStep === 0) {
      // 媒体フォーム
      // エラーメッセージ初期化
      initErrorMessage();

      // バリデーションチェック
      if (jackMei === "") {
        setErrorMessage("入力項目に誤りがあります。");
        return setErrorMessageJackMei("ジャック名を入力してください。");
      }
      if (jackKokokunushiMei === "") {
        setErrorMessage("入力項目に誤りがあります。");
        return setErrorMessageJackKokokunushiMei("ジャック広告主名を入力してください。");
      }
      if (!jackHaishinbi) {
        setErrorMessage("入力項目に誤りがあります。");
        return setErrorMessageJackHaishinbi("配信開始日を入力してください。");
      }
      if (!moment(jackHaishinbi).isValid()) {
        return setErrorMessageJackHaishinbi("不正な入力形式です。");
      }
      if (!jackKaishijikan) {
        setErrorMessage("入力項目に誤りがあります。");
        return setErrorMessageJackKaishijikan("配信開始時間を入力してください。");
      }
      if (!moment(jackKaishijikan).isValid()) {
        return setErrorMessageJackKaishijikan("不正な入力形式です。");
      }
      if (contentByosu === 0) {
        setErrorMessage("入力項目に誤りがあります。");
        return setErrorMessageContentByosu("コンテンツ秒数は15秒以上にしてください。");
      }
      if (contentByosu > JACK_BYOSU_MAX) {
        setErrorMessage("入力項目に誤りがあります。");
        return setErrorMessageContentByosu("コンテンツ秒数が3600秒を超えています。");
      }
      if (isCreate) {
        // 制限時間チェック
        const setingDateTime = `${moment(jackHaishinbi).format("YYYY-MM-DD")} ${moment(jackShuryojikan).format("HH:mm:ssZ")}`

        const nowDateTime = moment();
        // 現在時間に制限時間を足す
        const jackLimitDateMinutes = nowDateTime.subtract((-1 * jackLimitMinutes), 'minutes').format("YYYY-MM-DD HH:mm:ssZ")
        if (setingDateTime <= jackLimitDateMinutes) {
          setErrorMessage("入力項目に誤りがあります。");
          return setErrorMessageJackKaishijikan(`配信期限（配信の${jackLimitMinutes}分前）までに配信できない配信予定日時が設定されています。`);
        }
      }
    }

    const nextStep = activeStep + 1;
    await initJackBaitaiForm();
    setActiveStep(nextStep);
  };

  /**
   * 前のステップに戻る
   *
   */
  const handleBack = async () => {
    const prevStep = activeStep - 1;
    const confirmResult = window.confirm("現在の選択状態が破棄されます。前の画面に戻ってもよろしいですか？");
    if (confirmResult) {
      // 選択状態をクリアして、前のステップに戻る
      await initJackBaitaiForm();
      setActiveStep(prevStep);
    }
  };

  /**
   * 媒体詳細検索
   *
   * @return {*} 
   */
  const handleSearch = async () => {
    // 処理中(true)なら非同期処理せずに抜ける
    if (processing.current) return;
    // 処理中フラグを上げる
    processing.current = true;
    // 疑似非同期処理
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 1000);

    const searchParam: JackBaitaiShosaiSearchParam = {
      jack_id: henkomaeData?.id ? henkomaeData?.id : 0,
      jack_haishinbi: jackHaishinbi ? jackHaishinbi : new Date(),
      jack_kaishijikan: jackKaishijikan ? jackKaishijikan : new Date(),
      baitai_mei: baitaiMei,
      area_mei: areaMei,
      location_mei: locationMei,
      kaizodo_id: kaizodo?.id,
      is_only_selected_data: isOnlySelectedData,
    };
    const result = await postSearchJackBaitaiShosai(searchParam);
    if (result?.isError) {
      await reload?.setSnackbarInfo({
        isOpen: true,
        type: "error",
        message: result?.message,
      });
    }

    let resultChecked;

    if (isOnlySelectedData) {
      // 選択中データのみ表示：ONの場合、検索結果＋現在選択中の画面を表示する
      resultChecked = {
        data:
          [
            ...result.data.filter((d: BaitaiShosaiDataSet) => {
              return !gamenUnselectedList.data.map(gamenUnselected => gamenUnselected.id).includes(d.id)
            }),
            ...gamenSelectedList.data.map(d => ({ ...d, is_selected_data: true }))
          ]
      }
    } else {
      // 検索結果に選択中の画面があればチェックを付ける
      resultChecked = {
        data: result.data.map((d: BaitaiShosaiDataSet) => {
          return {
            ...d,
            is_selected_data:
              (
                (gamenSelectedList.data.findIndex(gamenSelected => gamenSelected.id === d.id) !== -1)
                ||
                (gamenUnselectedList.data.map(gamenUnselected => gamenUnselected.id).includes(d.id) ? false : d.is_selected_data)
              )
          }
        })
      }
    }
    // 重複排除
    resultChecked = {
      data: Array.from(new Set<BaitaiShosaiDataSet>(resultChecked.data))
    }

    const endresultChecked = resultChecked.data.filter((result: BaitaiShosaiDataSet) =>
      baitaiMei ? result.baitai_mei?.includes(baitaiMei) : true &&
        areaMei ? result.area_mei?.includes(areaMei) : true &&
          locationMei ? result.location_mei?.includes(locationMei) : true &&
            kaizodo?.id ? result.kaizodo_id === kaizodo?.id : true)

    setGamenList({ data: endresultChecked });
  };

  // --- 媒体情報フォーム、機器フォームを関数に外だし start ---
  /**
   * 媒体情報登録フォーム
   *
   * @return {*} 
   */
  const renderBaitaiForm = () => {
    return <>
      <TextField
        label="ジャック名 *"
        value={jackMei}
        onChange={e => setJackMei(e.target.value)}
        fullWidth
        variant="outlined"
        sx={{ marginTop: 1 }}
      />
      {errorMessageJackMei && (
        <small className={"error-message"}>{errorMessageJackMei}</small>
      )}
      <TextField
        fullWidth
        label="ジャック広告主名 *"
        value={jackKokokunushiMei}
        onChange={e => setJackKokokunushiMei(e.target.value)}
        sx={{ mt: 1 }}
      />
      {errorMessageJackKokokunushiMei && (
        <small className={"error-message"}>{errorMessageJackKokokunushiMei}</small>
      )}

      <SimpleDatePicker
        date={jackHaishinbi}
        setDate={setJackHaishinbi}
        label="配信開始日 *"
        disabled={!isCreate}
        minDate={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())}
      />
      {errorMessageJackHaishinbi && (
        <small className={"error-message"}>{errorMessageJackHaishinbi}</small>
      )}

      <SimpleTimePicker
        time={jackKaishijikan}
        setTime={setJackKaishijikan}
        label="配信開始時間 *"
        inputFormat="HH:mm:ss"
        viewMode={2}
        disabled={!isCreate}
      />
      {errorMessageJackKaishijikan && (
        <small className={"error-message"}>{errorMessageJackKaishijikan}</small>
      )}

      <Typography component="h3" variant="subtitle1" sx={{ mt: 1 }}>
        コンテンツ再生時間
      </Typography>
      <ContentByosu contentByosu={contentByosu} setContentByosu={setContentByosu} disabled={!isCreate} />
      {errorMessageContentByosu && (
        <small className={"error-message"}>{errorMessageContentByosu}</small>
      )}
    </>
  }

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      hide: true,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "checkbox",
      headerName: "",
      width: 62,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Checkbox checked={params.row.is_selected_data} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const newGamenList = {
            data: gamenList.data.map(d => {
              return {
                ...d,
                is_selected_data: (d.id === params.row.id) ? !d.is_selected_data : d.is_selected_data,
                is_jack_hoei_jikangai: (d.id === params.row.id) ? false : d.is_jack_hoei_jikangai
              }
            })
          }

          // チェック済みリスト、チェック外したリストを更新する
          if (params.row.is_selected_data) {
            // チェック外した場合
            // チェック済み媒体詳細IDリストから削除する
            setGamenSelectedList({ data: gamenSelectedList.data.filter(d => d.id !== params.row.id) });
            // チェック外した媒体詳細IDリストに追加する
            gamenUnselectedList.data.push(params.row);
          } else {
            // チェックした場合
            // コンテンツの再生が放映時間内に収まらない場合、警告メッセージを表示する
            // 媒体の開始終了時間を取得し、終了時間は調整を行う      
            let baitaiHoeiKaishiJikan = +moment(params.row.hoei_kaishi_jikan, 'HH:mm:ss').format('HHmmss');
            let baitaiHoeiShuryoJikan = +moment(params.row.hoei_shuryo_jikan, 'HH:mm:ss').format('HHmmss');
            const isBaitai24JikanHoei = baitaiHoeiKaishiJikan === baitaiHoeiShuryoJikan;  //24時間放映か否か

            let startTimeNum = +moment(jackKaishijikan, 'HH:mm:ss').format('HHmmss');
            let endTimeNum = +moment(jackShuryojikan, 'HH:mm:ss').format('HHmmss');

            let isJackHoeiJikannai = false;
            if (!isBaitai24JikanHoei) {
              // 媒体終了時間24
              if (baitaiHoeiKaishiJikan > baitaiHoeiShuryoJikan) {
                baitaiHoeiShuryoJikan = baitaiHoeiShuryoJikan + 240000
              }
              // ジャック開始時間24
              if (baitaiHoeiKaishiJikan > startTimeNum) {
                startTimeNum = startTimeNum + 240000;
                endTimeNum = endTimeNum + 240000;
              }
              // 判定
              if (baitaiHoeiKaishiJikan <= startTimeNum && endTimeNum <= baitaiHoeiShuryoJikan) {
                // 正常
                isJackHoeiJikannai = true;
              }
            } else {
              isJackHoeiJikannai = true;
            }

            if (!isJackHoeiJikannai) {
              reload?.setSnackbarInfo({
                isOpen: true,
                type: "warning",
                message: `ジャックの再生時間が${params.row.baitai_mei} ${params.row.gamen_mei}の放映開始または終了時間を超えています。ジャックは可能ですが、コンテンツが一部流れなくなる可能性があるためご注意ください。`,
              });

              // 時間外の行の背景色を赤にしつつ、備考にジャックが媒体の開始、終了時間に収まっていない旨を表示する
              newGamenList.data = newGamenList.data.map(d => {
                return {
                  ...d,
                  is_jack_hoei_jikangai: (d.id === params.row.id) ? true : d.is_jack_hoei_jikangai
                }
              })
            }

            // チェック済み媒体詳細IDリストに追加する
            gamenSelectedList.data.push(params.row);
            // チェック外した媒体詳細IDリストから削除する
            setGamenUnselectedList({
              data: gamenUnselectedList.data.filter(d => d.id !== params.row.id)
            });
          }

          // テーブル表示データを更新
          setGamenList(newGamenList);
        }} />
      )
    },
    {
      field: "baitai_mei",
      headerName: "媒体名",
      width: 250,
      sortable: false,
      disableColumnMenu: true,
      renderCell: renderCellExpand,
    },
    {
      field: "gamen_mei",
      headerName: "画面名",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      renderCell: renderCellExpand,
    },
    {
      field: "area_mei",
      headerName: "エリア",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      renderCell: renderCellExpand,
    },
    {
      field: "location_mei",
      headerName: "設置場所",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      renderCell: renderCellExpand,
    },
    {
      field: "hoei_kaishi_jikan",
      headerName: "放映開始時間",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      renderCell: renderCellExpand,
    },
    {
      field: "hoei_shuryo_jikan",
      headerName: "放映終了時間",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      renderCell: renderCellExpand,
    },
    {
      field: "kaizodo",
      headerName: "解像度",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      renderCell: renderCellExpand,
    },
  ]

  /**
   * ジャック媒体登録フォーム
   *
   * @return {*} 
   */
  const renderJackBaitai = () => {
    return <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'start',
          }}
        >
          <Typography component="h2" variant="subtitle1" sx={{ mr: 2 }}>
            {jackMei}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'start',
              alignItems: 'center',
            }}
          >
            <Typography component="h2" variant="subtitle1" sx={{ mr: 2 }}>
              配信開始日：{moment(jackHaishinbi).format('YYYY/MM/DD')}
            </Typography>
            <Typography component="h2" variant="subtitle1" sx={{ mr: 2 }}>
              配信開始時間：{moment(jackKaishijikan).format('HH:mm:ss')}
            </Typography>
            <Typography component="h2" variant="subtitle1">
              コンテンツ再生時間：{contentByosu}秒
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <TextField
              label="媒体名"
              value={baitaiMei}
              onChange={e => setBaitaiMei(e.target.value)}
              variant="outlined"
              sx={{ mt: 1, ml: 1, maxWith: `150px` }}
            />
            <TextField
              label="エリア"
              value={areaMei}
              onChange={e => setAreaMei(e.target.value)}
              variant="outlined"
              sx={{ mt: 1, ml: 1, maxWith: `150px` }}
            />
            <TextField
              label="設置場所"
              value={locationMei}
              onChange={e => setLocationMei(e.target.value)}
              variant="outlined"
              sx={{ mt: 1, ml: 1, maxWith: `150px` }}
            />
            <Box sx={{ ml: 1 }}>
              <KaizodoSelect kaizodo={kaizodo} setKaizodo={setKaizodo} />
            </Box>
            <FormGroup sx={{ ml: 2, mt: 2 }}>
              <FormControlLabel control={
                <Switch
                  value={isOnlySelectedData}
                  onChange={e => setIsOnlySelectedData((e.target.value === "false") ? true : false)}
                />} label="選択中データのみ表示" />
            </FormGroup>
          </Box>

          <Button
            type="button"
            variant="contained"
            sx={{ mt: 1, ml: 1, maxHeight: '36px', padding: '6px 32px' }}
            onClick={() => handleSearch()}
          >
            検索
          </Button>
        </Box>

        <div style={{ height: 650 }}>
          <DataGrid
            rows={gamenList?.data ? gamenList?.data : [{ id: 1 }]}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            sortModel={[{ field: "id", sort: "asc" }]}
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            sx={{
              mt: 1,
              mb: 1,
              // クラスを設定しておく
              '& .rows-warning': {
                background: 'rgba(255,60,0,0.25) !important',
              }
            }}
            // 特定の条件でclassを返す
            getRowClassName={(params: GridRowParams) => {
              if (params.row.is_jack_hoei_jikangai) {
                return 'rows-warning'
              }
              return ''
            }}
          />
        </div>
      </Box>
    </>
  }
  // --- 媒体情報フォーム、機器フォームを関数に外だし end ---

  // メインのJSX
  return (
    <Box sx={{ display: "flex" }}>
      {
        isCreate ?
          <Button
            type="button"
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
            onClick={() => handleClickOpen()}
          >
            新規ジャック登録
          </Button>
          :
          <Edit color="primary" fontSize='large'
            onClick={() => handleClickOpen()}
          />
      }
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            minWidth: "80vw",
            maxWidth: "80vw",
            minHeight: "30vh",
            maxHeight: "90vh"
          }
        }}
      >
        <form onSubmit={handleSubmit(handleUpdate)}>
          <Typography component="h3" variant="h5" mt={1} mb={2} ml={2}>
            {isCreate ? "新規ジャック登録" : "ジャック情報編集"}
          </Typography>
          <DialogContent sx={{ paddingTop: 0, minHeight: "30vh" }}>
            <>
              <Stepper activeStep={activeStep}>
                {steps.map((label) => {
                  const stepProps: { completed?: boolean } = {};
                  const labelProps: {
                    optional?: React.ReactNode;
                  } = {};
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              <Typography component="h3" variant="h6" sx={{ mt: 2 }}>
                {
                  (activeStep === 1) ?
                    `${steps[activeStep]}`
                    // `${steps[activeStep]} ${jackMei}`
                    :
                    steps[activeStep]
                }
              </Typography>
              {
                (gamenList.data.find(d => d.is_jack_hoei_jikangai))
                  ? <span style={{ color: "red" }}>コンテンツが放映時間内に収まらない画面が選択されています。ジャックは可能ですが、コンテンツが一部流れなくなる可能性があるためご注意ください。</span>
                  : ""
              }
              {(activeStep === 0) ? renderBaitaiForm() : ""}
              {(activeStep === 1) ? renderJackBaitai() : ""}
            </>
          </DialogContent>

          <DialogActions>
            {errorMessage && (
              <small className={"error-message"} style={{ marginRight: 8 }}>{errorMessage}</small>
            )}
            <Button variant="outlined" onClick={handleClose}>閉じる</Button>
            <Button
              variant="outlined"
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              戻る
            </Button>
            {activeStep === steps.length - 1 ?
              <LoadingButton
                loading={load}
                type="submit"
                variant="contained"
                onClick={handleUpdate}
              >
                保存
              </LoadingButton>
              :
              <Button variant="contained" onClick={handleNext}>
                次へ
              </Button>
            }
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}

export default JackEditDialog;