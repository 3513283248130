export const ItemTypes = ['thumbnail' as const];
export type ItemType = (typeof ItemTypes)[number];
export const GroupTypes = ['bangumihyo', 'content'] as const;
export type GroupType = (typeof GroupTypes)[number];
export type Content = {
  id: number;
  bangumihyo_id: number;
  hoeimoshikomi_id: number;
  bangumi_mei: string;
  kokyaku_mei: string;
  kaisha_mei: string;
  content_file_mei: string;
  content_file_path: string;
  content_file_extension: string;
  thumbnail_file_path: string;
  content_byosu: number;
  haishin_kaishibi: string;
  haishin_shuryobi: string;
  haishin_kaishijikan: string;
  haishin_shuryojikan: string;
  loginuser_id: number;
  kaisha_id: number;
  saisei_seq?: number;
};
export type Item = {
  id: number;
  type: ItemType;
  group: GroupType;
  content: Content;
};
export type ItemWithIndex = Item & {
  index: number;
};
export type MoveHandler = (dragIndex: number, targetIndex: number, groupType: GroupType) => void;
export const TitleMap = {
  bangumihyo: '番組表',
  content: 'コンテンツ一覧',
} as const;
