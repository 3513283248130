import { useContext, useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";

import {
  Button,
  Box,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  FormGroup,
  FormControl,
  FormControlLabel,
  Checkbox,
  Tab,
  Tabs,
  Stepper,
  Step,
  StepLabel,
  MenuItem,
} from "@mui/material";
import { Edit, Forward } from "@mui/icons-material";
import moment from "moment";

import { ReloadContext } from "../../context/ReloadContext";
import { updateBaitai } from "../../api/baitai";
import { getBaitaiId } from "../../api/baitai";
import { postSearchJackKaishijikan } from "../../api/jack";
import { postSearchHoeimoshikomiJikan } from "../../api/hoeimoshikomi";
import { postSearchKikiKataban } from "../../api/kikiKataban";
import {
  BaitaiDataSet,
  BaitaiEditData,
  LocationDataSet,
  BaitaiShubetsuDataSet,
  KaizodoDataSet,
  KaishaDataSet,
  KikiKatabanData,
  KikiKatabanDataSet,
  KikiKatabanSearchParam,
  KikiShubetsuDataSet,
  JackKaishijikanSet,
  hoeimoshikomiJikanSet,
} from "../../types/WebData";
import SimpleTimePicker from '../Common/SimpleTimePicker';
import LocationSelect from '../Common/LocationSelect';
import BaitaiShubetsuSelect from '../Common/BaitaiShubetsuSelect';
import KaizodoSelect from '../Common/KaizodoSelect';
import KaishaSelect from '../Common/KaishaSelect';
import KikiShubetsuSelect from '../Common/KikiShubetsuSelect';
import { GAMEN_MEI_LIST, KIKI_HIMODUKESAKI_CODE } from "../../const/index";
import LoadingButton from '@mui/lab/LoadingButton';

interface Props {
  baitaiId: number;
}

/**
 * 媒体編集ダイアログコンポーネント
 *
 * @param {Props} { baitaiId }
 * @return {*} 
 */
const BaitaiEditDialog = ({ baitaiId }: Props) => {
  const reload = useContext(ReloadContext);
  const [open, setOpen] = useState(false);
  const { handleSubmit } = useForm<any>();

  // --- 媒体フォーム用 start ---
  const [baitaiMei, setBaitaiMei] = useState<string>("");
  const [location, setLocation] = useState<LocationDataSet | null>(null);
  const [baitaiShubetsu, setBaitaiShubetsu] = useState<BaitaiShubetsuDataSet | null>(null);
  const [hoeiKaishiJikan, setHoeiKaishiJikan] = useState<Date | null>(null);
  const [hoeiShuryoJikan, setHoeiShuryoJikan] = useState<Date | null>(null);
  const [beforeHoeiKaishiJikan, setBeforeHoeiKaishiJikan] = useState<Date | null>(null);
  const [beforeHoeiShuryoJikan, setBeforeHoeiShuryoJikan] = useState<Date | null>(null);
  const [ryomenHoeiFlg, setRyomenHoeiFlg] = useState<boolean>(false);

  // 媒体詳細用のState
  const [gamenMeiA, setGamenMeiA] = useState<string>("");
  const [gamenMeiB, setGamenMeiB] = useState<string>("");
  const [gamenMeiC, setGamenMeiC] = useState<string>("");
  const [gamenMeiD, setGamenMeiD] = useState<string>("");

  const [sizeA, setSizeA] = useState<string>("");
  const [sizeB, setSizeB] = useState<string>("");
  const [sizeC, setSizeC] = useState<string>("");
  const [sizeD, setSizeD] = useState<string>("");

  const [moshikomiKigenNissuA, setMoshikomiKigenNissuA] = useState<number>(1);
  const [moshikomiKigenNissuB, setMoshikomiKigenNissuB] = useState<number>(1);
  const [moshikomiKigenNissuC, setMoshikomiKigenNissuC] = useState<number>(1);
  const [moshikomiKigenNissuD, setMoshikomiKigenNissuD] = useState<number>(1);

  const [kaizodoA, setKaizodoA] = useState<KaizodoDataSet | null>(null);
  const [kaizodoB, setKaizodoB] = useState<KaizodoDataSet | null>(null);
  const [kaizodoC, setKaizodoC] = useState<KaizodoDataSet | null>(null);
  const [kaizodoD, setKaizodoD] = useState<KaizodoDataSet | null>(null);

  const [kaishaA, setKaishaA] = useState<KaishaDataSet | null>(null);
  const [kaishaB, setKaishaB] = useState<KaishaDataSet | null>(null);
  const [kaishaC, setKaishaC] = useState<KaishaDataSet | null>(null);
  const [kaishaD, setKaishaD] = useState<KaishaDataSet | null>(null);

  const [omakasePackFlgA, setOmakasePackFlgA] = useState<boolean>(false);
  const [omakasePackFlgB, setOmakasePackFlgB] = useState<boolean>(false);
  const [omakasePackFlgC, setOmakasePackFlgC] = useState<boolean>(false);
  const [omakasePackFlgD, setOmakasePackFlgD] = useState<boolean>(false);

  // 変更前データ　入力値の初期化に使う
  const [henkomaeData, setHenkomaeData] = useState<BaitaiDataSet | null>(null);
  // 変更フラグ　A画面情報を変更したらONにする
  const [gamenAHenkoFlg, setGamenAHenkoFlg] = useState<boolean>(false);
  // 変更フラグ　B画面情報を変更したらONにする
  const [gamenBHenkoFlg, setGamenBHenkoFlg] = useState<boolean>(false);
  // 変更フラグ　C画面情報を変更したらONにする
  const [gamenCHenkoFlg, setGamenCHenkoFlg] = useState<boolean>(false);
  // 変更フラグ　D画面情報を変更したらONにする
  const [gamenDHenkoFlg, setGamenDHenkoFlg] = useState<boolean>(false);

  // タブ表示用
  const [tabIndex, setTabIndex] = useState(0);
  // --- 媒体フォーム用 end ---

  // --- 機器フォーム用 start ---
  const [kikiShubetsu, setKikiShubetsu] = useState<KikiShubetsuDataSet | null>(null);
  const [kikiKataban, setKikiKataban] = useState<string>("");
  const [kikiKatabanData, setKikiKatabanData] = useState<KikiKatabanData>({ data: [] });
  const [kikiKatabanBeforeDataBaitai, setKikiKatabanBeforeDataBaitai] = useState<KikiKatabanData>({ data: [] });
  const [kikiKatabanBeforeDataA, setKikiKatabanBeforeDataA] = useState<KikiKatabanData>({ data: [] });
  const [kikiKatabanBeforeDataB, setKikiKatabanBeforeDataB] = useState<KikiKatabanData>({ data: [] });
  const [kikiKatabanBeforeDataC, setKikiKatabanBeforeDataC] = useState<KikiKatabanData>({ data: [] });
  const [kikiKatabanBeforeDataD, setKikiKatabanBeforeDataD] = useState<KikiKatabanData>({ data: [] });
  const [kikiKatabanAfterDataBaitai, setKikiKatabanAfterDataBaitai] = useState<KikiKatabanData>({ data: [] });
  const [kikiKatabanAfterDataA, setKikiKatabanAfterDataA] = useState<KikiKatabanData>({ data: [] });
  const [kikiKatabanAfterDataB, setKikiKatabanAfterDataB] = useState<KikiKatabanData>({ data: [] });
  const [kikiKatabanAfterDataC, setKikiKatabanAfterDataC] = useState<KikiKatabanData>({ data: [] });
  const [kikiKatabanAfterDataD, setKikiKatabanAfterDataD] = useState<KikiKatabanData>({ data: [] });

  // --- 機器フォーム用 end ---

  // 処理中フラグ（ボタン連打対策）
  const processing = useRef(false);

  const isCreate = baitaiId === 0;

  // エラーメッセージ表示用
  // 登録ボタンの近くに表示するメッセージ
  const [errorMessage, setErrorMessage] = useState<string>("");
  // 媒体用のメッセージ
  const [errorMessageBaitaiMei, setErrorMessageBaitaiMei] = useState<string>("");
  const [errorMessageLocation, setErrorMessageLocation] = useState<string>("");
  const [errorMessageBaitaiShubetsu, setErrorMessageBaitaiShubetsu] = useState<string>("");
  const [errorMessageHoeiKaishiJikan, setErrorMessageHoeiKaishiJikan] = useState<string>("");
  const [errorMessageHoeiShuryoJikan, setErrorMessageHoeiShuryoJikan] = useState<string>("");
  // 媒体詳細用のメッセージ
  const [errorMessageGamenMeiA, setErrorMessageGamenMeiA] = useState<string>("");
  const [errorMessageGamenMeiB, setErrorMessageGamenMeiB] = useState<string>("");
  const [errorMessageGamenMeiC, setErrorMessageGamenMeiC] = useState<string>("");
  const [errorMessageGamenMeiD, setErrorMessageGamenMeiD] = useState<string>("");

  const [errorMessageSizeA, setErrorMessageSizeA] = useState<string>("");
  const [errorMessageSizeB, setErrorMessageSizeB] = useState<string>("");
  const [errorMessageSizeC, setErrorMessageSizeC] = useState<string>("");
  const [errorMessageSizeD, setErrorMessageSizeD] = useState<string>("");

  const [errorMessageMoshikomiKigenNissuA, setErrorMessageMoshikomiKigenNissuA] = useState<string>("");
  const [errorMessageMoshikomiKigenNissuB, setErrorMessageMoshikomiKigenNissuB] = useState<string>("");
  const [errorMessageMoshikomiKigenNissuC, setErrorMessageMoshikomiKigenNissuC] = useState<string>("");
  const [errorMessageMoshikomiKigenNissuD, setErrorMessageMoshikomiKigenNissuD] = useState<string>("");

  const [errorMessageKaizodoA, setErrorMessageKaizodoA] = useState<string>("");
  const [errorMessageKaizodoB, setErrorMessageKaizodoB] = useState<string>("");
  const [errorMessageKaizodoC, setErrorMessageKaizodoC] = useState<string>("");
  const [errorMessageKaizodoD, setErrorMessageKaizodoD] = useState<string>("");

  const [errorMessageKaishaA, setErrorMessageKaishaA] = useState<string>("");
  const [errorMessageKaishaB, setErrorMessageKaishaB] = useState<string>("");
  const [errorMessageKaishaC, setErrorMessageKaishaC] = useState<string>("");
  const [errorMessageKaishaD, setErrorMessageKaishaD] = useState<string>("");

  const [load, setLoad] = useState<boolean>(false);

  // ステッパー表示用
  const [steps, setSteps] = useState<string[]>([]);
  const [activeStep, setActiveStep] = useState<number>(0);

  useEffect(() => {
    // 媒体種別を切り替えるたびに入力状態を初期化
    initBaitaiShosaiForm();

    const steps = ["媒体登録", "機器登録(媒体)", "機器登録(画面A)"]
    if (baitaiShubetsu?.gamen_su) {
      if (baitaiShubetsu?.gamen_su > 1) steps.push("機器登録(画面B)")
      if (baitaiShubetsu?.gamen_su > 2) steps.push("機器登録(画面C)")
      if (baitaiShubetsu?.gamen_su > 3) steps.push("機器登録(画面D)")
    }
    setSteps(steps);
  }, [baitaiShubetsu])

  const initBaitaiShosaiForm = () => {
    // エラーメッセージ初期化
    initErrorMessage();

    if (!henkomaeData) return;
    // A面を選択状態にする
    handleChangeTab(0);

    // 媒体詳細
    setGamenMeiA(henkomaeData?.baitai_shosai_A?.gamen_mei ? henkomaeData?.baitai_shosai_A?.gamen_mei : "")
    setGamenMeiB(henkomaeData?.baitai_shosai_B?.gamen_mei ? henkomaeData?.baitai_shosai_B?.gamen_mei : "")
    setGamenMeiC(henkomaeData?.baitai_shosai_C?.gamen_mei ? henkomaeData?.baitai_shosai_C?.gamen_mei : "")
    setGamenMeiD(henkomaeData?.baitai_shosai_D?.gamen_mei ? henkomaeData?.baitai_shosai_D?.gamen_mei : "")

    setSizeA(henkomaeData?.baitai_shosai_A?.size ? henkomaeData?.baitai_shosai_A?.size : "")
    setSizeB(henkomaeData?.baitai_shosai_B?.size ? henkomaeData?.baitai_shosai_B?.size : "")
    setSizeC(henkomaeData?.baitai_shosai_C?.size ? henkomaeData?.baitai_shosai_C?.size : "")
    setSizeD(henkomaeData?.baitai_shosai_D?.size ? henkomaeData?.baitai_shosai_D?.size : "")

    setMoshikomiKigenNissuA(henkomaeData?.baitai_shosai_A?.moshikomi_kigen_nissu ? henkomaeData?.baitai_shosai_A?.moshikomi_kigen_nissu : 1)
    setMoshikomiKigenNissuB(henkomaeData?.baitai_shosai_B?.moshikomi_kigen_nissu ? henkomaeData?.baitai_shosai_B?.moshikomi_kigen_nissu : 1)
    setMoshikomiKigenNissuC(henkomaeData?.baitai_shosai_C?.moshikomi_kigen_nissu ? henkomaeData?.baitai_shosai_C?.moshikomi_kigen_nissu : 1)
    setMoshikomiKigenNissuD(henkomaeData?.baitai_shosai_D?.moshikomi_kigen_nissu ? henkomaeData?.baitai_shosai_D?.moshikomi_kigen_nissu : 1)

    setKaizodoA(henkomaeData?.baitai_shosai_A?.kaizodo_id ? { id: henkomaeData?.baitai_shosai_A?.kaizodo_id } : { id: 0 })
    setKaizodoB(henkomaeData?.baitai_shosai_B?.kaizodo_id ? { id: henkomaeData?.baitai_shosai_B?.kaizodo_id } : { id: 0 })
    setKaizodoC(henkomaeData?.baitai_shosai_C?.kaizodo_id ? { id: henkomaeData?.baitai_shosai_C?.kaizodo_id } : { id: 0 })
    setKaizodoD(henkomaeData?.baitai_shosai_D?.kaizodo_id ? { id: henkomaeData?.baitai_shosai_D?.kaizodo_id } : { id: 0 })

    setKaishaA(henkomaeData?.baitai_shosai_A?.kaisha_id ? { id: henkomaeData?.baitai_shosai_A?.kaisha_id } : { id: 0 })
    setKaishaB(henkomaeData?.baitai_shosai_B?.kaisha_id ? { id: henkomaeData?.baitai_shosai_B?.kaisha_id } : { id: 0 })
    setKaishaC(henkomaeData?.baitai_shosai_C?.kaisha_id ? { id: henkomaeData?.baitai_shosai_C?.kaisha_id } : { id: 0 })
    setKaishaD(henkomaeData?.baitai_shosai_D?.kaisha_id ? { id: henkomaeData?.baitai_shosai_D?.kaisha_id } : { id: 0 })
  }

  const handleClickOpen = async () => {
    // エラーメッセージ初期化
    initErrorMessage();

    // 機器フォーム初期化
    await initKikiForm(0);

    // 編集前
    setKikiKatabanBeforeDataBaitai({ data: [] });
    setKikiKatabanBeforeDataA({ data: [] });
    setKikiKatabanBeforeDataB({ data: [] });
    setKikiKatabanBeforeDataC({ data: [] });
    setKikiKatabanBeforeDataD({ data: [] });

    // 編集後
    setKikiKatabanAfterDataBaitai({ data: [] });
    setKikiKatabanAfterDataA({ data: [] });
    setKikiKatabanAfterDataB({ data: [] });
    setKikiKatabanAfterDataC({ data: [] });
    setKikiKatabanAfterDataD({ data: [] });

    // 表示ステップを先頭（媒体フォーム）にする
    setActiveStep(0);

    if (isCreate) { // 新規登録
      setBaitaiMei("")
      setLocation(null)
      setBaitaiShubetsu(null)
      setHoeiKaishiJikan(null);
      setHoeiShuryoJikan(null);
      setRyomenHoeiFlg(false);
      setGamenMeiA("")
      setGamenMeiB("")
      setGamenMeiC("")
      setGamenMeiD("")
      setSizeA("")
      setSizeB("")
      setSizeC("")
      setSizeD("")
      setMoshikomiKigenNissuA(1)
      setMoshikomiKigenNissuB(1)
      setMoshikomiKigenNissuC(1)
      setMoshikomiKigenNissuD(1)
      setKaizodoA({ id: 0 })
      setKaizodoB({ id: 0 })
      setKaizodoC({ id: 0 })
      setKaizodoD({ id: 0 })
      setKaishaA({ id: 0 })
      setKaishaB({ id: 0 })
      setKaishaC({ id: 0 })
      setKaishaD({ id: 0 })
      setOmakasePackFlgA(false);
      setOmakasePackFlgB(false);
      setOmakasePackFlgC(false);
      setOmakasePackFlgD(false);
      setTabIndex(0)

      setHenkomaeData({
        id: 0,
        baitai_mei: "",
        location_id: 0,
        baitai_shubetsu_id: 0,
        gamen_mei: "",
        hoei_kaishi_jikan: "",
        hoei_shuryo_jikan: ""
      });
    } else {
      // 媒体情報取得
      const res = await getBaitaiId(baitaiId);
      if (res?.isError) {
        await reload?.setSnackbarInfo({
          isOpen: true,
          type: "error",
          message: res?.message,
        });
      }

      const baitai = res.data[0];
      const baitaiShosaiA = res.data[0].baitai_shosai_A;
      const baitaiShosaiB = (res.data[0].baitai_shosai_B) ? res.data[0].baitai_shosai_B : null;
      const baitaiShosaiC = (res.data[0].baitai_shosai_C) ? res.data[0].baitai_shosai_C : null;
      const baitaiShosaiD = (res.data[0].baitai_shosai_D) ? res.data[0].baitai_shosai_D : null;

      setBaitaiMei(baitai.baitai_mei)
      setLocation({ id: baitai.location_id })
      setBaitaiShubetsu({ id: baitai.baitai_shubetsu_id, gamen_su: baitai.gamen_su, ryomen_hoei_kahi_flg: baitai.ryomen_hoei_kahi_flg })
      setHoeiKaishiJikan(moment(baitai.hoei_kaishi_jikan, 'HH:mm').toDate());
      setBeforeHoeiKaishiJikan(moment(baitai.hoei_kaishi_jikan, 'HH:mm').toDate());
      setHoeiShuryoJikan(moment(baitai.hoei_shuryo_jikan, 'HH:mm').toDate());
      setBeforeHoeiShuryoJikan(moment(baitai.hoei_shuryo_jikan, 'HH:mm').toDate());
      setRyomenHoeiFlg(baitai.ryomen_hoei_flg);
      setKikiKatabanBeforeDataBaitai({ data: baitai.baitai_kiki_list });
      setKikiKatabanAfterDataBaitai({ data: baitai.baitai_kiki_list });

      if (baitaiShosaiA) {
        setGamenMeiA(baitaiShosaiA.gamen_mei)
        setSizeA(baitaiShosaiA.size)
        setMoshikomiKigenNissuA(baitaiShosaiA.moshikomi_kigen_nissu)
        setKaizodoA({ id: baitaiShosaiA.kaizodo_id })
        setKaishaA({ id: baitaiShosaiA.kaisha_id })
        setOmakasePackFlgA(baitaiShosaiA.omakase_pack_flg);
        setKikiKatabanBeforeDataA({ data: baitaiShosaiA.kiki_list })
        setKikiKatabanAfterDataA({ data: baitaiShosaiA.kiki_list })
      }
      if (baitaiShosaiB) {
        setGamenMeiB(baitaiShosaiB.gamen_mei)
        setSizeB(baitaiShosaiB.size)
        setMoshikomiKigenNissuB(baitaiShosaiB.moshikomi_kigen_nissu)
        setKaizodoB({ id: baitaiShosaiB.kaizodo_id })
        setKaishaB({ id: baitaiShosaiB.kaisha_id })
        setOmakasePackFlgB(baitaiShosaiB.omakase_pack_flg);
        setKikiKatabanBeforeDataB({ data: baitaiShosaiB.kiki_list })
        setKikiKatabanAfterDataB({ data: baitaiShosaiB.kiki_list })
      }
      if (baitaiShosaiC) {
        setGamenMeiC(baitaiShosaiC.gamen_mei)
        setSizeC(baitaiShosaiC.size)
        setMoshikomiKigenNissuC(baitaiShosaiC.moshikomi_kigen_nissu)
        setKaizodoC({ id: baitaiShosaiC.kaizodo_id })
        setKaishaC({ id: baitaiShosaiC.kaisha_id })
        setOmakasePackFlgC(baitaiShosaiC.omakase_pack_flg);
        setKikiKatabanBeforeDataC({ data: baitaiShosaiC.kiki_list })
        setKikiKatabanAfterDataC({ data: baitaiShosaiC.kiki_list })
      }
      if (baitaiShosaiD) {
        setGamenMeiD(baitaiShosaiD.gamen_mei)
        setSizeD(baitaiShosaiD.size)
        setMoshikomiKigenNissuD(baitaiShosaiD.moshikomi_kigen_nissu)
        setKaizodoD({ id: baitaiShosaiD.kaizodo_id })
        setKaishaD({ id: baitaiShosaiD.kaisha_id })
        setOmakasePackFlgD(baitaiShosaiD.omakase_pack_flg);
        setKikiKatabanBeforeDataD({ data: baitaiShosaiD.kiki_list })
        setKikiKatabanAfterDataD({ data: baitaiShosaiD.kiki_list })
      }

      setTabIndex(0)

      setHenkomaeData({
        id: baitai.baitai_id,
        baitai_mei: baitai.baitai_mei,
        location_id: baitai.location_id,
        baitai_shubetsu_id: baitai.baitai_shubetsu_id,
        hoei_kaishi_jikan: baitai.hoei_kaishi_jikan,
        hoei_shuryo_jikan: baitai.hoei_shuryo_jikan,
        update_dt: moment(baitai.update_dt).format("YYYY-MM-DD HH:mm:ss"),
        baitai_shosai_A: baitaiShosaiA,
        baitai_shosai_B: baitaiShosaiB,
        baitai_shosai_C: baitaiShosaiC,
        baitai_shosai_D: baitaiShosaiD
      });
      setGamenAHenkoFlg(false)
      setGamenBHenkoFlg(false)
      setGamenCHenkoFlg(false)
      setGamenDHenkoFlg(false)
    }

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeTab = (newValue: number) => {
    setTabIndex(newValue);
  };

  /**
   * エラーメッセージ初期化処理
   */
  const initErrorMessage = () => {
    setErrorMessage("");
    setErrorMessageBaitaiMei("");
    setErrorMessageLocation("");
    setErrorMessageBaitaiShubetsu("");
    setErrorMessageHoeiKaishiJikan("");
    setErrorMessageHoeiShuryoJikan("");
    setErrorMessageGamenMeiA("");
    setErrorMessageGamenMeiB("");
    setErrorMessageGamenMeiC("");
    setErrorMessageGamenMeiD("");
    setErrorMessageSizeA("");
    setErrorMessageSizeB("");
    setErrorMessageSizeC("");
    setErrorMessageSizeD("");
    setErrorMessageMoshikomiKigenNissuA("");
    setErrorMessageMoshikomiKigenNissuB("");
    setErrorMessageMoshikomiKigenNissuC("");
    setErrorMessageMoshikomiKigenNissuD("");
    setErrorMessageKaizodoA("");
    setErrorMessageKaizodoB("");
    setErrorMessageKaizodoC("");
    setErrorMessageKaizodoD("");
    setErrorMessageKaishaA("");
    setErrorMessageKaishaB("");
    setErrorMessageKaishaC("");
    setErrorMessageKaishaD("");
  }

  // テーブル更新・一覧更新
  const handleUpdate = async () => {
    // 処理中(true)なら非同期処理せずに抜ける
    if (processing.current) return;
    // 処理中フラグを上げる
    processing.current = true;
    // 疑似非同期処理
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 1000);
    setLoad(true);
    const gamenList = [];
    const baitaikikiList = []

    const baitaiHoeiKaishiJikan = +moment(hoeiKaishiJikan, 'HH:mm').format('HHmm');
    const baitaiHoeiShuryoJikan = +moment(hoeiShuryoJikan, 'HH:mm').format('HHmm');
    const baitaiHoeiShuryoJikanOver24 = (baitaiHoeiShuryoJikan <= baitaiHoeiKaishiJikan) ? baitaiHoeiShuryoJikan + 2400 : baitaiHoeiShuryoJikan;  //放映終了時間が0時過ぎの場合
    const isBaitai24JikanHoei = baitaiHoeiKaishiJikan === baitaiHoeiShuryoJikan;  //24時間放映か否か
    let hoeijikanOutFlg: boolean = false;

    // 新規作成の場合と24時間の場合チェックしない
    if (!isCreate && !isBaitai24JikanHoei) {
      const hoeimoshikomiJikanList = await postSearchHoeimoshikomiJikan(baitaiId);
      // 放映申込の時間帯指定チェック
      hoeimoshikomiJikanList.data.forEach((hoeimoshikomi: hoeimoshikomiJikanSet) => {
        if (!hoeijikanOutFlg) {
          const startTimeNum = +moment(moment(hoeimoshikomi.haishin_kaishijikan, 'HH:mm').toDate()).format('HHmm');
          const endTimeNum = +moment(moment(hoeimoshikomi.haishin_shuryojikan, 'HH:mm').toDate()).format('HHmm');
          const endTimeOver24 = (endTimeNum < startTimeNum) ? endTimeNum + 2400 : endTimeNum;  //放映終了時間が0時過ぎの場合
          // 放映開始時間が放映時間から外れていたらNG
          if ((baitaiHoeiKaishiJikan > startTimeNum || baitaiHoeiShuryoJikanOver24 < startTimeNum)) {
            hoeijikanOutFlg = true;
          }
          // 放映終了時間が放映時間ら外れていたらNG
          if ((baitaiHoeiKaishiJikan > endTimeOver24 || baitaiHoeiShuryoJikanOver24 < endTimeOver24)) {
            hoeijikanOutFlg = true;
          }
        }
      })

      // 放映申込の時間帯指定チェックを通った場合のみ
      if (!hoeijikanOutFlg) {
        const jackKaishijikanList = await postSearchJackKaishijikan(baitaiId);
        jackKaishijikanList.data.forEach((jack: JackKaishijikanSet) => {
          if (!hoeijikanOutFlg) {
            // 時間帯指定を行った場合のバリデーションチェック
            const JacstartTimeNum = +moment(jack.jack_kaishijikan).format('HHmmss');
            // 放映開始時間、放映終了時間がジャックの放映開始時間から外れているためNG
            if (baitaiHoeiKaishiJikan * 100 > JacstartTimeNum) {
              if (baitaiHoeiShuryoJikanOver24 * 100 < JacstartTimeNum + 240000) {
                hoeijikanOutFlg = true;
              }
            } else if (baitaiHoeiShuryoJikanOver24 * 100 < JacstartTimeNum) {
              hoeijikanOutFlg = true;
            }
          }
        })
      }
    }

    if (hoeijikanOutFlg) {
      await reload?.setSnackbarInfo({
        isOpen: true,
        type: "error",
        message: "放映時間外に放映予定の番組またはジャックがあるため変更できませんでした。ご確認ください。",
      });
      await reloadSetting();
      setLoad(false);
      return;
    }

    // 画面情報、機器情報の変更があった場合のみ送信する
    // 媒体機器紐づけ
    const after = kikiKatabanAfterDataBaitai.data.sort(((a, b) => a.id - b.id));
    const before = kikiKatabanBeforeDataBaitai.data.sort(((a, b) => a.id - b.id));

    JSON.stringify(after) === JSON.stringify(before) ? baitaikikiList.push() : baitaikikiList.push(...kikiKatabanAfterDataBaitai?.data);

    // A面
    if (gamenMeiA !== "") {
      const after = kikiKatabanAfterDataA.data.sort(((a, b) => a.id - b.id));
      const before = kikiKatabanBeforeDataA.data.sort(((a, b) => a.id - b.id));

      gamenList.push(
        {
          id: henkomaeData?.baitai_shosai_A?.id,
          kaisha_id: kaishaA?.id,
          gamen_mei: gamenMeiA,
          moshikomi_kigen_nissu: moshikomiKigenNissuA,
          size: sizeA,
          kaizodo_id: kaizodoA?.id,
          omakase_pack_flg: omakasePackFlgA,
          kiki_list: JSON.stringify(after) === JSON.stringify(before) ? '' : kikiKatabanAfterDataA?.data,
          baitai_henko_flg: gamenAHenkoFlg,
        }
      )
    }
    // B面
    if (gamenMeiB !== "") {
      const after = kikiKatabanAfterDataB.data.sort(((a, b) => a.id - b.id));
      const before = kikiKatabanBeforeDataB.data.sort(((a, b) => a.id - b.id));

      gamenList.push({
        id: henkomaeData?.baitai_shosai_B?.id,
        kaisha_id: kaishaB?.id,
        gamen_mei: gamenMeiB,
        moshikomi_kigen_nissu: moshikomiKigenNissuB,
        size: sizeB,
        kaizodo_id: kaizodoB?.id,
        omakase_pack_flg: omakasePackFlgB,
        kiki_list: JSON.stringify(after) === JSON.stringify(before) ? '' : kikiKatabanAfterDataB?.data,
        baitai_henko_flg: gamenBHenkoFlg,
      })
    }
    // C面
    if (gamenMeiC !== "") {
      const after = kikiKatabanAfterDataC.data.sort(((a, b) => a.id - b.id));
      const before = kikiKatabanBeforeDataC.data.sort(((a, b) => a.id - b.id));

      gamenList.push({
        id: henkomaeData?.baitai_shosai_C?.id,
        kaisha_id: kaishaC?.id,
        gamen_mei: gamenMeiC,
        moshikomi_kigen_nissu: moshikomiKigenNissuC,
        size: sizeC,
        kaizodo_id: kaizodoC?.id,
        omakase_pack_flg: omakasePackFlgC,
        kiki_list: JSON.stringify(after) === JSON.stringify(before) ? '' : kikiKatabanAfterDataC?.data,
        baitai_henko_flg: gamenCHenkoFlg,
      })
    }
    // D面
    if (gamenMeiD !== "") {
      const after = kikiKatabanAfterDataD.data.sort(((a, b) => a.id - b.id));
      const before = kikiKatabanBeforeDataD.data.sort(((a, b) => a.id - b.id));

      gamenList.push({
        id: henkomaeData?.baitai_shosai_D?.id,
        kaisha_id: kaishaD?.id,
        gamen_mei: gamenMeiD,
        moshikomi_kigen_nissu: moshikomiKigenNissuD,
        size: sizeD,
        kaizodo_id: kaizodoD?.id,
        omakase_pack_flg: omakasePackFlgD,
        kiki_list: JSON.stringify(after) === JSON.stringify(before) ? '' : kikiKatabanAfterDataD?.data,
        baitai_henko_flg: gamenDHenkoFlg,
      })
    }

    // 送信データ作成
    const postData = {
      id: baitaiId,
      baitai_mei: baitaiMei,
      baitai_shubetsu_id: (baitaiShubetsu) ? baitaiShubetsu.id : null,
      location_id: (location) ? location.id : null,
      hoei_kaishi_jikan: moment(hoeiKaishiJikan).format('HH:mm'),
      hoei_shuryo_jikan: moment(hoeiShuryoJikan).format('HH:mm'),
      ryomen_hoei_flg: !!ryomenHoeiFlg,
      baitai_kiki_list: baitaikikiList,
      gamen_list: gamenList,
      update_dt: henkomaeData?.update_dt
    } as BaitaiEditData;

    const result = await updateBaitai(postData);
    const beforeBaitaiHoeiKaishiJikan = +moment(beforeHoeiKaishiJikan).format('HHmm');
    const beforeBaitaiHoeiShuryoJikan = +moment(beforeHoeiShuryoJikan).format('HHmm');
    // 媒体の開始時間終了時間が変更されているかチェック
    let hoeijikanChangeFlg = (beforeBaitaiHoeiKaishiJikan !== baitaiHoeiKaishiJikan) || (beforeBaitaiHoeiShuryoJikan !== baitaiHoeiShuryoJikan) ? true : false;

    await reload?.setSnackbarInfo({
      isOpen: true,
      type: result.isError ? "error" : "success",
      message: result.isError ? result.message : !isCreate && hoeijikanChangeFlg ? '更新しました。媒体への放映時間の反映は翌日以降となります。' : result.message,
    });
    await reloadSetting();
    setLoad(false);
  };

  // モーダル→画面戻り時
  const reloadSetting = () => {
    reload?.setReload(reload?.reload + 1);
    setOpen(false);
  };

  /**
   * 機器フォーム初期化処理
   *
   */
  const initKikiForm = async (activeStep: number) => {
    setKikiShubetsu(null);
    setKikiKataban("");
    const searchParam: KikiKatabanSearchParam = {
      is_visible: false,
      kiki_shubetsu_code: 0,
      kataban: "",
      kiki_himozukesaki_code: (activeStep <= 1) ? KIKI_HIMODUKESAKI_CODE.BAITAI.code : KIKI_HIMODUKESAKI_CODE.BAITAI_SHOSAI.code,
    };
    // 検索結果は全件表示、追加機器は空にしておく
    const result = await postSearchKikiKataban(searchParam);
    if (result?.isError) {
      await reload?.setSnackbarInfo({
        isOpen: true,
        type: "error",
        message: result?.message,
      });
    }
    setKikiKatabanData(result);
  }

  /**
   * 次のステップに進む
   *
   */
  const handleNext = async () => {
    // バリデーションチェック
    if (activeStep === 0) {
      // 媒体フォーム
      // エラーメッセージ初期化
      initErrorMessage();

      // バリデーションチェック
      if (baitaiMei === "") {
        setErrorMessage("入力項目に誤りがあります。");
        return setErrorMessageBaitaiMei("媒体名を入力してください。");
      }
      if (!location || location.id === 0) {
        setErrorMessage("入力項目に誤りがあります。");
        return setErrorMessageLocation("設置場所名を選択してください。");
      }
      if (!baitaiShubetsu || baitaiShubetsu.id === 0) {
        setErrorMessage("入力項目に誤りがあります。");
        return setErrorMessageBaitaiShubetsu("媒体種別名を選択してください。");
      }
      if (!hoeiKaishiJikan) {
        setErrorMessage("入力項目に誤りがあります。");
        return setErrorMessageHoeiKaishiJikan("放映開始時間を入力してください。");
      }
      if (hoeiKaishiJikan && !moment(hoeiKaishiJikan).isValid()) {
        setErrorMessage("入力項目に誤りがあります。");
        return setErrorMessageHoeiKaishiJikan("不正な入力形式です。");
      }
      if (!hoeiShuryoJikan) {
        setErrorMessage("入力項目に誤りがあります。");
        return setErrorMessageHoeiShuryoJikan("放映終了時間を入力してください。");
      }
      if (hoeiShuryoJikan && !moment(hoeiShuryoJikan).isValid()) {
        setErrorMessage("入力項目に誤りがあります。");
        return setErrorMessageHoeiShuryoJikan("不正な入力形式です。");
      }

      // A面
      if (gamenMeiA === "") {
        setErrorMessage("入力項目に誤りがあります。");
        return setErrorMessageGamenMeiA("画面名を入力してください。");
      }
      if (!kaizodoA || kaizodoA.id === 0) {
        setErrorMessage("入力項目に誤りがあります。");
        return setErrorMessageKaizodoA("解像度を入力してください。");
      }
      if (sizeA === "") {
        setErrorMessage("入力項目に誤りがあります。");
        return setErrorMessageSizeA("サイズを入力してください。");
      }
      if (moshikomiKigenNissuA < 1) {
        setErrorMessage("入力項目に誤りがあります。");
        return setErrorMessageMoshikomiKigenNissuA("申込期限日数は最短でも1日で入力してください。");
      }
      if (!kaishaA || kaishaA.id === 0) {
        setErrorMessage("入力項目に誤りがあります。");
        return setErrorMessageKaishaA("管理会社名を入力してください。");
      }
      // B面
      if (baitaiShubetsu.gamen_su && baitaiShubetsu.gamen_su > 1) {
        if (gamenMeiB === "") {
          setErrorMessage("入力項目に誤りがあります。");
          return setErrorMessageGamenMeiB("画面名を入力してください。");
        }
        if (!kaizodoB || kaizodoB.id === 0) {
          setErrorMessage("入力項目に誤りがあります。");
          return setErrorMessageKaizodoB("解像度を入力してください。");
        }
        if (sizeB === "") {
          setErrorMessage("入力項目に誤りがあります。");
          return setErrorMessageSizeB("サイズを入力してください。");
        }
        if (moshikomiKigenNissuB < 1) {
          setErrorMessage("入力項目に誤りがあります。");
          return setErrorMessageMoshikomiKigenNissuB("申込期限日数は最短でも1日で入力してください。");
        }
        if (!kaishaB || kaishaB.id === 0) {
          setErrorMessage("入力項目に誤りがあります。");
          return setErrorMessageKaishaB("管理会社名を入力してください。");
        }
      }
      // C面
      if (baitaiShubetsu.gamen_su && baitaiShubetsu.gamen_su > 2) {
        if (gamenMeiC === "") {
          setErrorMessage("入力項目に誤りがあります。");
          return setErrorMessageGamenMeiC("画面名を入力してください。");
        }
        if (!kaizodoC || kaizodoC.id === 0) {
          setErrorMessage("入力項目に誤りがあります。");
          return setErrorMessageKaizodoC("解像度を入力してください。");
        }
        if (sizeC === "") {
          setErrorMessage("入力項目に誤りがあります。");
          return setErrorMessageSizeC("サイズを入力してください。");
        }
        if (moshikomiKigenNissuC < 1) {
          setErrorMessage("入力項目に誤りがあります。");
          return setErrorMessageMoshikomiKigenNissuC("申込期限日数は最短でも1日で入力してください。");
        }
        if (!kaishaC || kaishaC.id === 0) {
          setErrorMessage("入力項目に誤りがあります。");
          return setErrorMessageKaishaC("管理会社名を入力してください。");
        }
      }
      // D面
      if (baitaiShubetsu.gamen_su && baitaiShubetsu.gamen_su > 3) {
        if (gamenMeiD === "") {
          setErrorMessage("入力項目に誤りがあります。");
          return setErrorMessageGamenMeiD("画面名を入力してください。");
        }
        if (!kaizodoD || kaizodoD.id === 0) {
          setErrorMessage("入力項目に誤りがあります。");
          return setErrorMessageKaizodoD("解像度を入力してください。");
        }
        if (sizeD === "") {
          setErrorMessage("入力項目に誤りがあります。");
          return setErrorMessageSizeD("サイズを入力してください。");
        }
        if (moshikomiKigenNissuD < 1) {
          setErrorMessage("入力項目に誤りがあります。");
          return setErrorMessageMoshikomiKigenNissuD("申込期限日数は最短でも1日で入力してください。");
        }
        if (!kaishaD || kaishaD.id === 0) {
          setErrorMessage("入力項目に誤りがあります。");
          return setErrorMessageKaishaD("管理会社名を入力してください。");
        }
      }
    }

    const nextStep = activeStep + 1;
    await initKikiForm(nextStep);
    setActiveStep(nextStep);
  };

  /**
   * 前のステップに戻る
   *
   */
  const handleBack = async () => {
    const prevStep = activeStep - 1;
    await initKikiForm(prevStep);
    setActiveStep(prevStep);
  };

  /**
   * 機器型番検索
   *
   * @return {*} 
   */
  const handleSearch = async () => {
    // 処理中(true)なら非同期処理せずに抜ける
    if (processing.current) return;
    // 処理中フラグを上げる
    processing.current = true;
    // 疑似非同期処理
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 1000);

    const searchParam: KikiKatabanSearchParam = {
      is_visible: false,
      kiki_shubetsu_code: kikiShubetsu?.code,
      kataban: kikiKataban,
      kiki_himozukesaki_code: (activeStep === 1) ? KIKI_HIMODUKESAKI_CODE.BAITAI.code : KIKI_HIMODUKESAKI_CODE.BAITAI_SHOSAI.code,
    };
    const result = await postSearchKikiKataban(searchParam);
    if (result?.isError) {
      await reload?.setSnackbarInfo({
        isOpen: true,
        type: "error",
        message: result?.message,
      });
    }
    setKikiKatabanData(result);
  };

  /**
   * 機器型番の検索結果レコードクリック処理
   * 選択状態を反転させる
   *
   * @param {number} kikiKatabanId
   */
  const handleClickKikiKatabanList = (kikiKatabanId: number) => {
    const result = {
      data: kikiKatabanData.data.map(kikiKataban => ({
        ...kikiKataban,
        is_selected: (kikiKataban.id === kikiKatabanId) ? true : false,
      }))
    }
    setKikiKatabanData(result);
  }

  /**
   * 機器型番の追加中レコードクリック処理
   * 選択状態を反転させる
   * 媒体用
   *
   * @param {number} index
   */
  const handleClickKikiKatabanSaveListBaitai = (targetIndex: number) => {
    const result = {
      data: kikiKatabanAfterDataBaitai.data.map((kikiKataban, index) => ({
        ...kikiKataban,
        is_selected: (index === targetIndex) ? true : false,
      }))
    }
    setKikiKatabanAfterDataBaitai(result);
  }
  /**
   * 機器型番の追加中レコードクリック処理
   * 選択状態を反転させる
   * A面用
   *
   * @param {number} index
   */
  const handleClickKikiKatabanSaveListA = (targetIndex: number) => {
    const result = {
      data: kikiKatabanAfterDataA.data.map((kikiKataban, index) => ({
        ...kikiKataban,
        is_selected: (index === targetIndex) ? true : false,
      }))
    }
    setKikiKatabanAfterDataA(result);
  }
  /**
   * 機器型番の追加中レコードクリック処理
   * 選択状態を反転させる
   * B面用
   *
   * @param {number} index
   */
  const handleClickKikiKatabanSaveListB = (targetIndex: number) => {
    const result = {
      data: kikiKatabanAfterDataB.data.map((kikiKataban, index) => ({
        ...kikiKataban,
        is_selected: (index === targetIndex) ? true : false,
      }))
    }
    setKikiKatabanAfterDataB(result);
  }
  /**
   * 機器型番の追加中レコードクリック処理
   * 選択状態を反転させる
   * C面用
   *
   * @param {number} index
   */
  const handleClickKikiKatabanSaveListC = (targetIndex: number) => {
    const result = {
      data: kikiKatabanAfterDataC.data.map((kikiKataban, index) => ({
        ...kikiKataban,
        is_selected: (index === targetIndex) ? true : false,
      }))
    }
    setKikiKatabanAfterDataC(result);
  }
  /**
   * 機器型番の追加中レコードクリック処理
   * 選択状態を反転させる
   * D面用
   *
   * @param {number} index
   */
  const handleClickKikiKatabanSaveListD = (targetIndex: number) => {
    const result = {
      data: kikiKatabanAfterDataD.data.map((kikiKataban, index) => ({
        ...kikiKataban,
        is_selected: (index === targetIndex) ? true : false,
      }))
    }
    setKikiKatabanAfterDataD(result);
  }

  /**
   * 機器型番追加
   * 媒体用
   */
  const handleAddKikiBaitai = () => {
    // 選択解除処理
    const unselectData = {
      data: kikiKatabanData.data.map(kikiKataban => ({
        ...kikiKataban,
        is_selected: false
      }))
    }
    setKikiKatabanData(unselectData);

    // 保存機器更新処理
    const addData = { data: kikiKatabanData.data.filter(kikiKataban => kikiKataban.is_selected) }
    const addedData = { data: [...kikiKatabanAfterDataBaitai.data, ...addData.data.map(d => ({ ...d, is_selected: false }))] }
    setKikiKatabanAfterDataBaitai(addedData);

  }
  /**
   * 機器型番削除
   * 媒体用
   */
  const handleDeleteKikiBaitai = () => {
    // filterで選択中の機器型番のみ削除
    const deletedData = { data: kikiKatabanAfterDataBaitai.data.filter(kikiKataban => !kikiKataban.is_selected) }
    setKikiKatabanAfterDataBaitai(deletedData);
  }

  /**
   * 機器型番追加
   * A面用
   */
  const handleAddKikiA = () => {
    // 選択解除処理
    const unselectData = {
      data: kikiKatabanData.data.map(kikiKataban => ({
        ...kikiKataban,
        is_selected: false
      }))
    }
    setKikiKatabanData(unselectData);

    // 保存機器更新処理
    const addData = { data: kikiKatabanData.data.filter(kikiKataban => kikiKataban.is_selected) }
    const addedData = { data: [...kikiKatabanAfterDataA.data, ...addData.data.map(d => ({ ...d, is_selected: false }))] }
    setKikiKatabanAfterDataA(addedData);
  }
  /**
   * 機器型番削除
   * A面用
   */
  const handleDeleteKikiA = () => {
    // filterで選択中の機器型番のみ削除
    const deletedData = { data: kikiKatabanAfterDataA.data.filter(kikiKataban => !kikiKataban.is_selected) }
    setKikiKatabanAfterDataA(deletedData);
  }

  /**
   * 機器型番追加
   * B面用
   */
  const handleAddKikiB = () => {
    // 選択解除処理
    const unselectData = {
      data: kikiKatabanData.data.map(kikiKataban => ({
        ...kikiKataban,
        is_selected: false
      }))
    }
    setKikiKatabanData(unselectData);

    // 保存機器更新処理
    const addData = { data: kikiKatabanData.data.filter(kikiKataban => kikiKataban.is_selected) }
    const addedData = { data: [...kikiKatabanAfterDataB.data, ...addData.data.map(d => ({ ...d, is_selected: false }))] }
    setKikiKatabanAfterDataB(addedData);
  }
  /**
   * 機器型番削除
   * B面用
   */
  const handleDeleteKikiB = () => {
    // filterで選択中の機器型番のみ削除
    const deletedData = { data: kikiKatabanAfterDataB.data.filter(kikiKataban => !kikiKataban.is_selected) }
    setKikiKatabanAfterDataB(deletedData);
  }

  /**
   * 機器型番追加
   * C面用
   */
  const handleAddKikiC = () => {
    // 選択解除処理
    const unselectData = {
      data: kikiKatabanData.data.map(kikiKataban => ({
        ...kikiKataban,
        is_selected: false
      }))
    }
    setKikiKatabanData(unselectData);

    // 保存機器更新処理
    const addData = { data: kikiKatabanData.data.filter(kikiKataban => kikiKataban.is_selected) }
    const addedData = { data: [...kikiKatabanAfterDataC.data, ...addData.data.map(d => ({ ...d, is_selected: false }))] }
    setKikiKatabanAfterDataC(addedData);
  }
  /**
   * 機器型番削除
   * C面用
   */
  const handleDeleteKikiC = () => {
    // filterで選択中の機器型番のみ削除
    const deletedData = { data: kikiKatabanAfterDataC.data.filter(kikiKataban => !kikiKataban.is_selected) }
    setKikiKatabanAfterDataC(deletedData);
  }

  /**
   * 機器型番追加
   * D面用
   */
  const handleAddKikiD = () => {
    // 選択解除処理
    const unselectData = {
      data: kikiKatabanData.data.map(kikiKataban => ({
        ...kikiKataban,
        is_selected: false
      }))
    }
    setKikiKatabanData(unselectData);

    // 保存機器更新処理
    const addData = { data: kikiKatabanData.data.filter(kikiKataban => kikiKataban.is_selected) }
    const addedData = { data: [...kikiKatabanAfterDataD.data, ...addData.data.map(d => ({ ...d, is_selected: false }))] }
    setKikiKatabanAfterDataD(addedData);
  }
  /**
   * 機器型番削除
   * D面用
   */
  const handleDeleteKikiD = () => {
    // filterで選択中の機器型番のみ削除
    const deletedData = { data: kikiKatabanAfterDataD.data.filter(kikiKataban => !kikiKataban.is_selected) }
    setKikiKatabanAfterDataD(deletedData);
  }

  // --- 媒体情報フォーム、機器フォームを関数に外だし start ---
  /**
   * 媒体情報登録フォーム
   *
   * @return {*} 
   */
  const renderBaitaiForm = () => {
    return <>
      <TextField
        id="baitaiName"
        label="媒体名 *"
        value={baitaiMei}
        onChange={e => setBaitaiMei(e.target.value)}
        fullWidth
        variant="outlined"
        sx={{ marginTop: 1 }}
      />
      {errorMessageBaitaiMei && (
        <small className={"error-message"}>{errorMessageBaitaiMei}</small>
      )}
      <LocationSelect location={location} setLocation={setLocation} label="設置場所名 *" />
      {errorMessageLocation && (
        <small className={"error-message"}>{errorMessageLocation}</small>
      )}

      <BaitaiShubetsuSelect baitaiShubetsu={baitaiShubetsu} setBaitaiShubetsu={setBaitaiShubetsu} label="媒体種別 *" disabled={!isCreate} />
      {errorMessageBaitaiShubetsu && (
        <small className={"error-message"}>{errorMessageBaitaiShubetsu}</small>
      )}

      <SimpleTimePicker
        time={hoeiKaishiJikan}
        setTime={setHoeiKaishiJikan}
        label="放映開始時間 *"
      />
      {errorMessageHoeiKaishiJikan && (
        <small className={"error-message"}>{errorMessageHoeiKaishiJikan}</small>
      )}

      <SimpleTimePicker
        time={hoeiShuryoJikan}
        setTime={setHoeiShuryoJikan}
        label="放映終了時間 *"
      />
      {errorMessageHoeiShuryoJikan && (
        <small className={"error-message"}>{errorMessageHoeiShuryoJikan}</small>
      )}

      <Box sx={{ display: "flex", flexFlow: "column" }}>
        <Typography component="h3" variant="h6" mt={2}>
          画面設定
        </Typography>
        {
          (!baitaiShubetsu || baitaiShubetsu.id === 0)
            ?
            <Typography component="h3" variant="subtitle2" mt={1} ml={1}>
              媒体種別を選択してください
            </Typography>
            :
            <Box>
              {
                // v1.1.4 両面放映を一旦廃止。要望があれば対応する。
                // (baitaiShubetsu.ryomen_hoei_kahi_flg)
                //   ?
                //   <FormControl
                //     component="fieldset"
                //     fullWidth
                //   >
                //     <FormGroup>
                //       <FormControlLabel
                //         control={
                //           <Checkbox checked={!!ryomenHoeiFlg} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                //             setRyomenHoeiFlg(!ryomenHoeiFlg);
                //           }}
                //             sx={{ marginLeft: 1 }} />
                //         }
                //         label="両面放映を行う"
                //       />
                //     </FormGroup>
                //   </FormControl>
                //   : ""
              }

              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={tabIndex} onChange={(e, value) => handleChangeTab(value)} aria-label="basic tabs example">
                    {(baitaiShubetsu.gamen_su && baitaiShubetsu.gamen_su > 0) ?
                      <Tab label={GAMEN_MEI_LIST[0] + "面"} {...a11yProps(0)} sx={{ fontSize: "large", border: "solid 1px rgba(0, 0, 0, 0.2)", borderBottom: "none", minWidth: "70px", minHeight: "40px" }} /> : ""}
                    {(baitaiShubetsu.gamen_su && baitaiShubetsu?.gamen_su > 1) ?
                      <Tab label={GAMEN_MEI_LIST[1] + "面"} {...a11yProps(1)} sx={{ fontSize: "large", border: "solid 1px rgba(0, 0, 0, 0.2)", borderBottom: "none", borderLeft: "none", minWidth: "70px", minHeight: "40px" }} /> : ""}
                    {(baitaiShubetsu.gamen_su && baitaiShubetsu?.gamen_su > 2) ?
                      <Tab label={GAMEN_MEI_LIST[2] + "面"} {...a11yProps(2)} sx={{ fontSize: "large", border: "solid 1px rgba(0, 0, 0, 0.2)", borderBottom: "none", borderLeft: "none", minWidth: "70px", minHeight: "40px" }} /> : ""}
                    {(baitaiShubetsu.gamen_su && baitaiShubetsu?.gamen_su > 3) ?
                      <Tab label={GAMEN_MEI_LIST[3] + "面"} {...a11yProps(3)} sx={{ fontSize: "large", border: "solid 1px rgba(0, 0, 0, 0.2)", borderBottom: "none", borderLeft: "none", minWidth: "70px", minHeight: "40px" }} /> : ""}
                  </Tabs>
                </Box>
                {/* // --------------- A面 --------------- */}
                <TabPanel value={tabIndex} index={0}>
                  <Box
                    sx={{
                      border: "1px solid rgba(0, 0, 0, 0.2)",
                      padding: "4px",
                      marginTop: "8px",
                      borderRadius: "4px"
                    }}>
                    <TextField
                      id="gamenMei"
                      label="画面名 *"
                      value={gamenMeiA}
                      onChange={e => {
                        setGamenAHenkoFlg(true);
                        setGamenMeiA(e.target.value)
                      }}
                      fullWidth
                      variant="outlined"
                    />
                    {errorMessageGamenMeiA && (
                      <small className={"error-message"}>{errorMessageGamenMeiA}</small>
                    )}
                    <KaizodoSelect kaizodo={kaizodoA} setKaizodo={setKaizodoA} setHenkoFlg={setGamenAHenkoFlg} label="解像度 *" />
                    {errorMessageKaizodoA && (
                      <small className={"error-message"}>{errorMessageKaizodoA}</small>
                    )}
                    <TextField
                      id="size"
                      label="サイズ *"
                      value={sizeA}
                      onChange={e => {
                        setGamenAHenkoFlg(true);
                        setSizeA(e.target.value)
                      }}
                      fullWidth
                      variant="outlined"
                      sx={{ marginTop: 1 }}
                    />
                    {errorMessageSizeA && (
                      <small className={"error-message"}>{errorMessageSizeA}</small>
                    )}
                    <TextField
                      id="henkoKigen"
                      label="変更期限日数 *"
                      type="number"
                      value={moshikomiKigenNissuA}
                      onChange={e => {
                        setGamenAHenkoFlg(true);
                        setMoshikomiKigenNissuA(+e.target.value)
                      }}
                      fullWidth
                      variant="outlined"
                      sx={{ marginTop: 1 }}
                    />
                    {errorMessageMoshikomiKigenNissuA && (
                      <small className={"error-message"}>{errorMessageMoshikomiKigenNissuA}</small>
                    )}
                    <KaishaSelect kaisha={kaishaA} setKaisha={setKaishaA} label={"管理会社名 *"} setHenkoFlg={setGamenAHenkoFlg} />
                    {errorMessageKaishaA && (
                      <small className={"error-message"}>{errorMessageKaishaA}</small>
                    )}
                    <FormControl
                      component="fieldset"
                      fullWidth
                      sx={{ display: 'none' }} 
                    >
                      <FormGroup sx={{ flexFlow: "row", alignItems: "center", marginBottom: 1 }}>
                        <FormControlLabel
                          control={
                            <>
                              <Checkbox checked={omakasePackFlgA} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setGamenAHenkoFlg(true);
                                setOmakasePackFlgA(event.target.checked);
                              }}
                                sx={{ marginLeft: 1 }} />
                            </>
                          }
                          label="おまかせパック"
                          sx={{ marginRight: 1 }}
                        />
                      </FormGroup>
                    </FormControl>
                  </Box>
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                  {/* // --------------- B面 --------------- */}
                  <Box
                    sx={{
                      border: "1px solid rgba(0, 0, 0, 0.2)",
                      padding: "4px",
                      marginTop: "8px",
                      borderRadius: "4px"
                    }}>
                    <TextField
                      id="gamenMei"
                      label="画面名 *"
                      value={gamenMeiB}
                      onChange={e => {
                        setGamenBHenkoFlg(true);
                        setGamenMeiB(e.target.value);
                      }}
                      fullWidth
                      variant="outlined"
                    />
                    {errorMessageGamenMeiB && (
                      <small className={"error-message"}>{errorMessageGamenMeiB}</small>
                    )}
                    <KaizodoSelect kaizodo={kaizodoB} setKaizodo={setKaizodoB} setHenkoFlg={setGamenBHenkoFlg} label="解像度 *" />
                    {errorMessageKaizodoB && (
                      <small className={"error-message"}>{errorMessageKaizodoB}</small>
                    )}
                    <TextField
                      id="size"
                      label="サイズ *"
                      value={sizeB}
                      onChange={e => {
                        setGamenBHenkoFlg(true);
                        setSizeB(e.target.value);
                      }}
                      fullWidth
                      variant="outlined"
                      sx={{ marginTop: 1 }}
                    />
                    {errorMessageSizeB && (
                      <small className={"error-message"}>{errorMessageSizeB}</small>
                    )}
                    <TextField
                      id="henkoKigen"
                      label="変更期限日数 *"
                      type="number"
                      value={moshikomiKigenNissuB}
                      onChange={e => {
                        setGamenBHenkoFlg(true);
                        setMoshikomiKigenNissuB(+e.target.value);
                      }}
                      fullWidth
                      variant="outlined"
                      sx={{ marginTop: 1 }}
                    />
                    {errorMessageMoshikomiKigenNissuB && (
                      <small className={"error-message"}>{errorMessageMoshikomiKigenNissuB}</small>
                    )}
                    <KaishaSelect kaisha={kaishaB} setKaisha={setKaishaB} setHenkoFlg={setGamenBHenkoFlg} label={"管理会社名 *"} />
                    {errorMessageKaishaB && (
                      <small className={"error-message"}>{errorMessageKaishaB}</small>
                    )}
                    <FormControl
                      component="fieldset"
                      fullWidth
                      sx={{ display: 'none' }}
                    >
                      <FormGroup sx={{ flexFlow: "row", alignItems: "center", marginBottom: 1 }}>
                        <FormControlLabel
                          control={
                            <>
                              <Checkbox checked={omakasePackFlgB} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setGamenBHenkoFlg(true);
                                setOmakasePackFlgB(event.target.checked);
                              }}
                                sx={{ marginLeft: 1 }} />
                            </>
                          }
                          label="おまかせパック"
                          sx={{ marginRight: 1 }}
                        />
                      </FormGroup>
                    </FormControl>
                  </Box>
                </TabPanel>
                <TabPanel value={tabIndex} index={2}>
                  {/* // --------------- C面 --------------- */}
                  <Box
                    sx={{
                      border: "1px solid rgba(0, 0, 0, 0.2)",
                      padding: "4px",
                      marginTop: "8px",
                      borderRadius: "4px"
                    }}>
                    <TextField
                      id="gamenMei"
                      label="画面名 *"
                      value={gamenMeiC}
                      onChange={e => {
                        setGamenCHenkoFlg(true);
                        setGamenMeiC(e.target.value);
                      }}
                      fullWidth
                      variant="outlined"
                    />
                    {errorMessageGamenMeiC && (
                      <small className={"error-message"}>{errorMessageGamenMeiC}</small>
                    )}
                    <KaizodoSelect kaizodo={kaizodoC} setKaizodo={setKaizodoC} setHenkoFlg={setGamenCHenkoFlg} label="解像度 *" />
                    {errorMessageKaizodoC && (
                      <small className={"error-message"}>{errorMessageKaizodoC}</small>
                    )}
                    <TextField
                      id="size"
                      label="サイズ *"
                      value={sizeC}
                      onChange={e => {
                        setGamenCHenkoFlg(true);
                        setSizeC(e.target.value);
                      }}
                      fullWidth
                      variant="outlined"
                      sx={{ marginTop: 1 }}
                    />
                    {errorMessageSizeC && (
                      <small className={"error-message"}>{errorMessageSizeC}</small>
                    )}
                    <TextField
                      id="henkoKigen"
                      label="変更期限日数 *"
                      type="number"
                      value={moshikomiKigenNissuC}
                      onChange={e => {
                        setGamenCHenkoFlg(true);
                        setMoshikomiKigenNissuC(+e.target.value);
                      }}
                      fullWidth
                      variant="outlined"
                      sx={{ marginTop: 1 }}
                    />
                    {errorMessageMoshikomiKigenNissuC && (
                      <small className={"error-message"}>{errorMessageMoshikomiKigenNissuC}</small>
                    )}
                    <KaishaSelect kaisha={kaishaC} setKaisha={setKaishaC} setHenkoFlg={setGamenCHenkoFlg} label={"管理会社名 *"} />
                    {errorMessageKaishaC && (
                      <small className={"error-message"}>{errorMessageKaishaC}</small>
                    )}
                    <FormControl
                      component="fieldset"
                      fullWidth
                      sx={{ display: 'none' }}
                    >
                      <FormGroup sx={{ flexFlow: "row", alignItems: "center", marginBottom: 1 }}>
                        <FormControlLabel
                          control={
                            <>
                              <Checkbox checked={omakasePackFlgC} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setGamenCHenkoFlg(true);
                                setOmakasePackFlgC(event.target.checked);
                              }}
                                sx={{ marginLeft: 1 }} />
                            </>
                          }
                          label="おまかせパック"
                          sx={{ marginRight: 1 }}
                        />
                      </FormGroup>
                    </FormControl>
                  </Box>
                </TabPanel>
                {/* // --------------- D面 --------------- */}
                <TabPanel value={tabIndex} index={3}>
                  <Box
                    sx={{
                      border: "1px solid rgba(0, 0, 0, 0.2)",
                      padding: "4px",
                      marginTop: "8px",
                      borderRadius: "4px"
                    }}>
                    <TextField
                      id="gamenMei"
                      label="画面名 *"
                      value={gamenMeiD}
                      onChange={e => {
                        setGamenDHenkoFlg(true);
                        setGamenMeiD(e.target.value);
                      }}
                      fullWidth
                      variant="outlined"
                    />
                    {errorMessageGamenMeiD && (
                      <small className={"error-message"}>{errorMessageGamenMeiD}</small>
                    )}
                    <KaizodoSelect kaizodo={kaizodoD} setKaizodo={setKaizodoD} setHenkoFlg={setGamenDHenkoFlg} label="解像度 *" />
                    {errorMessageKaizodoD && (
                      <small className={"error-message"}>{errorMessageKaizodoD}</small>
                    )}
                    <TextField
                      id="size"
                      label="サイズ *"
                      value={sizeD}
                      onChange={e => {
                        setGamenDHenkoFlg(true);
                        setSizeD(e.target.value);
                      }}
                      fullWidth
                      variant="outlined"
                      sx={{ marginTop: 1 }}
                    />
                    {errorMessageSizeD && (
                      <small className={"error-message"}>{errorMessageSizeD}</small>
                    )}
                    <TextField
                      id="henkoKigen"
                      label="変更期限日数 *"
                      type="number"
                      value={moshikomiKigenNissuD}
                      onChange={e => {
                        setGamenDHenkoFlg(true);
                        setMoshikomiKigenNissuD(+e.target.value);
                      }}
                      fullWidth
                      variant="outlined"
                      sx={{ marginTop: 1 }}
                    />
                    {errorMessageMoshikomiKigenNissuD && (
                      <small className={"error-message"}>{errorMessageMoshikomiKigenNissuD}</small>
                    )}
                    <KaishaSelect kaisha={kaishaD} setKaisha={setKaishaD} setHenkoFlg={setGamenDHenkoFlg} label={"管理会社名 *"} />
                    {errorMessageKaishaD && (
                      <small className={"error-message"}>{errorMessageKaishaD}</small>
                    )}
                    <FormControl
                      component="fieldset"
                      fullWidth
                      sx={{ display: 'none' }}
                    >
                      <FormGroup sx={{ flexFlow: "row", alignItems: "center", marginBottom: 1 }}>
                        <FormControlLabel
                          control={
                            <>
                              <Checkbox checked={omakasePackFlgD} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setGamenDHenkoFlg(true);
                                setOmakasePackFlgD(event.target.checked);
                              }}
                                sx={{ marginLeft: 1 }} />
                            </>
                          }
                          label="おまかせパック"
                          sx={{ marginRight: 1 }}
                        />
                      </FormGroup>
                    </FormControl>
                  </Box>
                </TabPanel>
              </Box>
            </Box>
        }
      </Box>
    </>
  }

  /**
   * 媒体機器登録フォーム
   *
   * @return {*} 
   */
  const renderBaitaiKiki = () => {
    return <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* 検索 */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}>
            <Box sx={{ minWidth: "200px" }}>
              <KikiShubetsuSelect kikiShubetsu={kikiShubetsu} setKikiShubetsu={setKikiShubetsu} />
            </Box>
            <TextField
              label="機器型番"
              value={kikiKataban}
              onChange={e => setKikiKataban(e.target.value)}
              variant="outlined"
              sx={{ mt: 1, ml: 1 }}
            />
          </Box>
          <Button
            type="button"
            variant="contained"
            sx={{ mt: 1, ml: 1 }}
            onClick={() => handleSearch()}
          >
            検索
          </Button>
        </Box>

        <Box
          sx={{
            mt: 1,
            border: 'solid 1px rgba(0,0,0,0.2)',
            minWidth: '200px',
            minHeight: '200px',
            maxHeight: '200px',
            overflowY: 'auto',
          }}>
          {
            kikiKatabanData.data.map((kikiKataban: KikiKatabanDataSet) => {
              return <MenuItem
                key={kikiKataban.id}
                value={kikiKataban.id}
                selected={kikiKataban.is_selected}
                onClick={e => handleClickKikiKatabanList(kikiKataban.id)}
                sx={{ minHeight: '42px !important' }}
              >
                {kikiKataban.kataban}
              </MenuItem>
            })
          }
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mt: 1
          }}>
          <Forward sx={{ fontSize: '70px', transform: 'rotate(90deg)', color: 'white', width: '0.875em' }} />
          <Forward sx={{ fontSize: '70px', transform: 'rotate(90deg)', color: 'gray' }} />
          <Button variant="contained" onClick={handleAddKikiBaitai} sx={{ maxHeight: '38px' }}>
            追加
          </Button>
        </Box>

        <Box
          sx={{
            mt: 1,
            border: 'solid 1px rgba(0,0,0,0.2)',
            minWidth: '200px',
            minHeight: '200px',
            maxHeight: '200px',
            overflowY: 'auto',
          }}>
          {
            kikiKatabanAfterDataBaitai.data.map((kikiKatabanSave: KikiKatabanDataSet, index: number) => {
              return <MenuItem
                key={index}
                value={kikiKatabanSave.id}
                selected={kikiKatabanSave.is_selected}
                onClick={e => handleClickKikiKatabanSaveListBaitai(index)}
                sx={{ minHeight: '42px !important' }}
              >
                {kikiKatabanSave.kataban}
              </MenuItem>
            })
          }
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 1
          }}>
          <Button color="inherit" variant="contained" onClick={handleDeleteKikiBaitai}>
            削除
          </Button>
        </Box>
      </Box>
    </>
  }

  /**
   * 画面A機器登録フォーム
   *
   * @return {*} 
   */
  const renderBaitaiShosaiAKiki = () => {
    return <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* 検索 */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}>
            <Box sx={{ minWidth: "200px" }}>
              <KikiShubetsuSelect kikiShubetsu={kikiShubetsu} setKikiShubetsu={setKikiShubetsu} />
            </Box>
            <TextField
              label="機器型番"
              value={kikiKataban}
              onChange={e => setKikiKataban(e.target.value)}
              variant="outlined"
              sx={{ mt: 1, ml: 1 }}
            />
          </Box>
          <Button
            type="button"
            variant="contained"
            sx={{ mt: 1, ml: 1 }}
            onClick={() => handleSearch()}
          >
            検索
          </Button>
        </Box>

        <Box
          sx={{
            mt: 1,
            border: 'solid 1px rgba(0,0,0,0.2)',
            minWidth: '200px',
            minHeight: '200px',
            maxHeight: '200px',
            overflowY: 'auto',
          }}>
          {
            kikiKatabanData.data.map((kikiKataban: KikiKatabanDataSet) => {
              return <MenuItem
                key={kikiKataban.id}
                value={kikiKataban.id}
                selected={kikiKataban.is_selected}
                onClick={e => handleClickKikiKatabanList(kikiKataban.id)}
                sx={{ minHeight: '42px !important' }}
              >
                {kikiKataban.kataban}
              </MenuItem>
            })
          }
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mt: 1
          }}>
          <Forward sx={{ fontSize: '70px', transform: 'rotate(90deg)', color: 'white', width: '0.875em' }} />
          <Forward sx={{ fontSize: '70px', transform: 'rotate(90deg)', color: 'gray' }} />
          <Button variant="contained" onClick={handleAddKikiA} sx={{ maxHeight: '38px' }}>
            追加
          </Button>
        </Box>

        <Box
          sx={{
            mt: 1,
            border: 'solid 1px rgba(0,0,0,0.2)',
            minWidth: '200px',
            minHeight: '200px',
            maxHeight: '200px',
            overflowY: 'auto',
          }}>
          {
            kikiKatabanAfterDataA.data.map((kikiKatabanSave: KikiKatabanDataSet, index: number) => {
              return <MenuItem
                key={index}
                value={kikiKatabanSave.id}
                selected={kikiKatabanSave.is_selected}
                onClick={e => handleClickKikiKatabanSaveListA(index)}
                sx={{ minHeight: '42px !important' }}
              >
                {kikiKatabanSave.kataban}
              </MenuItem>
            })
          }
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 1
          }}>
          <Button color="inherit" variant="contained" onClick={handleDeleteKikiA}>
            削除
          </Button>
        </Box>
      </Box>
    </>
  }

  /**
   * 画面B機器登録フォーム
   *
   * @return {*} 
   */
  const renderBaitaiShosaiBKiki = () => {
    return <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* 検索 */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}>
            <Box sx={{ minWidth: "200px" }}>
              <KikiShubetsuSelect kikiShubetsu={kikiShubetsu} setKikiShubetsu={setKikiShubetsu} />
            </Box>
            <TextField
              label="機器型番"
              value={kikiKataban}
              onChange={e => setKikiKataban(e.target.value)}
              variant="outlined"
              sx={{ mt: 1, ml: 1 }}
            />
          </Box>
          <Button
            type="button"
            variant="contained"
            sx={{ mt: 1, ml: 1 }}
            onClick={() => handleSearch()}
          >
            検索
          </Button>
        </Box>

        <Box
          sx={{
            mt: 1,
            border: 'solid 1px rgba(0,0,0,0.2)',
            minWidth: '200px',
            minHeight: '200px',
            maxHeight: '200px',
            overflowY: 'auto',
          }}>
          {
            kikiKatabanData.data.map((kikiKataban: KikiKatabanDataSet) => {
              return <MenuItem
                key={kikiKataban.id}
                value={kikiKataban.id}
                selected={kikiKataban.is_selected}
                onClick={e => handleClickKikiKatabanList(kikiKataban.id)}
                sx={{ minHeight: '42px !important' }}
              >
                {kikiKataban.kataban}
              </MenuItem>
            })
          }
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mt: 1
          }}>
          <Forward sx={{ fontSize: '70px', transform: 'rotate(90deg)', color: 'white', width: '0.875em' }} />
          <Forward sx={{ fontSize: '70px', transform: 'rotate(90deg)', color: 'gray' }} />
          <Button variant="contained" onClick={handleAddKikiB} sx={{ maxHeight: '38px' }}>
            追加
          </Button>
        </Box>

        <Box
          sx={{
            mt: 1,
            border: 'solid 1px rgba(0,0,0,0.2)',
            minWidth: '200px',
            minHeight: '200px',
            maxHeight: '200px',
            overflowY: 'auto',
          }}>
          {
            kikiKatabanAfterDataB.data.map((kikiKatabanSave: KikiKatabanDataSet, index: number) => {
              return <MenuItem
                key={index}
                value={kikiKatabanSave.id}
                selected={kikiKatabanSave.is_selected}
                onClick={e => handleClickKikiKatabanSaveListB(index)}
                sx={{ minHeight: '42px !important' }}
              >
                {kikiKatabanSave.kataban}
              </MenuItem>
            })
          }
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 1
          }}>
          <Button color="inherit" variant="contained" onClick={handleDeleteKikiB}>
            削除
          </Button>
        </Box>
      </Box>
    </>
  }

  /**
   * 画面C機器登録フォーム
   *
   * @return {*} 
   */
  const renderBaitaiShosaiCKiki = () => {
    return <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* 検索 */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}>
            <Box sx={{ minWidth: "200px" }}>
              <KikiShubetsuSelect kikiShubetsu={kikiShubetsu} setKikiShubetsu={setKikiShubetsu} />
            </Box>
            <TextField
              label="機器型番"
              value={kikiKataban}
              onChange={e => setKikiKataban(e.target.value)}
              variant="outlined"
              sx={{ mt: 1, ml: 1 }}
            />
          </Box>
          <Button
            type="button"
            variant="contained"
            sx={{ mt: 1, ml: 1 }}
            onClick={() => handleSearch()}
          >
            検索
          </Button>
        </Box>

        <Box
          sx={{
            mt: 1,
            border: 'solid 1px rgba(0,0,0,0.2)',
            minWidth: '200px',
            minHeight: '200px',
            maxHeight: '200px',
            overflowY: 'auto',
          }}>
          {
            kikiKatabanData.data.map((kikiKataban: KikiKatabanDataSet) => {
              return <MenuItem
                key={kikiKataban.id}
                value={kikiKataban.id}
                selected={kikiKataban.is_selected}
                onClick={e => handleClickKikiKatabanList(kikiKataban.id)}
                sx={{ minHeight: '42px !important' }}
              >
                {kikiKataban.kataban}
              </MenuItem>
            })
          }
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mt: 1
          }}>
          <Forward sx={{ fontSize: '70px', transform: 'rotate(90deg)', color: 'white', width: '0.875em' }} />
          <Forward sx={{ fontSize: '70px', transform: 'rotate(90deg)', color: 'gray' }} />
          <Button variant="contained" onClick={handleAddKikiC} sx={{ maxHeight: '38px' }}>
            追加
          </Button>
        </Box>

        <Box
          sx={{
            mt: 1,
            border: 'solid 1px rgba(0,0,0,0.2)',
            minWidth: '200px',
            minHeight: '200px',
            maxHeight: '200px',
            overflowY: 'auto',
          }}>
          {
            kikiKatabanAfterDataC.data.map((kikiKatabanSave: KikiKatabanDataSet, index: number) => {
              return <MenuItem
                key={index}
                value={kikiKatabanSave.id}
                selected={kikiKatabanSave.is_selected}
                onClick={e => handleClickKikiKatabanSaveListC(index)}
                sx={{ minHeight: '42px !important' }}
              >
                {kikiKatabanSave.kataban}
              </MenuItem>
            })
          }
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 1
          }}>
          <Button color="inherit" variant="contained" onClick={handleDeleteKikiC}>
            削除
          </Button>
        </Box>
      </Box>
    </>
  }

  /**
   * 画面D機器登録フォーム
   *
   * @return {*} 
   */
  const renderBaitaiShosaiDKiki = () => {
    return <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* 検索 */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}>
            <Box sx={{ minWidth: "200px" }}>
              <KikiShubetsuSelect kikiShubetsu={kikiShubetsu} setKikiShubetsu={setKikiShubetsu} />
            </Box>
            <TextField
              label="機器型番"
              value={kikiKataban}
              onChange={e => setKikiKataban(e.target.value)}
              variant="outlined"
              sx={{ mt: 1, ml: 1 }}
            />
          </Box>
          <Button
            type="button"
            variant="contained"
            sx={{ mt: 1, ml: 1 }}
            onClick={() => handleSearch()}
          >
            検索
          </Button>
        </Box>

        <Box
          sx={{
            mt: 1,
            border: 'solid 1px rgba(0,0,0,0.2)',
            minWidth: '200px',
            minHeight: '200px',
            maxHeight: '200px',
            overflowY: 'auto',
          }}>
          {
            kikiKatabanData.data.map((kikiKataban: KikiKatabanDataSet) => {
              return <MenuItem
                key={kikiKataban.id}
                value={kikiKataban.id}
                selected={kikiKataban.is_selected}
                onClick={e => handleClickKikiKatabanList(kikiKataban.id)}
                sx={{ minHeight: '42px !important' }}
              >
                {kikiKataban.kataban}
              </MenuItem>
            })
          }
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mt: 1
          }}>
          <Forward sx={{ fontSize: '70px', transform: 'rotate(90deg)', color: 'white', width: '0.875em' }} />
          <Forward sx={{ fontSize: '70px', transform: 'rotate(90deg)', color: 'gray' }} />
          <Button variant="contained" onClick={handleAddKikiD} sx={{ maxHeight: '38px' }}>
            追加
          </Button>
        </Box>

        <Box
          sx={{
            mt: 1,
            border: 'solid 1px rgba(0,0,0,0.2)',
            minWidth: '200px',
            minHeight: '200px',
            maxHeight: '200px',
            overflowY: 'auto',
          }}>
          {
            kikiKatabanAfterDataD.data.map((kikiKatabanSave: KikiKatabanDataSet, index: number) => {
              return <MenuItem
                key={index}
                value={kikiKatabanSave.id}
                selected={kikiKatabanSave.is_selected}
                onClick={e => handleClickKikiKatabanSaveListD(index)}
                sx={{ minHeight: '42px !important' }}
              >
                {kikiKatabanSave.kataban}
              </MenuItem>
            })
          }
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 1
          }}>
          <Button color="inherit" variant="contained" onClick={handleDeleteKikiD}>
            削除
          </Button>
        </Box>
      </Box>
    </>
  }
  // --- 媒体情報フォーム、機器フォームを関数に外だし end ---

  // メインのJSX
  return (
    <Box sx={{ display: "flex" }}>
      {
        isCreate ?
          <Button
            type="button"
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
            onClick={() => handleClickOpen()}
          >
            新規媒体登録
          </Button>
          :
          <Edit color="primary" fontSize='large'
            onClick={() => handleClickOpen()}
          />
      }
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            minWidth: "60vw",
            maxWidth: "80vw",
            minHeight: "30vh",
            maxHeight: "90vh"
          }
        }}
      >
        <form onSubmit={handleSubmit(handleUpdate)}>
          <Typography component="h3" variant="h5" mt={1} mb={2} ml={2}>
            {isCreate ? "新規媒体登録" : "媒体情報編集"}
          </Typography>
          <DialogContent sx={{ paddingTop: 0, minHeight: "50vh" }}>
            <>
              <Stepper activeStep={activeStep}>
                {steps.map((label) => {
                  const stepProps: { completed?: boolean } = {};
                  const labelProps: {
                    optional?: React.ReactNode;
                  } = {};
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              <Typography component="h3" variant="h6" sx={{ mt: 2 }}>
                {(activeStep === 0) ? "媒体情報登録" : ""}
                {(activeStep > 0) ? steps[activeStep] : ""}
              </Typography>
              {(activeStep === 0) ? renderBaitaiForm() : ""}
              {(activeStep === 1) ? renderBaitaiKiki() : ""}
              {(activeStep === 2) ? renderBaitaiShosaiAKiki() : ""}
              {(activeStep === 3) ? renderBaitaiShosaiBKiki() : ""}
              {(activeStep === 4) ? renderBaitaiShosaiCKiki() : ""}
              {(activeStep === 5) ? renderBaitaiShosaiDKiki() : ""}
            </>
          </DialogContent>

          <DialogActions>
            {errorMessage && (
              <small className={"error-message"} style={{ marginRight: 8 }}>{errorMessage}</small>
            )}
            <Button variant="outlined" onClick={handleClose}>閉じる</Button>
            <Button
              variant="outlined"
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              戻る
            </Button>
            {activeStep === steps.length - 1 ?
              <LoadingButton loading={load} variant="contained" onClick={handleUpdate}>
                登録
              </LoadingButton>
              :
              <Button variant="contained" onClick={handleNext}>
                次へ
              </Button>
            }
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}



// --- タブ表示処理 start ---
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
// --- タブ表示処理 end ---

export default BaitaiEditDialog;
