import { LocationDataSet, LocationSearchParam } from "../types/WebData";
import API from "./api";
import * as MESSAGE from "../const/message";
import { HTTP_STATUS_CODE } from "../const/index";

/**
 * locationテーブルAPI get
 *
 * @param {number} locationId
 */
export const getSearchLocationId = async (locationId: number) => {
  const result = await API.get(`/cms-api/location/id`, { params: { id: locationId } })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR, data: [] };
    });

  return result;
};

/**
 * locationテーブルAPI
 * 設置場所情報検索
 *
 * @param {LocationSearchParam} searchParam
 * @return {*} 
 */
export const postSearchLocation = async (searchParam: LocationSearchParam) => {
  const result = await API.post(`/cms-api/location/search`, searchParam)
    .then((res) => {
      return { data: res.data.data, isError: false, message: "" };
    })
    .catch((error) => {
      return { data: [], isError: true, message: MESSAGE.SYSTEM_ERROR };
    });
  return result;
};

/**
 * locationテーブルAPI 更新
 *
 * @param {LocationDataSet} postData
 */
export const updateLocation = async (postData: LocationDataSet) => {
  if (postData.id === 0) {
    // insert
    const result = await API.post("/cms-api/location", postData)
      .then((res) => {
        return { isError: false, message: MESSAGE.TOROKU_SEIKO };
      })
      .catch((error) => {
        return { isError: true, message: MESSAGE.SYSTEM_ERROR };
      });
    return result;
  } else {
    // update
    const result = await API.put("/cms-api/location/id", postData)
      .then((res) => {
        return { isError: false, message: MESSAGE.KOSHIN_SEIKO };
      })
      .catch((error) => {
        const status = error.response.status;
        const message = status === HTTP_STATUS_CODE.CONFLICT_ERROR ? MESSAGE.CONFLICT_ERROR : MESSAGE.SYSTEM_ERROR;
        return { isError: true, message: message, data: [] };
      });
    return result;
  }
};

/**
 * locationテーブルAPI del_flg更新
 *
 * @param {number} id
 * @param {boolean} del_flg
 */
export const deleteLocation = async (id: number, del_flg: boolean) => {
  const result = await API.put("/cms-api/location/delete", { id: id, del_flg: del_flg })
    .then((res) => {
      return { isError: false, message: "" };
    })
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR, data: [] };
    });
  return result;
};

/**
 * locationテーブルAPI 存在チェック
 *
 * @param {number} area_id
 */
export const isAreaLocation = async (area_id: number) => {
  const result = await API.post("/cms-api/location/count", { area_id: area_id })
    .then((res) => {
      return { isError: false, message: Number(res.data) > 0 ? MESSAGE.EXISTENCE_AREA : '', data: res.data };
    })
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR, data: '' };
    });
  return result;
};