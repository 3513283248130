import API from "./api";
import * as MESSAGE from "../const/message";
import { BaitaiShosaiSelectSearchParam, JackBaitaiShosaiSearchParam } from "../types/WebData";

/**
 * baitaiShosaiテーブルAPI get
 *
 * @param {number} baitaiShosaiId
 */
export const getBaitaiShosaiId = async (baitaiShosaiId: number) => {
  const result = await API.get(`/cms-api/baitaiShosai/id`, { params: { baitai_shosai_id: baitaiShosaiId } })
    .then(
      (res) => {
        return res.data.data[0];
      }
    );
  return result;
};

/**
 * baitaiShosaiテーブルAPI
 * 媒体詳細情報検索
 *
 * @param {BaitaiShosaiSelectSearchParam} searchParam
 * @return {*} 
 */
export const postSearchBaitaiShosai = async (searchParam: BaitaiShosaiSelectSearchParam) => {
  const result = await API.post(`/cms-api/baitaiShosai/search`, searchParam)
    .then((res) => {
      return { data: res.data.data, isError: false, message: "" };
    })
    .catch((error) => {
      return { data: [], isError: true, message: MESSAGE.SYSTEM_ERROR };
    });
  return result;
};

/**
 * baitaiShosaiテーブルAPI 存在チェック
 *
 * @param {number} kaizodo_id
 */
export const isBaitaiShosaiKaizodo = async (kaizodo_id: number) => {
  const result = await API.post("/cms-api/baitaiShosai/count", { kaizodo_id: kaizodo_id })
    .then((res) => {
      return { isError: false, message: Number(res.data) > 0 ? MESSAGE.EXISTENCE_KAIZODO : '', data: res.data };
    })
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR, data: '' };
    })
  return result;
}

/**
 * baitaiShosaiテーブルAPI
 * 媒体詳細情報検索（ジャック詳細ダイアログ用）
 *
 * @param {JackBaitaiShosaiSearchParam} searchParam
 * @return {*} 
 */
export const postSearchJackBaitaiShosai = async (searchParam: JackBaitaiShosaiSearchParam) => {
  const result = await API.post(`/cms-api/baitaiShosai/search/jack`, searchParam)
    .then((res) => {
      return { data: res.data.data, isError: false, message: "" };
    })
    .catch((error) => {
      return { data: [], isError: true, message: MESSAGE.SYSTEM_ERROR };
    });
  return result;
};