import { useState, useContext } from "react";

import {
  Button,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Grid
} from "@mui/material";

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { ReloadContext } from "../../context/ReloadContext";
import { getBaitaiShosaiId } from "../../api/baitaiShosai";
import { BaitaiDialogData } from "../../types/WebData";

interface Props {
  baitaiShosaiId: number;
}

/**
 * 媒体詳細ダイアログコンポーネント
 *
 * @param {Props} { baitaiShosaiId }
 * @return {*} 
 */
const BaitaiReadOnlyDialog = ({ baitaiShosaiId }: Props) => {
  const [open, setOpen] = useState(false);
  const [baitaiData, setBaitaiData] = useState<BaitaiDialogData | null>(null);
  const reloadContext = useContext(ReloadContext);

  const handleClickOpen = () => {
    const getSearchData = async () => {
      const result = await getBaitaiShosaiId(baitaiShosaiId);
      if (result.isError) {
        return reloadContext?.setSnackbarInfo({
          isOpen: true,
          type: "error",
          message: result.message,
        });
      }
      setBaitaiData(result);
    };
    getSearchData();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <OpenInNewIcon color="primary"
        onClick={() => handleClickOpen()}
      />

      <Dialog
        open={open}
        PaperProps={{
          sx: {
            width: "50vw",
          }
        }}
      >
        <Typography component="h3" variant="h6" mt={1} ml={2} mb={2}>
          媒体情報
        </Typography>
        <DialogContent sx={{ paddingTop: 0 }}>
          <Grid container spacing={1}>
            <Grid item xs={3} sx={{ mb: 1 }}>
              <Typography component="h3" variant="subtitle1" mt={1} ml={1} sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                媒体名
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography component="h3" variant="subtitle1" mt={1} sx={{ minWidth: '80%', borderBottom: 'rgba(0, 0, 0, 0.3) 1px solid' }}>
                {baitaiData?.baitai_mei || '　'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={3} sx={{ mb: 1 }}>
              <Typography component="h3" variant="subtitle1" mt={1} ml={1} sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                媒体種別
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography component="h3" variant="subtitle1" mt={1} sx={{ minWidth: '80%', borderBottom: 'rgba(0, 0, 0, 0.3) 1px solid' }}>
                {baitaiData?.shubetsu_mei || '　'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={3} sx={{ mb: 1 }}>
              <Typography component="h3" variant="subtitle1" mt={1} ml={1} sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                画面名
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography component="h3" variant="subtitle1" mt={1} sx={{ minWidth: '80%', borderBottom: 'rgba(0, 0, 0, 0.3) 1px solid' }}>
                {baitaiData?.gamen_mei || '　'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={3} sx={{ mb: 1 }}>
              <Typography component="h3" variant="subtitle1" mt={1} ml={1} sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                住所
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography component="h3" variant="subtitle1" mt={1} sx={{ minWidth: '80%', borderBottom: 'rgba(0, 0, 0, 0.3) 1px solid' }}>
                {baitaiData?.jusho || '　'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={3} sx={{ mb: 1 }}>
              <Typography component="h3" variant="subtitle1" mt={1} ml={1} sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                サイズ
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography component="h3" variant="subtitle1" mt={1} sx={{ minWidth: '80%', borderBottom: 'rgba(0, 0, 0, 0.3) 1px solid' }}>
                {baitaiData?.size || '　'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={3} sx={{ mb: 1 }}>
              <Typography component="h3" variant="subtitle1" mt={1} ml={1} sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                解像度
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography component="h3" variant="subtitle1" mt={1} sx={{ minWidth: '80%', borderBottom: 'rgba(0, 0, 0, 0.3) 1px solid' }}>
                {baitaiData?.kaizodo || '　'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={3} sx={{ mb: 1 }}>
              <Typography component="h3" variant="subtitle1" mt={1} ml={1} sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                放映開始時間
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography component="h3" variant="subtitle1" mt={1} sx={{ minWidth: '80%', borderBottom: 'rgba(0, 0, 0, 0.3) 1px solid' }}>
                {baitaiData?.hoei_kaishi_jikan || '　'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={3} sx={{ mb: 1 }}>
              <Typography component="h3" variant="subtitle1" mt={1} ml={1} sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                放映終了時間
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography component="h3" variant="subtitle1" mt={1} sx={{ minWidth: '80%', borderBottom: 'rgba(0, 0, 0, 0.3) 1px solid' }}>
                {baitaiData?.hoei_shuryo_jikan || '　'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={3} sx={{ mb: 1 }}>
              <Typography component="h3" variant="subtitle1" mt={1} ml={1} sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                申込期限日数
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography component="h3" variant="subtitle1" mt={1} sx={{ minWidth: '80%', borderBottom: 'rgba(0, 0, 0, 0.3) 1px solid' }}>
                {`放映開始日の${baitaiData?.moshikomi_kigen_nissu}日前まで申込可能` || '　'}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>閉じる</Button>
        </DialogActions>
      </Dialog>
    </Box >
  );
}

export default BaitaiReadOnlyDialog;