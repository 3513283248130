import { useContext, useState, useRef } from 'react';
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Person from "@mui/icons-material/Person";
import LoadingButton from '@mui/lab/LoadingButton';

import { ReloadContext } from "../context/ReloadContext";
import { AuthInfoContext } from "../context/AuthContext";
import PasswordReminderDialog from "../components/LoginForm/PasswordReminderDialog";
import { Copyright } from "../components/Common/Copyright";
import SimpleSnackbars from "../components/Common/SimpleSnackbars";
import { postSigninUser } from "../api/loginuser";
import { getMenuKengenList } from "../api/menu";
import * as MESSAGE from "../const/message";

const md5 = require("md5");

export interface IFormValues {
  user_id?: string;
  password?: string;
}

const theme = createTheme();

/**
 * ログイン画面コンポーネント
 *
 * @return {*} 
 */
export const LoginForm = () => {
  const navigate = useNavigate();
  const [, setAuthInfo] = useContext(AuthInfoContext);
  const reloadContext = useContext(ReloadContext);
  const { handleSubmit } = useForm<IFormValues>();
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [load, setLoad] = useState<boolean>(false);

  // エラーメッセージ表示用
  const [errorMessage, setErrorMessage] = useState("");
  // 各入力項目ごとのメッセージ
  const [errorMessageUserId, setErrorMessageUserId] = useState<string>("");
  const [errorMessagePassword, setErrorMessagePassword] = useState<string>("");

  // 処理中フラグ（ボタン連打対策）
  const processing = useRef(false);

  // システム名とバージョン表記
  const appName = process.env.REACT_APP_PACKAGE_NAME;
  const appVersion = process.env.REACT_APP_VERSION;

  /**
   * エラーメッセージ初期化処理
   */
  const initErrorMessage = () => {
    setErrorMessageUserId("");
    setErrorMessagePassword("");
  }

  const signInUser = async () => {
    // 処理中(true)なら非同期処理せずに抜ける
    if (processing.current) return;
    // 処理中フラグを上げる
    processing.current = true;
    // 疑似非同期処理
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 1000);

    // エラーメッセージ初期化
    initErrorMessage();

    if (userId === "") {
      return setErrorMessageUserId("担当者IDを入力してください。");
    }
    if (password === "") {
      return setErrorMessagePassword("パスワードを入力してください。");
    }

    setLoad(true);
    const postData = {
      user_id: userId,
      password: md5(password),
    };
    const resultSignin: any = await postSigninUser(postData);
    if (resultSignin.isError) {
      setLoad(false);
      return reloadContext?.setSnackbarInfo({
        isOpen: true,
        type: "error",
        message: resultSignin.message,
      });
    } else {
      // id、パスワードに一致するユーザー無し
      if (resultSignin.data.length === 0) {
        setErrorMessage("担当者ID,パスワードを正しく入力してください");
      } else {
        // メニュー情報を取得
        const resultGetMenu = await getMenuKengenList(resultSignin.data[0].kengen_id);
        if (resultGetMenu.isError) {
          setLoad(false);
          return reloadContext?.setSnackbarInfo({
            isOpen: true,
            type: "error",
            message: resultGetMenu.message,
          });
        }

        reloadContext?.setMenuData(resultGetMenu);

        // ログイン成功
        setAuthInfo({
          id: resultSignin.data[0].id,
          user_id: resultSignin.data[0].user_id,
          shimei: resultSignin.data[0].shimei,
          kengen: resultSignin.data[0].kengen_id,
          kaisha_id: resultSignin.data[0].kaisha_id,
          haishin_tanto_flg: resultSignin.data[0].haishin_tanto_flg,
          accessToken: resultSignin.accessToken,
          refleshToken: resultSignin.refleshToken,
          menuData: resultGetMenu
        });

        // メニューの一番上に表示される画面に遷移（権限毎に変わる）
        const topPage = resultGetMenu.data[0];
        if (topPage) {
          // スナックバーが表示されてる場合、非表示にする
          reloadContext?.setSnackbarInfo({ isOpen: false, type: "success", message: "", });
          navigate(topPage.contents_path, { state: { before_path: "loginForm" } }); // 画面遷移
        } else {
          setErrorMessage("ログインに失敗しました。");
        }
      }
    }
    setLoad(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <SimpleSnackbars />
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <Person />
          </Avatar>
          <Typography component="h1" variant="h5">
            {appName}   ver {appVersion}
          </Typography>
          <Box component="form" onSubmit={handleSubmit(signInUser)} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              fullWidth
              label="担当者ID"
              autoComplete="user_id"
              value={userId}
              onChange={e => setUserId(e.target.value)}
            />
            {errorMessageUserId && (
              <small className={"error-message"}>{errorMessageUserId}</small>
            )}
            <TextField
              margin="normal"
              fullWidth
              label="パスワード"
              type="password"
              autoComplete="current-password"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            {errorMessagePassword && (
              <small className={"error-message"}>{errorMessagePassword}</small>
            )}

            <p>
              <span className={"error-message"}>{errorMessage}</span>
            </p>

            <LoadingButton
              loading={load}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              ログイン
            </LoadingButton>
            <Grid container>
              <div style={{ flexGrow: 1 }}></div>
              <Grid item xs>
                <PasswordReminderDialog />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright />
      </Container>
    </ThemeProvider>
  );
}