import React, { useState, createContext, useContext, useEffect } from "react";
import { CancelTokenSource } from "axios";

import { SnackbarInterface } from "../components/Common/SimpleSnackbars";
import { AuthInfoContext } from "../context/AuthContext";
import { MenuData } from "../types/WebData";

type Props = {
  children: React.ReactNode;
};

type UploadingInfo = {
  isUploading: boolean;
  source?: CancelTokenSource;
};

type DownloadingInfo = {
  isDownloading: boolean;
  source?: CancelTokenSource;
};

type InitialState = {
  reload: number;
  setReload: React.Dispatch<React.SetStateAction<number>>;
  snackbarInfo: SnackbarInterface;
  setSnackbarInfo: React.Dispatch<React.SetStateAction<SnackbarInterface>>;
  menuData: MenuData;
  setMenuData: React.Dispatch<React.SetStateAction<MenuData>>;
  uploadingInfo: UploadingInfo;
  setUploadingInfo: React.Dispatch<React.SetStateAction<UploadingInfo>>;
  downloadingInfo: DownloadingInfo;
  setDownloadingInfo: React.Dispatch<React.SetStateAction<DownloadingInfo>>;
};

export const ReloadContext = createContext<InitialState | null>(null);

export const ReloadProvider: React.VFC<Props> = ({ children }) => {
  const [authInfo] = useContext(AuthInfoContext);
  const [reload, setReload] = useState(0);
  const [snackbarInfo, setSnackbarInfo] = useState<SnackbarInterface>({
    isOpen: false,
    type: undefined,
    message: "",
  });
  const [menuData, setMenuData] = useState<MenuData>({ data: [] });
  const [uploadingInfo, setUploadingInfo] = useState({ isUploading: false });
  const [downloadingInfo, setDownloadingInfo] = useState({ isDownloading: false });

  useEffect(() => {
    if (authInfo.menuData) {
      setMenuData(authInfo.menuData);
    }
  }, [authInfo])

  return (
    <ReloadContext.Provider
      value={{
        reload,
        setReload,
        snackbarInfo,
        setSnackbarInfo,
        menuData,
        setMenuData,
        uploadingInfo,
        setUploadingInfo,
        downloadingInfo,
        setDownloadingInfo
      }}
    >
      {children}
    </ReloadContext.Provider>
  );
};
