import React from "react";

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ja from 'date-fns/locale/ja'

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

type Props = {
  startDate: Date | null;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  endDate: Date | null;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  minDate?: Date;
  required?: boolean;
};

/**
 * 日付範囲DatePickerコンポーネント
 *
 * @param {Props} {
 *   startDate,
 *   setStartDate,
 *   endDate,
 *   setEndDate,
 *   minDate,
 *   required = false
 * }
 * @return {*} 
 */
const DateRangePicker = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  minDate,
  required = false
}: Props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
      <Grid container mt={1}>
        <Grid item md={5.5}>
          <DatePicker
            mask="____/__/__"
            label="開始"
            value={startDate}
            inputFormat={"yyyy/MM/dd"}
            onChange={(newValue: Date | null) => {
              setStartDate(newValue);
            }}
            renderInput={(params: any) => <TextField {...params} required={required} />}
            minDate={minDate}
          />
        </Grid>
        <Grid item md={1} style={{ display: "table", textAlign: "center" }}>
          <div style={{ display: "table-cell", verticalAlign: "middle" }}>
            ～
          </div>
        </Grid>
        <Grid item md={5.5}>
          <DatePicker
            mask="____/__/__"
            label="終了"
            value={endDate}
            inputFormat={"yyyy/MM/dd"}
            onChange={(newValue: Date | null) => {
              setEndDate(newValue);
            }}
            renderInput={(params: any) => <TextField {...params} required={required} />}
            minDate={startDate || undefined}
          />
        </Grid>
      </Grid>
    </LocalizationProvider >
  );
};

export default DateRangePicker;
