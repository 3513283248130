import { useState, useContext, useEffect, useCallback, useRef, useImperativeHandle, forwardRef } from "react";

import { useNavigate } from 'react-router-dom';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend';

import Box from '@mui/material/Box';

import { getBangumihyoShosai } from "../../api/bangumihyoShosai";
import { ReloadContext } from "../../context/ReloadContext";
import Group from './Group';
import useGroupedItems from './hooks/useGroupedItems';
import { Item, GroupTypes, MoveHandler } from '../Common/Preview/data';
import { postBangumihyoShosai } from "../../api/bangumihyoShosai";
import { BangumihyoShosaiData, BangumihyoShosaiProps, BangumihyoShosaiScroll } from "../../types/WebData";
import { BANGUMIHYO_STATUS } from "../../const/index";
import { isBangumihyo } from "../../api/bangumihyo";

import { AlertColor } from "@mui/material/Alert";

import moment from "moment";

interface Props {
  ref: any;
  state: BangumihyoShosaiProps;
  bangumihyoShosaiData: BangumihyoShosaiData;
  setBangumihyoShosaiData: React.Dispatch<React.SetStateAction<BangumihyoShosaiData>>;
}

/**
 * 再生順編集エリアコンポーネント
 *
 * @return {*} 
 */
const PlayOrder = ({ state, bangumihyoShosaiData, setBangumihyoShosaiData }: Props, ref: any) => {
  const navigate = useNavigate();
  const reloadContext = useContext(ReloadContext);
  const processing = useRef(false);
  const [draggingItem, setDraggingItem] = useState<Item | null>(null);
  const [updateDt, setUpdateDt] = useState<string | null>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [bangumiSelectedId, setBangumiSelectedId] = useState<number>(0);
  const [contentSelectedId, setContentSelectedId] = useState<number>(0);
  const [bangumihyoShosaiScroll, setBangumihyoShosaiScroll] = useState<BangumihyoShosaiScroll | null>(null);
  let index = 0;

  // 初期表示処理
  useEffect(() => {
    // 検索ボタン押下時
    const getSearchData = async () => {
      const result = await getBangumihyoShosai({ baitai_shosai_id: state.baitai_shosai_id, bangumihyo_id: state.bangumihyo_id, haishin_kaishibi: (state.haishin_kaishibi) ? moment(state.haishin_kaishibi).format('YYYY-MM-DD') : "" });
      if (result.data.length === 0) {
        await reloadContext?.setSnackbarInfo({
          isOpen: true,
          type: "warning",
          message: "番組が1件もありません。",
        });
        navigate("/bangumihyo", { state }); // 画面遷移
      }
      setUpdateDt(moment(result.data[0].update_dt).format("YYYY-MM-DD HH:mm:ss"));
      setBangumihyoShosaiData(result);
      console.log(result.data);
      if (result.message) {
        await reloadContext?.setSnackbarInfo({
          isOpen: true,
          type: result.isError ? "error" : "success",
          message: result.message,
        });
      }
    };
    getSearchData();
  }, [state, reloadContext?.reload]);

  // ドラッグ状態を更新する
  useEffect(() => {
    if (draggingItem) {
      setIsDragging(true)
    }
    else {
      setIsDragging(false)
    }
  }, [draggingItem]);

  const [groupedItems, items, setItems] = useGroupedItems(bangumihyoShosaiData);
  const moveItem: MoveHandler = useCallback((dragIndex, targetIndex, group) => {
    const selectedGroup: "bangumihyo" | "content" = (draggingItem) ? draggingItem.group : group;
    const selectedItems: Item[] | undefined = groupedItems[selectedGroup];
    const item = selectedItems ? selectedItems[dragIndex] : selectedItems;
    const targetItems = groupedItems[group];
    const targetItem = targetItems ? targetItems[targetIndex] : targetItems;
    if (!item) return;

    setItems(prevState => {
      // 処理中(true)なら非同期処理せずに抜ける
      if (processing.current) return prevState;
      // 処理中フラグを上げる
      processing.current = true;

      const insertItem = (draggingItem) ? draggingItem : item;
      const newItems = prevState.filter((p) => p.id !== insertItem.id);
      const newItemsTemp = JSON.parse(JSON.stringify(newItems));
      const targetItemIndex = (targetItem) ? prevState.findIndex(p => p.id === targetItem.id) : 0;
      newItemsTemp.splice(targetItemIndex, 0, { ...insertItem, group });
      const ids = new Set(newItemsTemp.map((i: any) => i.id));
      if (ids.size !== prevState.length) {
        processing.current = false;
        return prevState;
      }
      processing.current = false;
      return newItemsTemp;
    })
  }, [items, draggingItem, groupedItems]);

  // グループ間の移動を行う
  const moveGroup: MoveHandler = useCallback((selectIndex, targetIndex, group) => {
    // 移動元・移動先判定
    let selectedGroup: 'content' | 'bangumihyo' = 'content'
    let targetGroup: 'content' | 'bangumihyo' = 'bangumihyo'
    if (group === 'content') {
      selectedGroup = 'bangumihyo';
      targetGroup = 'content';
    }

    setItems(prevState => {
      // 処理中(true)なら非同期処理せずに抜ける
      if (processing.current) return prevState;
      // 処理中フラグを上げる
      processing.current = true;

      const selectedItems = groupedItems[selectedGroup];
      const item = selectedItems ? selectedItems[selectIndex] : null;
      if (!item) return;
      const targetItems = groupedItems[targetGroup];
      if (!targetItems) return;

      const newItems = prevState.filter((p) => p.id !== item.id);
      const newItemsTemp = JSON.parse(JSON.stringify(newItems));

      let tempIndex: number;
      let targetItem: Item;
      let targetItemIndex: number;
      // 領域外のtargetIndexを指定された場合
      if (targetIndex > targetItems.length - 1) {
        targetItem = targetItems[targetItems.length - 1];
        targetItemIndex = newItems.findIndex(p => p.id === targetItem.id) + 1;
      }
      else if (targetIndex < 0) {
        targetItem = targetItems[0];
        targetItemIndex = newItems.findIndex(p => p.id === targetItem.id);
      }
      else {
        tempIndex = targetIndex;
        targetItem = targetItems[tempIndex];
        targetItemIndex = newItems.findIndex(p => p.id === targetItem.id);
      }

      newItemsTemp.splice(targetItemIndex, 0, { ...item, group });
      const ids = new Set(newItemsTemp.map((i: any) => i.id));
      if (ids.size !== prevState.length) {
        processing.current = false;
        return prevState;
      }
      processing.current = false;
      return newItemsTemp;
    })
  }, [items, groupedItems]);

  useImperativeHandle(ref, () => ({
    handleTempSave: (setload: React.Dispatch<React.SetStateAction<boolean>>) => {
      handleTempSave(setload)
    },
    handleSave: (setload: React.Dispatch<React.SetStateAction<boolean>>) => {
      handleSave(setload)
    }
  }))

  const handleTempSave = async (setload: React.Dispatch<React.SetStateAction<boolean>>) => {
    if (state.status !== BANGUMIHYO_STATUS.SAKUSEICHU) {
      setload(false);
      return reloadContext?.setSnackbarInfo({
        isOpen: true,
        type: "error",
        message: "番組表保存後の一時保存はできません。",
      });
    }

    const message = "番組表を一時保存しました。";
    let counter = 0;
    const postData = {
      rows: items.map(i => {
        if ((i.group === 'bangumihyo')) {
          counter = counter + 1;
        }
        return {
          ...i.content,
          baitai_shosai_id: state.baitai_shosai_id,
          saisei_seq: (i.group === 'bangumihyo') ? counter : 0
        }
      }),
      baitai_id: state.baitai_id,
      baitai_shosai_id: state.baitai_shosai_id,
      update_dt: updateDt,
      haishin_kaishibi: moment(state.haishin_kaishibi).format('YYYY/MM/DD'),
      save_mode: 0
    };

    if (counter === 0) {
      setload(false);
      return reloadContext?.setSnackbarInfo({
        isOpen: true,
        type: "error",
        message: "番組表エリアにコンテンツがありません。",
      });
    }
    const conflict = await isBangumihyo(postData)

    if (conflict?.isError) {
      reloadContext?.setSnackbarInfo({
        isOpen: true,
        type: "error",
        message: conflict.message,
      });
      if (!postData.rows[0].bangumihyo_id) {
        setload(false);
        navigate("/bangumihyo", { state }); // 画面遷移
      }
    } else {
      const result = await postBangumihyoShosai(postData);
      state.bangumihyo_id = result.bangumihyoId;
      state.status = BANGUMIHYO_STATUS.SAKUSEICHU;

      reloadContext?.setSnackbarInfo({
        isOpen: true,
        type: result.isError ? "error" : "success",
        message: result.isError ? result.message : message,
      });
      setBangumiSelectedId(0);
      setContentSelectedId(0);
    }

    setload(false);
    reloadContext?.setReload(reloadContext?.reload + 1);

  }

  const handleSave = async (setload: React.Dispatch<React.SetStateAction<boolean>>) => {
    const message = "番組表を保存しました。";
    let counter = 0;
    const postData = {
      rows: items.map(i => {
        if ((i.group === 'bangumihyo')) {
          counter = counter + 1;
        }
        return {
          ...i.content,
          bangumihyo_id: state.bangumihyo_id,
          baitai_shosai_id: state.baitai_shosai_id,
          saisei_seq: (i.group === 'bangumihyo') ? counter : 0
        }
      }),
      baitai_id: state.baitai_id,
      baitai_shosai_id: state.baitai_shosai_id,
      update_dt: updateDt,
      haishin_kaishibi: moment(state.haishin_kaishibi).format('YYYY/MM/DD'),
      save_mode: 1
    };

    if (counter < items.length) {
      setload(false);
      return reloadContext?.setSnackbarInfo({
        isOpen: true,
        type: "error",
        message: "番組表エリアに全てのコンテンツを並べてください。",
      });
    }
    const conflict = await isBangumihyo(postData)

    if (conflict?.isError) {
      reloadContext?.setSnackbarInfo({
        isOpen: true,
        type: "error",
        message: conflict.message,
      });
      if (!postData.rows[0].bangumihyo_id) {
        setload(false);
        navigate("/bangumihyo", { state }); // 画面遷移
      }
    } else {
      console.log(postData);
      const result = await postBangumihyoShosai(postData);
      state.bangumihyo_id = result.bangumihyoId;
      state.status = BANGUMIHYO_STATUS.SAKUSEIZUMI;

      let type: AlertColor = 'error'
      let result_message = `${result.message}`
      if (result.isError) {
        type = 'error'
        result_message = `${result.message}`
      } else {
        if (result.failedMailAddresses?.length) {
          type = 'warning'
          result_message = `${message}\r\n${result.message}\r\n${result.failedMailAddresses.join("\r\n")}`
        } else {
          type = 'success';
          result_message = `${message}\r\n${result.message}`
        }
      }
      // 送信に失敗したメールアドレスがあれば、スナックバーに表示する

      reloadContext?.setSnackbarInfo({
        isOpen: true,
        type: type,
        message: result_message,
      });
      setBangumiSelectedId(0);
      setContentSelectedId(0);
    }
    setload(false);
    reloadContext?.setReload(reloadContext?.reload + 1);
  }

  return (
    <Box>
      <DndProvider backend={HTML5Backend}>
        {GroupTypes.map(group => {
          const items = groupedItems[group];
          const bangumiItems = groupedItems['bangumihyo'];
          const firstIndex = index;
          if (bangumiItems === undefined || items === undefined) return null;
          index = index + items.length;

          return (
            <section key={group} className='group-section'>
              <Group
                bangumiItems={bangumiItems}
                items={items}
                state={state}
                groupType={group}
                firstIndex={firstIndex}
                onMove={moveItem}
                onMoveGroup={moveGroup}
                draggingItem={draggingItem}
                setDraggingItem={setDraggingItem}
                isDragging={isDragging}
                setIsDragging={setIsDragging}
                bangumiSelectedId={bangumiSelectedId}
                setBangumiSelectedId={setBangumiSelectedId}
                contentSelectedId={contentSelectedId}
                setContentSelectedId={setContentSelectedId}
                bangumihyoShosaiScroll={bangumihyoShosaiScroll}
                setBangumihyoShosaiScroll={setBangumihyoShosaiScroll}
              />
            </section>
          )
        })}
      </DndProvider>
    </Box>
  );
}

export default forwardRef(PlayOrder);
