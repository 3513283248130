import API from "./api";
import * as MESSAGE from "../const/message";

/**
 * kengenテーブルAPI
 * 権限情報取得
 *
 * @param {}
 * @return {*} 
 */
export const getKengen = async () => {
  const result = await API.get(`/cms-api/kengen/`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR, data: [] };
    });
  return result;
};

