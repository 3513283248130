import { useContext, useEffect, useState, useRef } from "react";
import { useLocation } from 'react-router-dom';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import moment from "moment";

import {
  Box,
  Toolbar,
  Grid,
  TextField,
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';

import { KENGEN } from "../const/index";
import { AuthInfoContext } from "../context/AuthContext";
import JackEditDialog from '../components/Jack/JackEditDialog';
import JackHaishinConfirmDialog from '../components/Jack/JackHaishinConfirmDialog';
import SimpleDatePicker from '../components/Common/DatePicker/SimpleDatePicker';
import { Copyright } from "../components/Common/Copyright";
import JackGrid from '../components/Jack/JackGrid';
import { JackSearchParam, JackDataSet, JackShosaiProps } from "../types/WebData";

const mdTheme = createTheme();

/**
 * ジャック管理画面コンポーネント
 *
 * @return {*} 
 */
export const Jack = () => {
  const location = useLocation();
  const state = location.state as JackShosaiProps;

  const [authInfo] = useContext(AuthInfoContext);
  const [selectedRow, setSelectedRow] = useState<JackDataSet | null>(null);
  const [jackMei, setJackMei] = useState<string>("");
  const [jackKokokunushiMei, setJackKokokunushiMei] = useState<string>("");
  const [jackHaishinbiFrom, setJackHaishinbiFrom] = useState<Date | null>(new Date());
  const [jackHaishinbiTo, setJackHaishinbiTo] = useState<Date | null>(null);
  const [areaMei, setAreaMei] = useState<string>("");
  const [locationMei, setLocationMei] = useState<string>("");
  const [baitaiMei, setBaitaiMei] = useState<string>("");
  const [isHaishinZumi, setIsHaishinZumi] = useState<boolean>(false);

  const [searchParam, setSearchParam] = useState<JackSearchParam | null>(null);

  // 処理中フラグ（ボタン連打対策）
  const processing = useRef(false);
  const [load, setLoad] = useState<boolean>(false);

  useEffect(() => {
    // 検索条件復元
    if (state?.searchParam) {
      setJackMei(state?.searchParam.jack_mei ? state?.searchParam.jack_mei : "")
      setJackKokokunushiMei(state?.searchParam.jack_kokokunushi_mei ? state?.searchParam.jack_kokokunushi_mei : "")
      setJackHaishinbiFrom(state?.searchParam.jack_haishinbi_from ? moment(state?.searchParam.jack_haishinbi_from).toDate() : null)
      setJackHaishinbiTo(state?.searchParam.jack_haishinbi_to ? moment(state?.searchParam.jack_haishinbi_to).toDate() : null)
      setAreaMei(state?.searchParam.area_mei ? state?.searchParam.area_mei : "")
      setLocationMei(state?.searchParam.location_mei ? state?.searchParam.location_mei : "")
      setBaitaiMei(state?.searchParam.baitai_mei ? state?.searchParam.baitai_mei : "")
      setIsHaishinZumi(state?.searchParam.is_haishin_zumi ? state?.searchParam.is_haishin_zumi : false)
      setSearchParam(state?.searchParam);
    } else {
      const searchParam: JackSearchParam = { jack_haishinbi_from: moment(jackHaishinbiFrom).format('YYYY-MM-DD') };
      setSearchParam(searchParam);
    }
  }, [])

  // 検索実行
  const handleSearch = async () => {
    // 処理中(true)なら非同期処理せずに抜ける
    if (processing.current) return;
    // 処理中フラグを上げる
    processing.current = true;
    // 疑似非同期処理
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 1000);
    setLoad(true);

    const searchParam: JackSearchParam = {
      jack_mei: jackMei,
      jack_kokokunushi_mei: jackKokokunushiMei,
      jack_haishinbi_from: (jackHaishinbiFrom !== null) ? moment(jackHaishinbiFrom).format('YYYY-MM-DD') : "",
      jack_haishinbi_to: (jackHaishinbiTo !== null) ? moment(jackHaishinbiTo).format('YYYY-MM-DD') : "",
      area_mei: areaMei,
      location_mei: locationMei,
      baitai_mei: baitaiMei,
      is_haishin_zumi: isHaishinZumi
    };
    await setSearchParam(searchParam);
  };

  /**
   * 配信ボタン 表示非表示判定
   */
  const isShowHaishinConfirmDialog = () => {
    let result = false;
    // 配信担当者 かつ 管理者
    if (authInfo?.haishin_tanto_flg && (authInfo?.kengen === KENGEN.ADMIN || authInfo?.kengen === KENGEN.OMAKASE)) {
      result = true;
    }
    return result;
  }

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />

          <Container maxWidth={false} sx={{ mt: 0, mb: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <JackEditDialog jackId={0} />
              {isShowHaishinConfirmDialog() ?
                <JackHaishinConfirmDialog selectedRow={selectedRow} />
                : ""}
            </Box>

            <Grid container spacing={1}>
              {/* 検索 */}
              <Grid item xs={3} md={4} lg={3}>
                <Paper
                  sx={{
                    p: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '75vh',
                  }}
                >
                  <Box>
                    <Box sx={{ overflowY: 'auto', maxHeight: '65vh' }}>
                      <Paper
                        elevation={0}
                        sx={{
                          p: 1,
                        }}
                      >
                        <Typography component="h3" variant="h6" ml={1} sx={{
                          maxWidth: '200px',
                          background: 'linear-gradient(transparent 60%, #a8eaff 60%)'
                        }}>
                          基本情報<span style={{ fontSize: "smaller" }}>から探す</span>
                        </Typography>

                        <TextField
                          fullWidth
                          id="jack_mei"
                          label="ジャック名"
                          value={jackMei}
                          name="jack_mei"
                          sx={{ mt: 1 }}
                          onChange={(e) => setJackMei(e.target.value)}
                        />
                        <TextField
                          fullWidth
                          id="jack_kokokunushi_mei"
                          label="ジャック広告主名"
                          value={jackKokokunushiMei}
                          name="jack_kokokunushi_mei"
                          sx={{ mt: 1 }}
                          onChange={(e) => setJackKokokunushiMei(e.target.value)}
                        />
                        <SimpleDatePicker
                          date={jackHaishinbiFrom}
                          setDate={setJackHaishinbiFrom}
                          label="配信日開始"
                        />
                        <SimpleDatePicker
                          date={jackHaishinbiTo}
                          setDate={setJackHaishinbiTo}
                          label="配信日終了"
                        />
                      </Paper>

                      <Paper
                        elevation={0}
                        sx={{
                          p: 1,
                          mt: 1,
                        }}
                      >
                        <Typography component="h3" variant="h6" ml={1} sx={{
                          maxWidth: '200px',
                          background: 'linear-gradient(transparent 60%, #a8eaff 60%)'
                        }}>
                          場所<span style={{ fontSize: "smaller" }}>から探す</span>
                        </Typography>
                        <TextField
                          fullWidth
                          id="area_mei"
                          label="エリア名"
                          value={areaMei}
                          name="area_mei"
                          sx={{ mt: 1 }}
                          onChange={(e) => setAreaMei(e.target.value)}
                        />
                        <TextField
                          fullWidth
                          id="location_mei"
                          label="設置場所名"
                          value={locationMei}
                          name="location_mei"
                          sx={{ mt: 1 }}
                          onChange={(e) => setLocationMei(e.target.value)}
                        />
                        <TextField
                          fullWidth
                          id="baitai_mei"
                          label="媒体名"
                          value={baitaiMei}
                          name="baitai_mei"
                          sx={{ mt: 1 }}
                          onChange={(e) => setBaitaiMei(e.target.value)}
                        />
                      </Paper>

                      <FormGroup sx={{ ml: 1, mt: 1 }}>
                        <FormControlLabel control={
                          <Switch
                            id="is_visble"
                            checked={isHaishinZumi}
                            value={isHaishinZumi}
                            onChange={(e) => setIsHaishinZumi((e.target.value === "true") ? false : true)} />}
                          label="放映終了したデータを表示する" />
                      </FormGroup>
                    </Box>

                    <LoadingButton
                      loading={load}
                      type="button"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      onClick={() => handleSearch()}
                    >
                      検索
                    </LoadingButton>
                  </Box>
                </Paper>
              </Grid>
              {/* 一覧 */}
              <Grid item xs={9} md={8} lg={9} pl={0}>
                {/* 一覧 */}
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '75vh',
                  }}
                >
                  <JackGrid searchParam={searchParam} setSelectedRow={setSelectedRow} setload={setLoad} />
                </Paper>
              </Grid>
            </Grid>
            <Copyright />
          </Container>
        </Box>
      </Box>
    </ThemeProvider >
  );
}