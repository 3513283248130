import { useEffect, useState, useContext } from "react";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { postSearchKaizodo } from "../../api/kaizodo";
import { ReloadContext } from "../../context/ReloadContext";
import { KaizodoData, KaizodoDataSet } from "../../types/WebData";

type Props = {
  kaizodo: KaizodoDataSet | null;
  setKaizodo: React.Dispatch<React.SetStateAction<KaizodoDataSet | null>>;
  setHenkoFlg?: React.Dispatch<React.SetStateAction<boolean>>;
  label?: string;
  disabled?: boolean;
};

/**
 * 解像度セレクトボックスコンポーネント
 *
 * @param {Props} { kaizodo, setKaizodo, setHenkoFlg, label, disabled = false }
 * @return {*} 
 */
const KaizodoSelect = ({ kaizodo, setKaizodo, setHenkoFlg, label = "解像度", disabled = false }: Props) => {
  const [kaizodoData, setKaizodoData] = useState<KaizodoData>({ data: [] });
  const reloadContext = useContext(ReloadContext);

  // 初期化処理
  useEffect(() => {
    let isMounted = true;
    const getKaizodoData = async () => {
      const searchParam = { id: kaizodo?.id, kaizodo: "", is_visible: false };
      const result = await postSearchKaizodo(searchParam);
      if (result.isError) {
        return reloadContext?.setSnackbarInfo({
          isOpen: true,
          type: "error",
          message: result.message,
        });
      }
      const blank = { id: 0 } as KaizodoDataSet;
      result.data.unshift(blank);
      setKaizodoData(result);
    };
    if (isMounted) getKaizodoData();
    return () => { isMounted = false };
  }, []);

  const handleChange = (event: SelectChangeEvent) => {
    const kaizodo = kaizodoData.data.find(kaizodo => event.target.value === String(kaizodo.id))
    if (!kaizodo) return;
    if (setHenkoFlg) setHenkoFlg(true);
    setKaizodo(kaizodo);
  };

  return (
    <Box sx={{ minWidth: 120 }} mt={1}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label" >{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={(kaizodo) ? String(kaizodo.id) : ""}
          label={label}
          onChange={handleChange}
          disabled={disabled}
        >
          {kaizodoData.data.map((kaizodo) => {
            return (
              <MenuItem key={kaizodo.id} value={String(kaizodo.id)} sx={{ height: "36px" }}>
                {kaizodo.kaizodo}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}

export default KaizodoSelect;