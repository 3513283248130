import React from "react";

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import ja from 'date-fns/locale/ja'

import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

type Props = {
  time: Date | null;
  setTime: React.Dispatch<React.SetStateAction<Date | null>>;
  label: string;
  inputFormat?: string;
  disabled?: boolean;
  // viewMode 0:時間のみ、1:時分、2:時分秒
  viewMode?: number;
};

/**
 * 単一TimePickerコンポーネント
 *
 * @param {Props} { time, setTime, label, inputFormat, disabled = false, viewMode = 0 }
 * @return {*} 
 */
const SimpleTimePicker = ({ time, setTime, label, inputFormat = "HH:mm", disabled = false, viewMode = 1 }: Props) => {
  return (
    <Box sx={{ mt: 1 }}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
        <TimePicker
          label={label}
          value={time}
          inputFormat={inputFormat}
          views={viewMode === 0 ? ["hours"] : viewMode === 1 ? ["hours", "minutes"] : ["hours", "minutes", "seconds"]}
          onChange={(newValue: Date | null, keyboardInputValue?: string | undefined) => {
            //  HH:Mの中途半端な入力の際はsetStateしない
            if (keyboardInputValue?.length === 4 || keyboardInputValue?.length === 7) {
              return;
            }
            setTime(newValue || null);
          }}
          renderInput={(params) => <TextField {...params} fullWidth />}
          disabled={disabled}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default SimpleTimePicker;
