import { useContext, useState, useRef } from "react";
import { Box, Grid, Paper } from "@mui/material";

import { ReloadContext } from "../../context/ReloadContext";
import { getMenu, getMenuList } from "../../api/menu";
import { updateMenuUsage } from "../../api/menuUsage";
import { MenuData, MenuUsageDataSet } from "../../types/WebData";
import MenuUsageGrid from "./MenuUsageGrid";
import MenuUsageCheck from "./MenuUsageCheck";
import KengenRadio from "./KengenRadio";
import LoadingButton from '@mui/lab/LoadingButton';

import moment from "moment";

/**
 * メニュー利用設定コンポーネント
 *
 * @return {*} 
 */
const KengenForm = () => {
  const [menuData, setMenuData] = useState<MenuData>({ data: [] });
  const [checkedMenu, setCheckedMenu] = useState<string[]>([]);
  const reload = useContext(ReloadContext);
  const [kengen, setKengen] = useState<number>(1);
  // 処理中フラグ（ボタン連打対策）
  const processing = useRef(false);
  const [load, setLoad] = useState<boolean>(false);

  // 更新実行
  const handleUpdate = async () => {
    // 必須入力チェック
    let errorMessage = "";
    if (checkedMenu.length === 0) {
      errorMessage = "利用するメニューを選択してください";
    }
    if (errorMessage !== "") {
      await reload?.setSnackbarInfo({
        isOpen: true,
        type: "error",
        message: errorMessage,
      });
    } else {
      // 処理中(true)なら非同期処理せずに抜ける
      if (processing.current) return;
      // 処理中フラグを上げる
      processing.current = true;
      // 疑似非同期処理
      setTimeout(() => {
        // 処理中フラグを下げる
        processing.current = false;
      }, 1000);

      setLoad(true);
      const rows: MenuUsageDataSet[] = [];

      // menu_usage rows作成
      menuData.data
        .filter((option) => (option.kengen_id == null || option.kengen_id === kengen))
        .forEach((menu) => {
          rows.push({
            id: menu.usage_id,
            kengen_id: kengen,
            menu_id: menu.id,
            riyo_flg: checkedMenu.indexOf(String(menu.id)) !== -1,
            update_dt: moment(menu.update_dt).format("YYYY-MM-DD HH:mm:ss"),
          });
        });

      // menu_usage更新
      const result = await updateMenuUsage(rows);
      await reload?.setSnackbarInfo({
        isOpen: true,
        type: result.isError ? "error" : "success",
        message: result.message,
      });

      // 更新後、RelaodContextのメニューを再設定
      const resultMenu = await getMenu();
      if (resultMenu.isError) {
        return reload?.setSnackbarInfo({
          isOpen: true,
          type: "error",
          message: resultMenu.message,
        });
      }
      reload?.setMenuData(resultMenu);

      // 更新後、更新した権限のメニューを再設定
      const resultMenuList = await getMenuList(kengen);
      if (resultMenuList.isError) {
        return reload?.setSnackbarInfo({
          isOpen: true,
          type: "error",
          message: resultMenuList.message,
        });
      }
      setMenuData(resultMenuList);
      setLoad(false);
      //リロードする
      await reload?.setReload(reload?.reload + 1);
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid
        item
        md={5}
        sx={{
          margin: 0,
          padding: 0,
        }}
      >
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: '80vh',
            overflowY: 'auto',
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "80%",
              margin: "20px",
            }}
          >
            <KengenRadio kengen={kengen} setKengen={setKengen} />
            <MenuUsageCheck
              menuData={menuData}
              setMenuData={setMenuData}
              checkedMenu={checkedMenu}
              setCheckedMenu={setCheckedMenu}
              kengen={kengen}
            />
          </Box>
          <Box
            sx={{
              p: 2,
              display: "flex",
              width: "100%",
            }}
          >
            <LoadingButton
              loading={load}
              variant="contained"
              sx={{
                width: "200px",
                margin: "10px",
              }}
              onClick={handleUpdate}
            >
              更新
            </LoadingButton>
          </Box>
        </Paper>
      </Grid>
      <Grid item md={7}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            width: "100%",
            height: '80vh',
            overflowY: 'auto',
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <MenuUsageGrid />
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default KengenForm;
