import {
  BangumihyoSearchParam,
} from "../types/WebData";
import API from "./api";
import * as MESSAGE from "../const/message";
import { HTTP_STATUS_CODE } from "../const/index"

/**
 * bangumihyoテーブルAPI
 * 番組表検索
 * 非媒体管理者用
 *
 * @param {BangumihyoSearchParam} searchParam
 * @return {*} 
 */
export const postSearchBangumihyo = async (searchParam: BangumihyoSearchParam) => {
  const result = await API.post(`/cms-api/bangumihyo/search`, searchParam)
    .then(
      (res) => {
        return { isError: false, message: "", data: res.data };
      })
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR, data: [] };
    });
  return result;
};

/**
 * bangumihyoテーブルAPI
 * 番組表検索
 * 媒体管理者用
 * ※システム管理者の場合は媒体管理会社ではなくてもこちらのAPIで検索する
 *
 * @param {BangumihyoSearchParam} searchParam
 * @return {*} 
 */
export const postSearchBangumihyoKanri = async (searchParam: BangumihyoSearchParam) => {
  const result = await API.post(`/cms-api/bangumihyo/search/kanri`, searchParam)
    .then(
      (res) => {
        return { isError: false, message: "", data: res.data };
      })
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR, data: [] };
    });
  return result;
};

/**
 * bangumihyoテーブルAPI
 * 番組表ダウンロード日付取得
 *
 * @param {string} haishin_kaishibi
 * @param {number} baitai_shosai_id
 * @return 
 */
export const getBangumihyoDownloadHizuke = async (haishin_kaishibi: string, baitai_shosai_id?: number) => {
  const result = await API.get(`/cms-api/bangumihyo/download/hizuke`, { params: { haishin_kaishibi, baitai_shosai_id } })
    .then((res) => {
      return { isError: res.data.length === 0, data: res.data, message: res.data.length === 0 ? "まだ番組表が作成されていません。" : "" };
    })
    .catch((error) => {
      return { isError: true, data: null, message: MESSAGE.SYSTEM_ERROR };
    });
  return result;
};

/**
 * bangumihyoテーブルAPI
 * 番組表ダウンロード
 *
 * @param {string} haishin_kaishibi
 * @param {number} kaisha_id
 * @param {string} loginuser_mei
 * @param {number} kengen
 * @param {number} baitai_id
 * @param {number} baitai_shosai_id
 * @return 
 */
export const postBangumihyoDownload = async (haishin_kaishibi_list: any, kaisha_id: number, loginuser_mei: string, kengen: number, baitai_id?: number, baitai_shosai_id?: number) => {
  const result = await API.post(`/cms-api/bangumihyo/download`, { haishin_kaishibi_list, baitai_id, baitai_shosai_id, kaisha_id, loginuser_mei, kengen })
    .then((res) => {
      return { isError: false, data: res.data, message: "" };
    })
    .catch((error) => {
      return { isError: true, data: null, message: MESSAGE.SYSTEM_ERROR };
    });
  return result;
};

/**
 * bangumihyoテーブルAPI
 * 番組表 配信処理
 *
 * @param {number} id
 * @param {number} baitai_shosai_id
 * @param {string} haishin_kaishibi
 * @return {*} 
 */
export const postBangumihyoHaishin = async (id: number, baitai_shosai_id?: number, haishin_kaishibi?: string) => {
  const result = await API.put(`/cms-api/bangumihyo/haishin`, { id, baitai_shosai_id, haishin_kaishibi })
    .then((res) => {
      return { isError: false, message: "配信に成功しました。" };
    })
    .catch((error) => {
      const noContentBangumiMei = error.response.data.noContentBangumiMei;
      const message = noContentBangumiMei ? `番組「${noContentBangumiMei}」にコンテンツがアップロードされていないため、配信できません。` : MESSAGE.SYSTEM_ERROR;
      return { isError: true, message };
    });
  return result;
};

/**
 * bangumihyoテーブルAPI
 * 番組表存在チェック
 *
 * @param {string} haishin_kaishibi
 * @param {number} baitai_shosai_id
 * @return {*} 
 */
export const getExistBangumihyo = async (haishin_kaishibi: string, baitai_shosai_id?: number) => {
  const result = await API.get(`/cms-api/bangumihyo/exist`, { params: { baitai_shosai_id, haishin_kaishibi } })
    .then(
      (res) => {
        return { isError: false, message: "", data: res.data };
      })
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR, data: [] };
    });
  return result;
};

/**
 * bangumihyoテーブルAPI
 * 番組表削除
 *
 * @param {number} id
 * @return {*} 
 */
export const deleteBangumihyo = async (id: number) => {
  const result = await API.put(`/cms-api/bangumihyo/delete/id`, { id })
    .then((res) => {
      return { isError: false, message: "" };
    })
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR };
    });
  return result;
};

/**
 * bangumihyoテーブルAPI
 * 番組表存在チェック
 *
 * @param {string} haishin_kaishibi
 * @param {number} baitai_shosai_id
 * @return {*} 
 */
export const isBangumihyo = async (postData: any) => {
  const result = await API.post(`/cms-api/bangumihyo/conflict`, postData)
    .then(
      (res) => {
        return { isError: false, message: "", data: res.data };
      })
    .catch((error) => {
      const status = error.response.status;
      const message = status === HTTP_STATUS_CODE.CONFLICT_ERROR ? Number(error.response.data) === 0 ? MESSAGE.CONFLICT_ERROR : MESSAGE.BANGUMIHYO_CONFLICT_ERROR : MESSAGE.SYSTEM_ERROR;
      return { isError: true, message: message, data: [] };
    });
  return result;
};

