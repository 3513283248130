import {
  UserSettingDataSet,
  UserSettingSearchParam
} from "../types/WebData";
import API from "./api";
import * as MESSAGE from "../const/message";
import { Kengen, HTTP_STATUS_CODE, CUSTOM_ERROR_CODE } from "../const/index";

/**
 * loginuerテーブルAPI get
 * ログイン担当者詳細情報取得
 *
 * @param {number} id
 * @param {number} loginuserId
 * @param {Kengen} kengen
 */
export const getLoginUserId = async (id: number, loginuserId: number, kengen: Kengen) => {
  const result = await API.get(`/cms-api/loginuser/id`, { params: { id: id, loginuser_id: loginuserId, kengen: kengen } })
    .then((res) => {
      return { isError: false, message: "", data: res.data.data };
    })
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR, data: [] };
    });
  return result;
};

/**
 * loginuerテーブルAPI
 * 担当者検索
 *
 * @param {UserSettingSearchParam} searchParam
 * @return {*} 
 */
export const postSearchUserSetting = async (searchParam: UserSettingSearchParam) => {
  const result = await API.post(`/cms-api/loginuser/search`, searchParam)
    .then((res) => {
      return { data: res.data.data, isError: false, message: "" };
    })
    .catch((error) => {
      return { data: [], isError: true, message: MESSAGE.SYSTEM_ERROR };
    });
  return result;
};

/**
 * loginuerテーブルAPI get
 *
 * @param {number} id
 * @param {Kengen} kengen
 */
export const getLoginUser = async (id: number, kengen: Kengen) => {
  const result = await API.get(`/cms-api/loginuser`, { params: { id, kengen } })
    .then((res) => {
      return { isError: false, message: "", data: res.data.data };
    })
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR, data: [] };
    });
  return result;
};

/**
 * loginuserテーブルAPI 更新
 *
 * @param {UserSettingDataSet} postData
 */
export const updateLoginUser = async (postData: UserSettingDataSet) => {
  if (postData.id === 0) {
    // insert
    const result = await API.post("/cms-api/loginuser", postData)
      .then((res) => {
        return { isError: false, message: MESSAGE.TOROKU_SEIKO, status: "" };
      })
      .catch((error) => {
        const status = error.response.status;
        const customStatus = error.response.data.status;
        let message = "";
        if (customStatus === CUSTOM_ERROR_CODE.DB_UNIQUE_ERROR) {
          message = error.response.data.message
        } else if (status === HTTP_STATUS_CODE.CONFLICT_ERROR) {
          message = MESSAGE.CONFLICT_ERROR
        } else {
          message = MESSAGE.SYSTEM_ERROR
        }
        return { isError: true, message: message, status: status };
      });
    return result;
  } else {
    // update
    const result = await API.put("/cms-api/loginuser/id", postData)
      .then((res) => {
        return { isError: false, message: MESSAGE.USER_EDIT, status: "" };
      })
      .catch((error) => {
        const status = error.response.status;
        let message = "";
        if (status === HTTP_STATUS_CODE.CONFLICT_ERROR) {
          message = MESSAGE.CONFLICT_ERROR
        } else {
          message = MESSAGE.SYSTEM_ERROR
        }
        return { isError: true, message: message, status: status };
      });
    return result;
  }
};

/**
 * loginuserテーブルAPI delete
 *
 * @param {number} id
 * @param {boolean} del_flg
 */
export const deleteLoginUser = async (id: number, del_flg: boolean) => {
  const result = await API.put("/cms-api/loginuser/delete", { id: id, del_flg: del_flg })
    .then((res) => {
      return { isError: false, message: "" };
    })
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR };
    });
  return result;
};

/**
 * loginuserテーブルAPI post signin
 *  ログイン画面用
 *
 * @param {user_id: string | undefined; password: string | undefined;} postData
 */
export const postSigninUser = async (postData: {
  user_id: string | undefined;
  password: string | undefined;
}) => {
  const result = await API.post("/cms-api/loginuser/signin", postData)
    .then((res) => {
      return { isError: false, message: "", data: res.data.data, accessToken: res.data.accessToken, refleshToken: res.data.refleshToken };
    })
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR, data: [], accessToken: "", refleshToken: "" };
    });
  return result;
};

/**
 * loginuserテーブルAPI post パスワード再設定メール送信
 *
 * @param {string} user_id
 */
export const postPasswordReminder = async (user_id: string) => {
  const result = await API.post("/cms-api/loginuser/passwordReminder", { user_id: user_id })
    .then((res) => {
      return { isError: false, message: MESSAGE.SEND_PASSWORD_REMINDER_MAIL };
    })
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR };
    });
  return result;
};

/**
 * loginuserテーブルAPI put パスワード再設定
 *
 * @param {user_id: string | undefined; password: string | undefined;} postData
 */
export const putPasswordReSetting = async (postData: {
  user_id: string | undefined;
  password: string | undefined;
}) => {
  const result = await API.put("/cms-api/loginuser/passwordReSetting", postData)
    .then((res) => {
      return { isError: false, message: "パスワードを再設定しました。" };
    })
    .catch((error) => {
      return { isError: true, message: "パスワード再設定に失敗しました。" };
    });
  return result;
};

/**
 * loginuserテーブルAPI post トークンリフレッシュ
 *
 * @param {string} userId
 */
export const postTokenReflesh = async (userId: string) => {
  const result = await API.post("/cms-api/loginuser/tokenReflesh", { user_id: userId })
    .then((res) => {
      return { isError: false, message: res.data.message, data: res.data.data, accessToken: res.data.accessToken, refleshToken: res.data.refleshToken };
    })
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR, data: [], accessToken: "", refleshToken: "" };
    });
  return result;
};

/**
 * loginuserテーブル 存在チェック
 *
 * @param {number} kaisha_id
 */
export const isKaishaUser = async (kaisha_id: number) => {
  const result = await API.post("/cms-api/loginuser/count", { kaisha_id: kaisha_id })
    .then((res) => {
      return { isError: false, message: Number(res.data) > 0 ? MESSAGE.EXISTENCE_KAISHA : '', data: res.data };
    })
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR, data: '' };
    });
  return result;
};