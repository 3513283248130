import React from 'react';

import type { Content } from '../Common/Preview/data';
import BangumiReadOnlyDialog from './BangumiReadOnlyDialog'

import GroupModule from './Group.module.css';
import { Item as _Item } from '../Common/Preview/data';

const Item: React.FC<{
  items: _Item[];
  index: number;
  id: number;
  contents: Content;
  groupName: string;
  selectedId: number;
  setSelectedId: React.Dispatch<React.SetStateAction<number>>
}> = ({ items, index, id, contents, groupName, selectedId, setSelectedId }) => {
  return (
    <div className={GroupModule['item']}>
      <BangumiReadOnlyDialog
        items={items}
        index={index}
        id={id}
        bangumi_mei={contents.bangumi_mei}
        kokyaku_mei={contents.kokyaku_mei}
        kaisha_mei={contents.kaisha_mei}
        content_file_mei={contents.content_file_mei}
        thumbnail_file_path={contents.thumbnail_file_path}
        content_byosu={contents.content_byosu}
        groupName={groupName}
        haishin_kaishibi={contents.haishin_kaishibi}
        haishin_shuryobi={contents.haishin_shuryobi}
        haishin_kaishijikan={contents.haishin_kaishijikan}
        haishin_shuryojikan={contents.haishin_shuryojikan}
        selectedId={selectedId}
        setSelectedId={setSelectedId}
      />
    </div>
  )
};

export default Item;