import { useEffect, useState, useContext } from "react";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { HoeiKubunDataSet, CodeMasterData, CodeMasterSearchParam } from "../../types/WebData";
import { postSearchCodemaster } from '../../api/codemaster';
import { ReloadContext } from "../../context/ReloadContext";
import { CODE_MASTER_CATEGORY } from "../../const/index";

type Props = {
  hoeiKubun: HoeiKubunDataSet;
  setHoeiKubun: React.Dispatch<React.SetStateAction<HoeiKubunDataSet>>;
  label?: string;
  disabled?: boolean;
};

/**
 * 放映区分セレクトボックスコンポーネント
 *
 * @param {Props} { hoeiKubun, setHoeiKubun, label, disabled = false }
 * @return {*} 
 */
const HoeiKubunSelect = ({ hoeiKubun, setHoeiKubun, label = "放映区分", disabled = false }: Props) => {
  const reloadContext = useContext(ReloadContext);
  const [hoeiKubunData, setHoeiKubunData] = useState<CodeMasterData>({ data: [] });

  // 初期化処理
  useEffect(() => {
    const getHoeiKubunData = async () => {
      const searchParam: CodeMasterSearchParam = { category_id: CODE_MASTER_CATEGORY.HOEI_KUBUN }
      const result = await postSearchCodemaster(searchParam)
      if (result.isError) {
        return reloadContext?.setSnackbarInfo({
          isOpen: true,
          type: "error",
          message: result.message,
        });
      }
      setHoeiKubunData(result);
    }
    setTimeout(() => {
      getHoeiKubunData();
    }, 0);
  }, []);

  const handleChange = (event: SelectChangeEvent) => {
    const hoeiKubun = hoeiKubunData.data.find(hoeiKubun => event.target.value === String(hoeiKubun.code))
    if (!hoeiKubun) return;
    setHoeiKubun(hoeiKubun);
  };

  return (
    <Box sx={{ minWidth: 120 }} mt={1}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label" >{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={String(hoeiKubun.code)}
          label={label}
          onChange={handleChange}
          disabled={disabled}
        >
          {hoeiKubunData.data.map((kikiShubetsu) => {
            return (
              <MenuItem key={kikiShubetsu.code} value={String(kikiShubetsu.code)} sx={{ height: "36px" }}>
                {kikiShubetsu.code_name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}

export default HoeiKubunSelect;