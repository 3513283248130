import { useState, useContext, useEffect } from "react";

import {
  DataGrid,
  GridColDef,
  jaJP,
  GridSelectionModel,
} from "@mui/x-data-grid";

import { renderCellExpand } from "../Common/GridCellExpand";
import BaitaiEditDialog from './BaitaiEditDialog';
import ToggleVisibilityDialog from '../Common/ToggleVisibilityDialog'
import { ReloadContext } from "../../context/ReloadContext";
import { postSearchBaitai } from "../../api/baitai";

import { BaitaiData, BaitaiSearchParam, BaitaiDataSet } from "../../types/WebData";

type Props = {
  searchParam: BaitaiSearchParam | null;
  setSelectedRow: React.Dispatch<React.SetStateAction<BaitaiDataSet | null>>;
  setload: React.Dispatch<React.SetStateAction<boolean>>
};

/**
 * 媒体一覧コンポーネント
 *
 * @param {Props} { searchParam, setSelectedRow }
 * @return {*} 
 */
const BaitaiGrid = ({ searchParam, setSelectedRow, setload }: Props) => {
  const reloadContext = useContext(ReloadContext);
  const [baitaiData, setBaitaiData] = useState<BaitaiData>({ data: [] });

  // 初期表示処理
  useEffect(() => {
    // 検索ボタン押下時
    const getSearchData = async (param: BaitaiSearchParam) => {
      const result = await postSearchBaitai({ ...param });
      setload(false);
      if (result.isError) {
        return reloadContext?.setSnackbarInfo({
          isOpen: true,
          type: "error",
          message: result.message,
        });
      } else {
        setBaitaiData(result);
      }
    }
    if (!searchParam) {
      getSearchData({ jusho: "", is_visible: false })
    } else {
      getSearchData(searchParam);
    }
  }, [searchParam, reloadContext?.reload]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      hide: true,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "baitai_mei",
      headerName: "媒体名",
      width: 250,
      sortable: false,
      disableColumnMenu: true,
      renderCell: renderCellExpand,
    },
    {
      field: "area_mei",
      headerName: "エリア",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      renderCell: renderCellExpand,
    },
    {
      field: "location_mei",
      headerName: "設置場所",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      renderCell: renderCellExpand,
    },
    {
      field: "jusho",
      headerName: "住所",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      renderCell: renderCellExpand,
    },
    {
      field: "hoei_kaishi_jikan",
      headerName: "放映開始時間",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "hoei_shuryo_jikan",
      headerName: "放映終了時間",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "update_dt",
      headerName: "更新日",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'editButton',
      headerName: "",
      width: 20,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      disableExport: true,
      renderCell: (params) => (
        <BaitaiEditDialog baitaiId={params.row.id} />
      )
    },
    {
      field: "toggleVisibilityDialog",
      headerName: "",
      width: 20,
      align: "center",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      disableExport: true,
      renderCell: (params) => (
        <ToggleVisibilityDialog deleteId={params.row.id} isVisible={!params.row.del_flg} tableName="baitai" />
      ),
    },
  ]

  return (
    <div style={{ height: 650, width: "100%" }}>
      <DataGrid
        rows={baitaiData.data}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        sortModel={[{ field: "id", sort: "asc" }]}
        localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
        onSelectionModelChange={(selectionModel: GridSelectionModel) => {
          const baitai = baitaiData.data.find((baitai) => baitai.id === selectionModel[0]);
          if (!baitai) return;
          setSelectedRow(baitai);
        }}
      />
    </div>
  );
};

export default BaitaiGrid;