// ログイン有効期限切れメッセージ
export const EXPIRED_AUTH_INFO = "ログインの有効期限が切れました。再度ログインしてください。";

// システムエラー
export const SYSTEM_ERROR = "システムエラーが発生しました。システム管理者にご連絡ください。";

// 競合エラー
export const CONFLICT_ERROR = "データが更新されています。お手数ですが、もう一度はじめからやり直してください。";

// 競合エラー
export const BANGUMIHYO_CONFLICT_ERROR = "対象の日程の番組表は既に作成されています。お手数ですが、番組表管理画面からやり直して下さい";

// API登録後メッセージ
export const TOROKU_SEIKO = "登録しました。";

// API更新後メッセージ
export const KOSHIN_SEIKO = "更新しました。";

// パスワード忘れメール送信後メッセージ
export const SEND_PASSWORD_REMINDER_MAIL = "入力された担当者にメールを送信しました。メールに記載したURLからパスワード再設定をお願いいたします。";

// 申込期限を過ぎた際の申込内容変更不可メッセージ
export const EXPIRED_MOSHIKOMI_KIGEN_NISSU = "申込期限日数を過ぎているため申込内容の変更はできません。";

export const WAIT_FOR_UPLOADING = "現在アップロード中です。\nアップロードが終わるまでしばらくお待ちください。";

// 担当者情報変更後
export const USER_EDIT = "再ログインするまでは変更前の担当者情報が適用されます。";

// 削除時の存在チェックエラー（会社設定）
export const EXISTENCE_KAISHA = "該当の会社に表示中の担当者がいる為、非表示にできません。";

// 削除時の存在チェックエラー（エリア設定）
export const EXISTENCE_AREA = "該当のエリアに表示中の設置場所がある為、非表示にできません。";

// 削除時の存在チェックエラー（設置場所設定）
export const EXISTENCE_LOCATION = "該当の設置場所に表示中の媒体がある為、非表示にできません。";

// 削除時の存在チェックエラー（設置場所設定）
export const EXISTENCE_KAIZODO = "表示中の媒体に該当の解像度がある為、非表示にできません。";

// 削除時の存在チェックエラー（媒体種別設定）
export const EXISTENCE_BAITAISHUBETU = "表示中の媒体に該当の媒体種別がある為、非表示にできません。";

// 削除時の存在チェックエラー（機器型番設定）
export const EXISTENCE_BAITAI_KIKI_ASSOCIATE = "表示中の媒体に該当の機器が登録されている為、非表示にできません。";