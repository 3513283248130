import { useContext, useState, useEffect, useRef } from "react";
import axios from "axios";

import { useNavigate, useLocation } from 'react-router-dom';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import {
  Box,
  Toolbar,
  Typography,
  CircularProgress
} from "@mui/material";

import { ReloadContext } from "../context/ReloadContext";
import { AuthInfoContext } from "../context/AuthContext";

import { Copyright } from "../components/Common/Copyright";
import FileUpload from '../components/Common/FileUpload';
import { ContentUploadProps, ContentUploadPostData } from "../types/WebData";
import { putContentUpload, getContentDownload } from "../api/content";
import { UPLOAD_STATUS, ALLOW_MIME_TYPES, ALLOW_MOVE_EXTENTIONS, HTTP_STATUS_CODE, KENGEN } from "../const/index";
import { SYSTEM_ERROR } from "../const/message";
import LoadingButton from '@mui/lab/LoadingButton';

const mdTheme = createTheme();
const noImageFilePath = `${process.env.PUBLIC_URL}/NoImage.png`;
/**
 * コンテンツアップロード画面コンポーネント
 *
 * @return {*} 
 */
export const ContentUpload = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as ContentUploadProps;
  const reloadContext = useContext(ReloadContext);
  const [authInfo] = useContext(AuthInfoContext);

  const [bangumiMei, setBangumiMei] = useState<string>("");
  const [contentFileMei, setContentFileMei] = useState<string>("");
  const [henshuContentFlg, setHenshuContentFlg] = useState<boolean>(false);
  const [contentByosu, setContentByosu] = useState<number>(15);
  const [contentId, setContentId] = useState<number>(0);
  const [uploadFile, setUploadFile] = useState<File | null>(null);
  const [uploadFileUrl, setUploadFileUrl] = useState<string>("");
  const [thumbnailBlob, setThumbnailBlob] = useState<Blob | null>(null);
  const [thumbnailUrl, setThumbnailUrl] = useState<string>("");
  const [isShowThumbnail, setIsShowThumbnail] = useState<boolean>(false);
  const [canCreateThumbnail, setCanCreateThumbnail] = useState<boolean>(false);
  const [pauseImage, setPauseImage] = useState<EventTarget & HTMLVideoElement | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [omakasePackFlg, setOmakasePackFlg] = useState<boolean>(false);
  const [editFlg, setEditFlg] = useState<boolean>(false);
  // 処理中フラグ（ボタン連打対策）
  const processing = useRef(false);
  const [load, setLoad] = useState<boolean>(false);

  useEffect(() => {
    setBangumiMei(state.selectedRow.bangumi_mei);
    setThumbnailUrl(noImageFilePath);
    setHenshuContentFlg(state.henshu_content_flg);
    const contentFileMei = (state.henshu_content_flg) ? state.selectedRow.henshu_content_file_mei : state.selectedRow.content_file_mei;
    setContentFileMei(contentFileMei);
    setContentByosu(state.selectedRow.content_byosu);
    setContentId((state.henshu_content_flg) ? state.selectedRow.henshu_content_id : state.selectedRow.content_id);
    const isShowThumbnail = (authInfo.kaisha_id === state.selectedRow.kanri_kaisha_id) || authInfo.kengen === KENGEN.ADMIN || authInfo.kengen === KENGEN.OMAKASE;
    setIsShowThumbnail(isShowThumbnail);
    setOmakasePackFlg(state.selectedRow.omakase_pack_flg);

    // 配信担当者またはCMN管理者の場合、サムネイル作成用にコンテンツダウンロード
    if (isShowThumbnail) {
      // 登録済みのコンテンツをダウンロード
      const getUploadContentFile = async () => {
        const contentFilePath = (state.henshu_content_flg) ? state.selectedRow.henshu_content_file_path : state.selectedRow.content_file_path;
        if (contentFilePath) {
          const contentId = (state.henshu_content_flg) ? state.selectedRow.henshu_content_id : state.selectedRow.content_id;
          // キャンセルトークン生成
          const CancelToken = axios.CancelToken;
          const source = CancelToken.source();
          // ダウンロード中state更新
          reloadContext?.setDownloadingInfo({ isDownloading: true, source });
          const result = await getContentDownload(source, contentId, contentFilePath);
          const blob = new Blob([result.data], { type: 'application/octet-binary' });
          const downloadUrl = URL.createObjectURL(blob);
          setUploadFileUrl(downloadUrl);
          // コンテンツが動画だったらサムネイル作成ボタンを活性化
          if (isAllowedMoveExtensions(contentFileMei.split('.').pop())) {
            setCanCreateThumbnail(true);
          }
          // ダウンロード中フラグOFF
          reloadContext?.setDownloadingInfo({ isDownloading: false });
          if (videoRef.current) setPauseImage(videoRef.current);
        };
      }
      getUploadContentFile();

      const getUploadThumbnailFile = async () => {
        const thumbnailFilePath = (state.henshu_content_flg) ? state.selectedRow.henshu_thumbnail_file_path : state.selectedRow.thumbnail_file_path;
        if (thumbnailFilePath) {
          const result = await getContentDownload(null, contentId, thumbnailFilePath);
          const blob = new Blob([result.data], { type: 'application/octet-binary' });
          const downloadUrl = URL.createObjectURL(blob);
          setThumbnailUrl(downloadUrl);
        }
      }
      getUploadThumbnailFile();
    }
  }, [])

  useEffect(() => {
    // ファイル選択時の処理。画像を選択した場合はサムネイル作成までやる。
    const getUploadFileUrl = async () => {
      if (!uploadFile) return;

      const buffer = await uploadFile.arrayBuffer();
      const blob = new Blob([buffer]);
      // アップロードファイルが画像の場合、そのままサムネイルにする
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d');
      if (!ctx) return "";

      const img = new Image();

      // img.srcの読込みが完了した際の処理
      img.onload = () => {
        const canvasWidth = ctx.canvas.width;
        const canvasHeight = ctx.canvas.height;
        const imgWidth = img.width;
        const imgHeight = img.height;

        let canvasAspect = canvasWidth / canvasHeight, // canvasのアスペクト比
          imgAspect = imgWidth / imgHeight, // 画像のアスペクト比
          width, height;

        if (imgAspect >= canvasAspect) {// 画像が横長
          width = canvasWidth;
          height = canvasWidth / imgAspect;
        } else {// 画像が縦長
          height = canvasHeight;
          width = canvasHeight * imgAspect;
        }

        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(img, 0, 0, imgWidth, imgHeight,
          0, 0, width, height);

        // サムネイル表示
        setThumbnailUrl(canvas.toDataURL('image/jpeg'));
        canvas.toBlob((blob) => {
          setThumbnailBlob(blob)
        }, 'image/jpeg');
      }

      if (uploadFile.type === 'image/jpeg' || uploadFile.type === 'image/png') {
        img.src = URL.createObjectURL(blob);
        setUploadFileUrl("")
        setCanCreateThumbnail(false);
      } else {
        img.src = noImageFilePath;
        if (videoRef.current) setPauseImage(videoRef.current);
        setUploadFileUrl(URL.createObjectURL(blob))
        setCanCreateThumbnail(true);
      }
    }
    getUploadFileUrl();
    if (uploadFile) {
      setContentFileMei(uploadFile.name);
    }
  }, [uploadFile])


  const handlePageBack = () => {
    navigate("/hoeimoshikomi", { state }); // 画面遷移
  }

  const handlePause = (event: React.ChangeEvent<HTMLVideoElement>) => {
    // 動画を停止するたびにサムネイルを作成する
    setPauseImage(event.target);
  }

  const handleCreateThumbnail = () => {
    if (pauseImage) {
      setThumbnailUrl(toDataURL(pauseImage));
    }
  }

  /**
   * サムネイル作成処理
   *
   * @param {(EventTarget & HTMLVideoElement)} target
   * @return {*} 
   */
  const toDataURL = (target: EventTarget & HTMLVideoElement) => {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d');
    if (!ctx) return "";

    const canvasWidth = ctx.canvas.width;
    const canvasHeight = ctx.canvas.height;
    const targetWidth = target.videoWidth;
    const targetHeight = target.videoHeight;

    let canvasAspect = canvasWidth / canvasHeight, // canvasのアスペクト比
      imgAspect = targetWidth / targetHeight, // 画像のアスペクト比
      width, height;

    if (imgAspect >= canvasAspect) {// 画像が横長
      width = canvasWidth;
      height = canvasWidth / imgAspect;
    } else {// 画像が縦長
      height = canvasHeight;
      width = canvasHeight * imgAspect;
    };

    canvas.width = width;
    canvas.height = height;

    ctx.drawImage(target, 0, 0, targetWidth, targetHeight,
      0, 0, width, height);

    canvas.toBlob((blob) => {
      setThumbnailBlob(blob)
    }, 'image/jpeg');
    return canvas.toDataURL('image/jpeg');
  }

  const isAllowedMimeType = (mimeType: string) => {
    // 許可配列から一致する値を検索
    return (ALLOW_MIME_TYPES.includes(mimeType.toLowerCase()));
  }

  const isAllowedMoveExtensions = (extension: string = "") => {
    // 許可配列から一致する値を検索
    return (ALLOW_MOVE_EXTENTIONS.includes(extension.toLowerCase()));
  }

  // ファイルをBase64に変換する関数
  const fileToBase64 = async (file: File | Blob | null): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      if (!file) return;
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result as string;
        resolve(base64String.split(',')[1]); // Base64データ部分を抽出
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  /**
   * 保存ボタン押下処理
   */
  const handleSave = async () => {
    // コンテンツファイル バリデーションチェック
    if (uploadFile) {
      if (!isAllowedMimeType(uploadFile.type)) {
        return reloadContext?.setSnackbarInfo({
          isOpen: true,
          type: "error",
          message: "アップロードできないファイルが選択されました。ファイルを確認してください。",
        });
      };
    }

    // 処理中(true)なら非同期処理せずに抜ける
    if (processing.current) return;
    // 処理中フラグを上げる
    processing.current = true;
    // 疑似非同期処理
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 1000);
    setLoad(true);

    let thumbnailFile: File | null = null;
    if (thumbnailBlob) {
      const blob = new Blob([thumbnailBlob], { type: 'image/jpeg' });
      // サムネイル画像からファイルを生成
      thumbnailFile = new File([blob], "thumbnail.jpeg", { type: "image/jpeg" });
    }

    // バイナリファイルをBase64に変換
    const contentFileBase64 = await fileToBase64(uploadFile);
    const thumbnailFileBase64 = await fileToBase64(thumbnailFile);

    const contentUploadPostData: ContentUploadPostData = {
      id: contentId,
      hoeimoshikomi_id: state.selectedRow.id,
      baitai_shosai_id: state.selectedRow.baitai_shosai_id,
      content_file_mei: uploadFile ? uploadFile.name : "",
      thumbnail_file_mei: thumbnailFile ? thumbnailFile.name : "",
      content_file_mime_type: uploadFile ? uploadFile.type : "",
      henshu_content_flg: henshuContentFlg,
      content_file_base64: contentFileBase64,
      thumbnail_file_base64: thumbnailFileBase64,
      omakase_pack_flg: omakasePackFlg
    }

    // 署名付きURLを取得
    const result = await putContentUpload(contentUploadPostData);
    reloadContext?.setReload(reloadContext?.reload + 1);
    if (result.isError) {
      setLoad(false);
      reloadContext?.setSnackbarInfo({
        isOpen: true,
        type: "error",
        message: result.message,
      });
      if (result.status === HTTP_STATUS_CODE.CONFLICT_ERROR) {
        navigate("/hoeimoshikomi", { state });
      }
      return;
    }

    // putContentUploadの実行でコンテンツテーブルへのInsertはコミット済みなので、newContentIdをセットする
    if (result.newContentId) {
      setContentId(result.newContentId);
    }

    if (result.newContentId) {
      setUploadFile(null);
      setThumbnailBlob(null);
    }

    setLoad(false);
    return reloadContext?.setSnackbarInfo({
      isOpen: true,
      type: (result.haishinBangumihyoCount > 0) && ((!omakasePackFlg && state.selectedRow.kanri_kaisha_id === authInfo.kaisha_id) || (authInfo.kengen === KENGEN.OMAKASE)) ?
        `warning` : `success`,
      message: (result.haishinBangumihyoCount > 0) && ((!omakasePackFlg && state.selectedRow.kanri_kaisha_id === authInfo.kaisha_id) || (authInfo.kengen === KENGEN.OMAKASE)) ?
        henshuContentFlg ?
          `アップロードが完了しました。配信中・配信済みの申込になります。該当の番組表をご確認ください。` :
          `アップロードが完了しました。配信中・配信済みの申込になります。編集後ファイルをアップロードしてください。` : `アップロードが完了しました。`,
    });
  }

  return (
    <ThemeProvider theme={mdTheme}>
      {reloadContext?.uploadingInfo.isUploading || reloadContext?.downloadingInfo.isDownloading ?
        <Box sx={{ width: '100%', height: '100%', zIndex: 9999, backgroundColor: "rgba(0, 0, 0, 0.5)", display: "flex", justifyContent: "center", alignItems: "center", position: "absolute" }}>
          <CircularProgress sx={{ width: '100%', height: '100%', }} />
        </Box>
        : ""}
      <Box sx={{ display: 'flex' }}>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />

          <Container maxWidth={false} sx={{ mt: 2, mb: 4 }}>

            {/* 一覧 */}
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 'auto',
                minHeight: '80vh',
                maxWidth: '80vw',
              }}
            >
              <Grid container spacing={1}>
                {/* タイトル */}
                <Grid item xs={6} md={6} lg={8} pl={0}>
                  <Typography component="h1" variant="h6">
                    コンテンツ登録
                  </Typography>
                  <Typography component="h2" variant="subtitle1">
                    放映番組：{bangumiMei}
                  </Typography>
                  <Typography component="h3" variant="h6" mt={2} sx={{
                    maxWidth: '250px',
                    background: 'linear-gradient(transparent 60%, #a8eaff 60%)'
                  }}>
                    コンテンツアップロード
                  </Typography>
                  <FileUpload contentFileMei={contentFileMei} setUploadFile={setUploadFile} contentByosu={contentByosu} setEditFlg={setEditFlg} />
                </Grid>
                {/* ボタンエリア */}
                <Grid item xs={6} md={6} lg={4}>
                  <Box
                    display="flex"
                    justifyContent="end">
                    <Button
                      type="submit"
                      variant="outlined"
                      onClick={handlePageBack}
                      sx={{ mt: 2, mb: 2 }}
                    >
                      戻る
                    </Button>
                    <LoadingButton
                      loading={load}
                      type="submit"
                      variant="contained"
                      onClick={handleSave}
                      disabled={(!uploadFile && !thumbnailBlob)}
                      sx={{ ml: 1, mt: 2, mb: 2 }}
                    >
                      保存
                    </LoadingButton>
                  </Box>
                </Grid>
                {
                  isShowThumbnail ?
                    <>
                      <Grid item xs={6} sx={{ minHeight: '50vh', position: 'relative', maxWidth: '600px !important' }}>
                        <Box>
                          <Typography component="h1" variant="h6" sx={{
                            maxWidth: '250px',
                            background: 'linear-gradient(transparent 60%, #a8eaff 60%)'
                          }}>
                            サムネイル登録
                          </Typography>
                          <Typography component="h1" variant="subtitle2" ml={1}>
                            サムネイルにしたいシーンでサムネイル作成ボタンを押して、サムネイルを作成できます。<br />
                            ※サムネイルを作成しない場合、デフォルトのNo Image画像を表示します。<br />
                            ※コンテンツが静止画の場合、そのままサムネイルになります。
                          </Typography>
                        </Box>
                        <Box sx={{ height: '100%', display: 'flex', flexFlow: "column", justifyContent: 'start', alignItems: 'end' }}>
                          <video id="video" width={'550px'} height={'360px'} src={uploadFileUrl} controls ref={videoRef} onPause={handlePause}></video>
                          <Button
                            type="submit"
                            variant="contained"
                            onClick={handleCreateThumbnail}
                            sx={{ ml: 1, mt: 2, mb: 2 }}
                            disabled={!canCreateThumbnail}
                          >
                            サムネイル作成
                          </Button>
                        </Box>
                      </Grid>
                      <Grid item xs={6} sx={{ minHeight: '50vh', maxWidth: '600px !important', display: 'flex', justifyContent: 'center', alignItems: 'center', flexFlow: 'column' }}>
                        <Typography component="h1" variant="subtitle1" ml={1}>
                          サムネイル
                        </Typography>
                        <svg id="svg" viewBox="0 0 1280 720" xmlns="http://www.w3.org/2000/svg">
                          <image id="image" width={'100%'} height={'100%'} href={thumbnailUrl} />
                          <g id="g" />
                        </svg>
                      </Grid>
                    </>
                    : ""
                }
              </Grid>
            </Paper>
            <Copyright />
          </Container>
        </Box>
      </Box>
    </ThemeProvider >
  );
}
