import { useContext, useState, useRef, useEffect } from "react";
import { useLocation } from 'react-router-dom';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  Typography,
  TextField,
  Container,
  Grid,
  Paper,
  CircularProgress,
  Button
} from "@mui/material";

import moment from "moment";

import FormControlLabel from "@mui/material/FormControlLabel";
import { Checkbox } from "@mui/material";
import { AuthInfoContext } from "../context/AuthContext";
import { ReloadContext } from "../context/ReloadContext";
import { Copyright } from "../components/Common/Copyright";
import BaitaiSelect from "../components/Common/BaitaiSelect";
import HoeimoshikomiGrid from "../components/Hoeimoshikomi/HoeimoshikomiGrid";
import HoeimoshikomiEditDialog from "../components/Hoeimoshikomi/HoeimoshikomiEditDialog";
import DateRangePicker from '../components/Common/DatePicker/DateRangePicker';
import { KENGEN } from "../const/index";
import { HoeimoshikomiSearchParam, BaitaiDataSet } from "../types/WebData";
import LoadingButton from '@mui/lab/LoadingButton';

const mdTheme = createTheme();

/**
 * 放映申込管理画面コンポーネント
 *
 * @return {*} 
 */
export const Hoeimoshikomi = () => {
  const location = useLocation();
  const state = location.state;
  const hoeimoshikomiId = state?.hoeimoshikomiId || 0;
  const [authInfo] = useContext(AuthInfoContext);
  const reloadContext = useContext(ReloadContext);
  const [kikanStartDate, setKikanStartDate] = useState<Date | null>(null);
  const [kikanEndDate, setKikanEndDate] = useState<Date | null>(null);
  // const [startDate, setStartDate] = useState<Date | null>(null);
  // const [endDate, setEndDate] = useState<Date | null>(null);
  const [baitai, setBaitai] = useState<BaitaiDataSet | null>(null);
  const [kokyakuMei, setKokyakuMei] = useState<string>("");
  const [kaishaMei, setKaishaMei] = useState<string>("");
  const [bangumiMei, setBangumiMei] = useState<string>("");
  const [onlyMyDataFlg, setOnlyMyDataFlg] = useState<boolean>(false);
  const [noContentFlg, setNoContentFlg] = useState<boolean>(false);
  // 処理中フラグ（ボタン連打対策）
  const processing = useRef(false);
  const [load, setLoad] = useState<boolean>(false);

  const [searchParam, setSearchParam] = useState<HoeimoshikomiSearchParam | null>(null);

  // エラーメッセージ
  const [errorMessageHaishinKikan, setErrorMessageHaishinKikan] = useState<string>("");
  // const [errorMessageHaishinKaishibi, setErrorMessageHaishinKaishibi] = useState<string>("");

  useEffect(() => {
    // 検索条件復元
    if (state?.searchParam) {
      if (state?.searchParam.haishin_kikan_start) setKikanStartDate(new Date(moment(state?.searchParam.haishin_kikan_start).format('l')) || null);
      if (state?.searchParam.haishin_kikan_end) setKikanEndDate(new Date(moment(state?.searchParam.haishin_kikan_end).format('l')) || null);
      // if (state?.searchParam.haishin_kaishibi_start) setStartDate(new Date(moment(state?.searchParam.haishin_kaishibi_start).format('l')) || null);
      // if (state?.searchParam.haishin_kaishibi_end) setEndDate(new Date(moment(state?.searchParam.haishin_kaishibi_end).format('l')) || null);
      if (state?.searchParam.baitai_id) setBaitai({ id: state?.searchParam.baitai_id });
      if (state?.searchParam.kokyaku_mei) setKokyakuMei(state?.searchParam.kokyaku_mei)
      if (state?.searchParam.kaisha_mei) setKaishaMei(state?.searchParam.kaisha_mei)
      if (state?.searchParam.bangumi_mei) setBangumiMei(state?.searchParam.bangumi_mei)
      if (state?.searchParam.only_my_data_flg) setOnlyMyDataFlg(state?.searchParam.only_my_data_flg)
      if (state?.searchParam.no_content_flg) setNoContentFlg(state?.searchParam.no_content_flg)
      setSearchParam(state?.searchParam);
    } else {
      initSearchParam();
    }
  }, [])

  // 条件クリアボタン押下時処理
  const handleClear = async () => {
    initSearchParam()
  };
  // 検索条件初期化処理
  const initSearchParam = () => {
    setKikanStartDate(null)
    setKikanEndDate(null)
    // setStartDate(null)
    // setEndDate(null)
    setBaitai(null)
    setBangumiMei("")
    setKokyakuMei("")
    setKaishaMei("")
    setOnlyMyDataFlg(false)
    setNoContentFlg(false)
    const initialSearchParam = {
      loginuser_id: authInfo.id,
      kengen: authInfo.kengen,
      kaisha_id: authInfo.kaisha_id,
      haishin_kikan_start: "",
      haishin_kikan_end: "",
      //        haishin_kaishibi_start: "",
      //        haishin_kaishibi_end: "",
      baitai_id: 0,
      kokyaku_mei: "",
      kaisha_mei: "",
      bangumi_mei: "",
      only_my_data_flg: false,
      no_content_flg: false
    } as HoeimoshikomiSearchParam;
    setSearchParam(initialSearchParam);
  }

  // 検索実行
  const handleSearch = async () => {
    setErrorMessageHaishinKikan("");
    // setErrorMessageHaishinKaishibi("");

    // バリデーションチェック
    if (kikanStartDate && !moment(kikanStartDate).isValid()) {
      return setErrorMessageHaishinKikan("不正な入力形式です。");
    }
    if (kikanEndDate && !moment(kikanEndDate).isValid()) {
      return setErrorMessageHaishinKikan("不正な入力形式です。");
    }
    // if (startDate && !moment(startDate).isValid()) {
    //   return setErrorMessageHaishinKaishibi("不正な入力形式です。");
    // }
    // if (endDate && !moment(endDate).isValid()) {
    //   return setErrorMessageHaishinKaishibi("不正な入力形式です。");
    // }

    // 処理中(true)なら非同期処理せずに抜ける
    if (processing.current) return;
    // 処理中フラグを上げる
    processing.current = true;
    // 疑似非同期処理
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 1000);
    setLoad(true);
    const searchParam: HoeimoshikomiSearchParam = {
      loginuser_id: authInfo.id,
      kengen: authInfo.kengen,
      kaisha_id: authInfo.kaisha_id,
      haishin_kikan_start: kikanStartDate !== null ? moment(kikanStartDate).format("YYYY-MM-DD") : "",
      haishin_kikan_end: kikanEndDate !== null ? moment(kikanEndDate).format("YYYY-MM-DD") : "",
      // haishin_kaishibi_start: startDate !== null ? moment(startDate).format("YYYY-MM-DD") : "",
      // haishin_kaishibi_end: endDate !== null ? moment(endDate).format("YYYY-MM-DD") : "",
      baitai_id: baitai ? baitai.id : 0,
      kokyaku_mei: kokyakuMei,
      kaisha_mei: kaishaMei,
      bangumi_mei: bangumiMei,
      only_my_data_flg: onlyMyDataFlg,
      no_content_flg: noContentFlg
    };
    await setSearchParam(searchParam);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      {reloadContext?.downloadingInfo.isDownloading ?
        <Box sx={{ width: '100%', height: '100%', zIndex: 9999, backgroundColor: "rgba(0, 0, 0, 0.5)", position: "absolute", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <CircularProgress sx={{ width: '100%', height: '100%', }} />
        </Box>
        : ""}
      <Box sx={{ display: 'flex' }}>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />

          <Container maxWidth={false} sx={{ mt: 0, mb: 4 }}>
            <HoeimoshikomiEditDialog hoeimoshikomiId={0} isOpen={false}/>
            {hoeimoshikomiId !== 0 && (
              <HoeimoshikomiEditDialog hoeimoshikomiId={hoeimoshikomiId} isOpen={true} />
            )}
            <Grid container>
              {/* 検索 */}
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  width: "-webkit-fill-available",
                }}
              >
                <Box>
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <Typography component="h3" variant="subtitle1">
                        放映期間
                      </Typography>
                      <DateRangePicker
                        startDate={kikanStartDate}
                        setStartDate={setKikanStartDate}
                        endDate={kikanEndDate}
                        setEndDate={setKikanEndDate}
                      />
                      {errorMessageHaishinKikan && (
                        <small className={"error-message"}>{errorMessageHaishinKikan}</small>
                      )}
                      {/* <Typography component="h3" variant="subtitle1">
                        放映開始日
                      </Typography>
                      <DateRangePicker
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                      />
                      {errorMessageHaishinKaishibi && (
                        <small className={"error-message"}>{errorMessageHaishinKaishibi}</small>
                      )} */}

                    </Grid>
                    <Grid item xs={8} textAlign="right">
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: "right",
                          alignItems: "flex-start",
                          width: '100%',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox checked={onlyMyDataFlg} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setOnlyMyDataFlg(event.target.checked);
                              }} />
                            }
                            label="自分が登録したもののみを表示"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox checked={noContentFlg} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setNoContentFlg(event.target.checked);
                              }} />
                            }
                            label="編集後ファイルが未登録のもののみを表示"
                          />
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <LoadingButton
                            loading={load}
                            type="button"
                            variant="contained"
                            sx={{ mt: 1, mr: 1, px: 5 }}
                            onClick={() => handleSearch()}
                          >
                            検 索
                          </LoadingButton>
                          <Button
                            sx={{ mt: 1, mr: 1, px: 5 }}
                            color="primary" variant="outlined" onClick={handleClear}>
                            条件クリア
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <BaitaiSelect
                        baitai={baitai}
                        setBaitai={setBaitai}
                        label="媒体名"
                        is_visible={true}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        id="bangumi_mei"
                        label="番組名"
                        value={bangumiMei}
                        onChange={e => setBangumiMei(e.target.value)}
                        variant="outlined"
                        fullWidth
                        sx={{ mt: 1 }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        id="kokyaku_mei"
                        label="広告主名"
                        value={kokyakuMei}
                        onChange={e => setKokyakuMei(e.target.value)}
                        variant="outlined"
                        fullWidth
                        sx={{ mt: 1 }}
                      />
                    </Grid>
                    {(authInfo?.kengen === KENGEN.ADMIN) ?
                      <Grid item xs={3}>
                        <TextField
                          id="kaisha_mei"
                          label="広告代理店名"
                          value={kaishaMei}
                          onChange={e => setKaishaMei(e.target.value)}
                          variant="outlined"
                          fullWidth
                          sx={{ mt: 1 }}
                        />
                      </Grid>
                      : ""}
                  </Grid>
                </Box>
              </Paper>
              {/* 一覧 */}
              <Grid container mt={1}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    width: "-webkit-fill-available",
                    height: '50vh',
                  }}
                >
                  <HoeimoshikomiGrid searchParam={searchParam} setload={setLoad} />
                </Paper>
              </Grid>
            </Grid>
            <Copyright />
          </Container>
        </Box>
      </Box>
    </ThemeProvider >
  );
}