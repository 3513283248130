import API from "./api";
import * as MESSAGE from "../const/message";

/**
 * menuテーブルAPI get
 *
 * @param {}
 */
export const getMenu = async () => {
  const result = await API.get(`/cms-api/menu/`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR, data: [] };
    });

  return result;
};

/**
 * menuテーブルAPI get
 * @param {number} kengen_id
 * @returns 
 */
export const getMenuList = async (kengen_id: number) => {
  const result = await API.get(`/cms-api/menu/list`, { params: { kengen_id } })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR, data: [] };
    });

  return result;
}

/**
 * menuテーブルAPI get
 * @param {number} kengen_id
 * @returns 
 */
export const getMenuKengenList = async (kengen_id: number) => {
  const result = await API.get(`/cms-api/menu/signin`, { params: { kengen_id } })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR, data: [] };
    });

  return result;
}
