import {
  KikiKatabanDataSet, KikiKatabanSearchParam
} from "../types/WebData";
import API from "./api";
import * as MESSAGE from "../const/message";
import { HTTP_STATUS_CODE } from "../const/index";

/**
 * kikiKatabanテーブルAPI get
 *
 * @param {number} kikiKatabanId
 */
export const getKikiKatabanId = async (kikiKatabanId: number) => {
  const result = await API.get(`/cms-api/kikiKataban/id`, { params: { id: kikiKatabanId } })
    .then((res) => {
      return res.data.data[0];
    })
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR, data: [] };
    });

  return result;
};

/**
 * kikiKatabanテーブルAPI
 * 機器型番検索
 *
 * @param {KikiKatabanSearchParam} searchParam
 * @return {*} 
 */
export const postSearchKikiKataban = async (searchParam: KikiKatabanSearchParam | null) => {
  const result = await API.post(`/cms-api/kikiKataban/search`, searchParam)
    .then((res) => {
      return { data: res.data.data, isError: false, message: "" };
    })
    .catch((error) => {
      return { data: [], isError: true, message: MESSAGE.SYSTEM_ERROR };
    });
  return result;
};

/**
 * kikiKatabanテーブルAPI 更新
 *
 * @param {KikiKatabanDataSet} postData
 */
export const updateKikiKataban = async (postData: KikiKatabanDataSet) => {
  if (postData.id === 0) {
    // insert
    const result = await API.post("/cms-api/kikiKataban", postData)
      .then((res) => {
        return { isError: false, message: MESSAGE.TOROKU_SEIKO };
      })
      .catch((error) => {
        return { isError: true, message: MESSAGE.SYSTEM_ERROR };
      });
    return result;
  } else {
    // update
    const result = await API.put("/cms-api/kikiKataban/id", postData)
      .then((res) => {
        return { isError: false, message: MESSAGE.KOSHIN_SEIKO };
      })
      .catch((error) => {
        const status = error.response.status;
        const message = status === HTTP_STATUS_CODE.CONFLICT_ERROR ? MESSAGE.CONFLICT_ERROR : MESSAGE.SYSTEM_ERROR;
        return { isError: true, message: message, data: [] };
      });
    return result;
  }
};

/**
 * kikiKatabanテーブルAPI del_flg更新
 *
 * @param {number} id
 * @param {boolean} del_flg
 */
export const deleteKikiKataban = async (id: number, del_flg: boolean) => {
  const result = await API.put("/cms-api/kikiKataban/delete", { id: id, del_flg: del_flg })
    .then((res) => {
      return { isError: false, message: "" };
    })
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR };
    });
  return result;
};