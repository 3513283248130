import { useState, useContext, useEffect } from "react";

import {
  Typography,
  DialogContent,
  Grid
} from "@mui/material";

import moment from "moment";
import { ReloadContext } from "../../context/ReloadContext";
import { getHoeimoshikomiDetail } from "../../api/hoeimoshikomi";
import { HoeimoshikomiDialogData } from "../../types/WebData";

interface Props {
  hoeimoshikomiId: number;
}

/**
 * 放映申込詳細ダイアログコンポーネント
 *
 * @param {Props} { hoeimoshikomiId }
 * @return {*} 
 */
const HoeimoshikomiReadOnlyDialog = ({ hoeimoshikomiId }: Props) => {
  const [hoeimoshikomiData, setHoeimoshikomiData] = useState<HoeimoshikomiDialogData | null>(null);
  const reloadContext = useContext(ReloadContext);

  useEffect(() => {
    const getSearchData = async () => {
      const result = await getHoeimoshikomiDetail(hoeimoshikomiId);
      if (result.isError) {
        return reloadContext?.setSnackbarInfo({
          isOpen: true,
          type: "error",
          message: result.message,
        });
      }

      result.data[0].haishin_kaishibi = (result.data[0].haishin_kaishibi) ? moment(result.data[0].haishin_kaishibi).format("YYYY/MM/DD") : '';
      result.data[0].haishin_shuryobi = (result.data[0].haishin_shuryobi) ? moment(result.data[0].haishin_shuryobi).format("YYYY/MM/DD") : '';
      result.data[0].jikantai_shitei_flg = (result.data[0].jikantai_shitei_flg) ? ' ～ ' : '';

      setHoeimoshikomiData(result.data[0]);
    };
    getSearchData();
  }, [hoeimoshikomiId]);

  return (
    <>
      <Typography component="h3" variant="h6" mt={1} ml={2} mb={2}>
        放映申込確認
      </Typography>
      <DialogContent sx={{ paddingTop: 0 }}>
        <Grid container spacing={1}>
          <Grid item xs={3} sx={{ mb: 1 }}>
            <Typography component="h3" variant="subtitle1" mt={1} ml={1} sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
              媒体名
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography component="h3" variant="subtitle1" mt={1} sx={{ minWidth: '80%', borderBottom: 'rgba(0, 0, 0, 0.3) 1px solid' }}>
              {hoeimoshikomiData?.baitai_mei || '　'}
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{ mb: 1 }}>
            <Typography component="h3" variant="subtitle1" mt={1} ml={1} sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
              画面名
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography component="h3" variant="subtitle1" mt={1} sx={{ minWidth: '80%', borderBottom: 'rgba(0, 0, 0, 0.3) 1px solid' }}>
              {hoeimoshikomiData?.gamen_mei || '　'}
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{ mb: 1 }}>
            <Typography component="h3" variant="subtitle1" mt={1} ml={1} sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
              番組名
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography component="h3" variant="subtitle1" mt={1} sx={{ minWidth: '80%', borderBottom: 'rgba(0, 0, 0, 0.3) 1px solid' }}>
              {hoeimoshikomiData?.bangumi_mei || '　'}
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{ mb: 1 }}>
            <Typography component="h3" variant="subtitle1" mt={1} ml={1} sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
              広告主名
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography component="h3" variant="subtitle1" mt={1} sx={{ minWidth: '80%', borderBottom: 'rgba(0, 0, 0, 0.3) 1px solid' }}>
              {hoeimoshikomiData?.kokyaku_mei || '　'}
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{ mb: 1 }}>
            <Typography component="h3" variant="subtitle1" mt={1} ml={1} sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
              住所
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography component="h3" variant="subtitle1" mt={1} sx={{ minWidth: '80%', borderBottom: 'rgba(0, 0, 0, 0.3) 1px solid' }}>
              {hoeimoshikomiData?.jusho || '　'}
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{ mb: 1 }}>
            <Typography component="h3" variant="subtitle1" mt={1} ml={1} sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
              放映開始日
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography component="h3" variant="subtitle1" mt={1} sx={{ minWidth: '80%', borderBottom: 'rgba(0, 0, 0, 0.3) 1px solid' }}>
              {hoeimoshikomiData?.haishin_kaishibi || '　'}
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{ mb: 1 }}>
            <Typography component="h3" variant="subtitle1" mt={1} ml={1} sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
              放映終了日
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography component="h3" variant="subtitle1" mt={1} sx={{ minWidth: '80%', borderBottom: 'rgba(0, 0, 0, 0.3) 1px solid' }}>
              {hoeimoshikomiData?.haishin_shuryobi || '　'}
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{ mb: 1 }}>
            <Typography component="h3" variant="subtitle1" mt={1} ml={1} sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
              時間指定放映
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography component="h3" variant="subtitle1" mt={1} sx={{ minWidth: '80%', borderBottom: 'rgba(0, 0, 0, 0.3) 1px solid' }}>
              {hoeimoshikomiData?.haishin_kaishijikan || '　'}
              {hoeimoshikomiData?.jikantai_shitei_flg || '　'}
              {hoeimoshikomiData?.haishin_shuryojikan || '　'}
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{ mb: 1 }}>
            <Typography component="h3" variant="subtitle1" mt={1} ml={1} sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
              1動画の再生時間
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography component="h3" variant="subtitle1" mt={1} sx={{ minWidth: '80%', borderBottom: 'rgba(0, 0, 0, 0.3) 1px solid' }}>
              {hoeimoshikomiData?.content_byosu || '　'} 秒
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{ mb: 1 }}>
            <Typography component="h3" variant="subtitle1" mt={1} ml={1} sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
              1動画の再生回数
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography component="h3" variant="subtitle1" mt={1} sx={{ minWidth: '80%', borderBottom: 'rgba(0, 0, 0, 0.3) 1px solid' }}>
              {hoeimoshikomiData?.saisei_kaisu || '　'} 回
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
}

export default HoeimoshikomiReadOnlyDialog;