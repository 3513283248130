import { useState, useRef, useContext, useEffect } from "react";
import videojs, { VideoJsPlayer } from 'video.js';
import 'video.js/dist/video-js.css';

import {
  Button,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";

import {
  PlaylistPlay,
  PlayArrow,
} from "@mui/icons-material";

import { ReloadContext } from "../../../context/ReloadContext";
import Player from "../../BangumihyoShosai/Player"
import Thumbnail from "../../Common/Thumbnail";
import { Content } from './data';
import { BANGUMIHYO_STATUS } from "../../../const/index";
import { BangumihyoDataSet, ExcelBangumihyoHizukeDataSet } from "../../../types/WebData";
import DogaSaiseiDialogModule from './DogaSaiseiDialog.module.css';
import BangumihyoHizukeSelect from "../../Common/bangumihyoHizukeSelect";

interface Props {
  contents: Content[];
  haishinKaishibi: string;
  selectedRow?: BangumihyoDataSet | null;
  bangumihyouFlg?: boolean;
}

/**
 * 動画再生ダイアログコンポーネント
 *
 * @param {Props} { contents, selectedRow }
 * @return {*} 
 */
const DogaSaiseiDialog = ({ contents, selectedRow, haishinKaishibi, bangumihyouFlg }: Props) => {
  const [open, setOpen] = useState(false);
  const [bangumihyoHizuke, setBangumihyoHizuke] = useState<ExcelBangumihyoHizukeDataSet>({ id: 0, bangumihyo_haishinbi: selectedRow?.haishin_kaishibi })
  const [playContents, setPlayContents] = useState<Content[]>([]);
  const reloadContext = useContext(ReloadContext);
  const playerRef = useRef<VideoJsPlayer | undefined>(undefined);
  const [bangumiMei, setBangumiMei] = useState<string>('');
  const [contentByosu, setContentByosu] = useState<number>(0);
  const [videoHaishinKaishibi, setVideoHaishinKaishibi] = useState<string>("");
  const [videoHaishinShuryobi, setVideoHaishinShuryobi] = useState<string>("");
  const [imageContentUrl, setImageContentUrl] = useState<string>("");
  const [videoJsOptions, setVideoJsOptions] = useState<videojs.PlayerOptions | undefined>(undefined);
  const [timerId, setTimerId] = useState<number>(0);
  const [contentBango, setContentBango] = useState<number>(0);
  const NO_IMAGE_PATH = `${process.env.PUBLIC_URL}/NoImage.png`;
  let contentIndex = 1;

  useEffect(() => {
    contes()
  }, [contents, bangumihyoHizuke])

  useEffect(() => {
    if (videoJsOptions) {
      handleReplay()
    }
  }, [playContents])

  useEffect(() => {
    if (selectedRow) {
      setBangumihyoHizuke({ id: 0, bangumihyo_haishinbi: selectedRow.haishin_kaishibi });
    } else {
      setBangumihyoHizuke({ id: 0, bangumihyo_haishinbi: haishinKaishibi });
    }
  }, [selectedRow, haishinKaishibi])

  /**
   *　player初期化完了後処理
   *  先頭のコンテンツ終了時イベントを設定する
   *
   * @param {VideoJsPlayer} player
   */
  const handlePlayerReady = (player: VideoJsPlayer) => {
    playerRef.current = player;
    const content = contents[0];

    const extension = content.content_file_extension.toLowerCase();
    const type = (extension === "mp4" || extension === "mpg") ? "movie" : "image";
    if (type === "image") {
      // 画像表示終了時イベント設定
      const timerId: number = window.setTimeout(onEndContent, content.content_byosu * 1000);
      setTimerId(timerId);
    } else {
      // 動画終了時イベント再設定
      player.off('ended')
      player.on('ended', onEndContent);
    }
  };

  /**
   *　表示コンテンツ抽出処理
   *
   */
  const contes = () => {
    let playContent: Content[] = [];
    let hizukeList: string[] = [];
    const getPlayContents = () => {
      contents.forEach((content) => {
        hizukeList.push(content.haishin_kaishibi)
        if (bangumihyoHizuke.bangumihyo_haishinbi) {
          if (content.haishin_kaishibi <= bangumihyoHizuke.bangumihyo_haishinbi && (content.haishin_shuryobi >= bangumihyoHizuke.bangumihyo_haishinbi || content.haishin_shuryobi === '')) {
            playContent.push(content);
          }
        } else {
          if (content.haishin_kaishibi <= haishinKaishibi && (content.haishin_shuryobi >= haishinKaishibi || content.haishin_shuryobi === '')) {
            playContent.push(content);
          }
        }
      })
      // 番組表作成日付がすべてのコンテンツの配信開始日よりも前の場合
      if (playContent.length === 0) {
        hizukeList.sort(((a, b) => { return (a > b ? 1 : -1); }))
        contents.forEach((content) => {
          if (content.haishin_kaishibi <= hizukeList[0] && (content.haishin_shuryobi >= hizukeList[0] || content.haishin_shuryobi === '')) {
            playContent.push(content);
          }
        })
      }
      setPlayContents(playContent);
    }
    getPlayContents();
  }

  /**
   * ダイアログ表示時処理
   *
   * @return {*} 
   */
  const handleClickOpen = async () => {
    await contes();

    if (playContents.length === 0) {
      return reloadContext?.setSnackbarInfo({
        isOpen: true,
        type: "error",
        message: (bangumihyouFlg) ? "再生する番組表を選択してください。" : "番組表エリアにコンテンツが1件もありません。",
      });
    }

    // 先頭のコンテンツを設定した後、ダイアログ表示する
    const content = playContents[0];
    setBangumiMei(content.bangumi_mei);
    setContentByosu(content.content_byosu);
    setVideoHaishinKaishibi(content.haishin_kaishibi);
    setVideoHaishinShuryobi(content.haishin_shuryobi);
    setContentBango(1);
    const videoJsOptions: videojs.PlayerOptions = {
      controls: true,
      muted: false,
      autoplay: false, // 自動再生OFF
      loop: false, // ループ再生OFF
    };

    const extension = content.content_file_extension;
    const type = (extension === "mp4" || extension === "mpg") ? "movie" : "image";
    if (type === "image") {
      if (content.content_file_path) {
        setImageContentUrl(content.content_file_path);
      } else {
        // コンテンツが無い場合、デフォルトのNoImage画像を表示
        setImageContentUrl(NO_IMAGE_PATH);
      }
    } else {
      setImageContentUrl("");
      videoJsOptions.sources = [{
        src: contents.length > 0 ? contents[0].content_file_path : "",
        type: `video/${extension}`
      }]
    };

    setVideoJsOptions(videoJsOptions)

    // ダイアログ表示
    setOpen(true);
  };

  /**
   * ダイアログを閉じる
   * 動画、画像の終了時イベントは削除する
   *
   * @return {*} 
   */
  const handleClose = () => {
    if (!playerRef.current) return;
    const player = playerRef.current;
    // 動画終了時イベント削除
    player.off('ended')
    // 画像表示終了時イベント削除
    if (timerId !== 0) {
      clearTimeout(timerId);
    }
    setVideoJsOptions(undefined)
    setBangumihyoHizuke({ id: 0, bangumihyo_haishinbi: undefined });
    setOpen(false);
  };

  const handleReplay = () => {
    replayByIndex(0);
  };

  /**
   * サムネイルクリック処理
   *
   * @param {number} contentIndexSelected
   */
  const handleClickThumbnail = (contentIndexSelected: number) => {
    replayByIndex(contentIndexSelected);
  }

  /**
   * コンテンツ再生処理
   * 引数のindexから順番に再生する
   *
   * @param {number} contentIndexSelected
   * @return {*} 
   */
  const replayByIndex = (contentIndexSelected: number) => {
    if (!playerRef.current) return;
    const player = playerRef.current;
    const content = playContents[contentIndexSelected];

    setBangumiMei(content.bangumi_mei);
    setContentByosu(content.content_byosu);
    setVideoHaishinKaishibi(content.haishin_kaishibi);
    setVideoHaishinShuryobi(content.haishin_shuryobi);
    // 画像表示終了時イベント削除
    if (timerId !== 0) {
      clearTimeout(timerId);
    }

    const extension = content.content_file_extension;
    const type = (extension === "mp4" || extension === "mpg") ? "movie" : "image";
    if (type === "image") {
      if (content.content_file_path) {
        setImageContentUrl(content.content_file_path);
      } else {
        // コンテンツが無い場合、デフォルトのNoImage画像を表示
        setImageContentUrl(NO_IMAGE_PATH);
      }
      // 画像表示終了時イベント設定
      const timerId: number = window.setTimeout(onEndContent, content.content_byosu * 1000);
      setTimerId(timerId);
    } else {
      player.src({
        src: content.content_file_path,
        type: `video/${extension}`
      });
      setImageContentUrl("");
      player.play();

      // 動画終了時イベント再設定
      player.off('ended')
      player.on('ended', onEndContent);
    }
    contentIndex = contentIndexSelected + 1;
    setContentBango(contentIndex);
  }

  /**
   * コンテンツ再生終了時処理
   *
   */
  const onEndContent = () => {
    if (!playerRef.current) return;
    const player = playerRef.current;

    // 続きのコンテンツがある場合、再生する
    if (playContents.length > contentIndex) {
      const content = playContents[contentIndex];
      setBangumiMei(content.bangumi_mei);
      setContentByosu(content.content_byosu);

      // 画像表示終了時イベント削除
      if (timerId !== 0) {
        clearTimeout(timerId);
      }

      const extension = content.content_file_extension;
      const type = (extension === "mp4" || extension === "mpg") ? "movie" : "image";
      if (type === "image") {
        if (content.content_file_path) {
          setImageContentUrl(content.content_file_path);
        } else {
          // コンテンツが無い場合、デフォルトのNoImage画像を表示
          setImageContentUrl(NO_IMAGE_PATH);
        }
        // 画像表示終了時イベント設定
        const timerId: number = window.setTimeout(onEndContent, content.content_byosu * 1000);
        setTimerId(timerId);
      } else {
        player.src({
          src: content.content_file_path,
          type: `video/${extension}`
        });
        setImageContentUrl("");
        player.play();
        // 動画終了時イベント再設定
        player.off('ended')
        player.on('ended', onEndContent);
      }
      contentIndex = contentIndex + 1;
      setContentBango(contentIndex);
    }
  }

  return (
    <>
      <Button
        type="button"
        variant="contained"
        onClick={() => handleClickOpen()}
        disabled={selectedRow?.status === BANGUMIHYO_STATUS.SAKUSEICHU}
        sx={{ ml: 1, mt: 2, mb: 2 }}
      >
        番組再生
      </Button>

      <Dialog
        open={open}
        PaperProps={{
          sx: {
            maxWidth: '1600px',
            width: "90vw",
            height: "90vh",
            padding: '8px'
          }
        }}
      >
        <Box mb={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography component="h3" variant="h5" mt={1} ml={2}
            sx={{ background: 'linear-gradient(transparent 60%, #a8eaff 100%)', minWidth: '250px', maxHeight: '50px' }}>
            番組表プレビュー再生
          </Typography>
          <BangumihyoHizukeSelect bangumihyoHizuke={bangumihyoHizuke} contents={contents} bangumiHaisinkaishibi={haishinKaishibi} setBangumihyoHizuke={setBangumihyoHizuke}></BangumihyoHizukeSelect>
          <Box sx={{ width: '75px', display: 'flex', alignItems: 'center' }}>
            <Typography component="h3" variant="body1" ml={2} sx={{ fontSize: '14px' }}>
              総秒数：{playContents.reduce((sum, item) => sum + item.content_byosu, 0)}秒
            </Typography>
          </Box>
          <Typography component="h3" variant="h6" mt={1} mr={2}
            sx={{ display: 'flex', alignItems: 'center' }}>
            <PlaylistPlay color="primary" sx={{ mr: 1 }} />
            {`${contentBango} / ${playContents.length}`}
          </Typography>
        </Box>

        <DialogContent sx={{
          paddingTop: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
        }}>
          <Box sx={{
            display: 'flex',
            flexFlow: 'column',
            width: '-webkit-fill-available',
            maxWidth: '70%',
            maxHeight: '100%',
          }}>
            {
              <>
                <svg id="svg" width={'100%'} height={'720px'} viewBox="0 0 1280 720" xmlns="http://www.w3.org/2000/svg" style={{ display: `${imageContentUrl ? "block" : "none"}` }}>
                  <image id="image" width={'100%'} height={'100%'} href={imageContentUrl} />
                  <g id="g" />
                </svg>
                <Box sx={{ display: `${imageContentUrl === "" ? "block" : "none"}` }}>
                  <Player options={videoJsOptions} onReady={handlePlayerReady} />
                </Box>
              </>
            }
            <Typography component="h3" variant="h6" mt={1}>
              {bangumiMei}
            </Typography>
            <Typography component="h3" variant="subtitle1" sx={{ marginLeft: '4px' }}>
              再生時間：{contentByosu}秒
            </Typography>
            <Typography component="h3" variant="subtitle1" sx={{ marginLeft: '4px' }}>
            放映開始日/終了日：{videoHaishinKaishibi} - {videoHaishinShuryobi}
            </Typography>             
            {
              imageContentUrl === NO_IMAGE_PATH ?
                <Typography component="h3" variant="subtitle2" sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
                  ※コンテンツが登録されていません。
                </Typography>
                : imageContentUrl ?
                  <Typography component="h3" variant="subtitle2" sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
                    ※{contentByosu}秒経過後、次のコンテンツを表示します。
                  </Typography>
                  : ""
            }
          </Box>
          <Box sx={{
            display: 'flex',
            flexFlow: 'column',
            overflowY: 'auto',
            height: '100%',
            minWidth: '300px'
          }}>
            <ul className={DogaSaiseiDialogModule[`playlist`]}>
              {playContents.map((content, i) => {
                return (
                  <li key={content.id} className={DogaSaiseiDialogModule[`item-wrapper`]}>
                    <div className={DogaSaiseiDialogModule[`play-arrow-container`]}>
                      {
                        contentBango === i + 1 ?
                          <PlayArrow fontSize="large" sx={{ mr: 1, opacity: 0.8 }} />
                          : ""
                      }
                    </div>
                    {/* 先ほどの２つのコンポーネントを利用 */}
                    <div className={DogaSaiseiDialogModule[`contents`]} onClick={() => handleClickThumbnail(i)}>
                      <Thumbnail src={playContents[i].thumbnail_file_path} />
                      {/* <div className={DogaSaiseiDialogModule[`thumbnail-text`]}>{playContents[i].bangumi_mei}</div> */}
                    </div>
                  </li>
                );
              })}
            </ul>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleReplay}>最初から再生する</Button>
          <Button variant="outlined" onClick={handleClose}>閉じる</Button>
        </DialogActions>
      </Dialog >
    </ >
  );
}

export default DogaSaiseiDialog;