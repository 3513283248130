import API from "./api";
import * as MESSAGE from "../const/message";
import { CancelTokenSource } from "axios";
import { JackContentUploadPostData } from "../types/WebData";
import { HTTP_STATUS_CODE } from "../const/index";

/**
 * jack_contentテーブルAPI
 * ジャックコンテンツ検索
 *
 * @param {JackSearchParam} searchParam
 * @return {*} 
 */
export const postSearchJackContent = async (jackId: number) => {
  const result = await API.post(`/cms-api/jackContent/search`, { jack_id: jackId })
    .then(
      (res) => {
        return { isError: false, message: "", data: res.data };
      })
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR, data: [] };
    });
  return result;
};

/**
 * コンテンツアップロード
 *
 * @param {JackContentUploadPostData} contentUploadPostData コンテンツデータ（ファイル自体は含まない）
 * @return {*} 
 */
export const putJackContentUpload = async (contentUploadPostData: JackContentUploadPostData): Promise<any> => {
  const result = await API.put(`/cms-api/jackContent/upload`, contentUploadPostData)
    .then((res) => {
      return {
        isError: false,
        message: "",
        newContentId: res.data.newContentId,
        signedUrlContent: res.data.signedUrlContent,
      };
    })
    .catch((error) => {
      const status = error.response.status;
      const message = status === HTTP_STATUS_CODE.CONFLICT_ERROR ? MESSAGE.CONFLICT_ERROR : MESSAGE.SYSTEM_ERROR;
      return { isError: true, message: message };
    });
  return result;
};

/**
 * コンテンツアップロードステータス更新
 *
 * @param {number} contentId
 * @param {number} uploadStatus
 * @return {*} 
 */
export const putJackContentUploadStatus = async (contentId: number, uploadStatus: number) => {
  return await API.put(`/cms-api/jackContent/upload/status/id`, { id: contentId, upload_status: uploadStatus });
};

/**
 * コンテンツダウンロード
 *
 * @param {CancelTokenSource | null} source
 * @param {number} id
 * @param {string} filepath
 * @return {*} 
 */
export const getJackContentDownload = async (source: CancelTokenSource | null, id: number, filepath: string) => {
  return await API.get(`/cms-api/jackContent/download`, { params: { id, filepath }, cancelToken: source?.token });
};

/**
 * コンテンツ情報取得
 *
 * @param {number} contentId
 * @return {*} 
 */
export const getJackContent = async (contentId: number) => {
  return await API.get(`/cms-api/jackContent/id`, { params: { id: contentId } });
};