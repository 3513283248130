import { useContext, useState, useRef } from "react";
import { useForm } from "react-hook-form";

import {
  Button,
  Box,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";

import Edit from '@mui/icons-material/Edit';
import LoadingButton from '@mui/lab/LoadingButton';

import moment from "moment";

import { ReloadContext } from "../../context/ReloadContext";
import { getSearchLocationId, updateLocation } from "../../api/location";
import KaishaSelect from '../Common/KaishaSelect';
import AreaSelect from '../Common/AreaSelect';
import { KaishaDataSet } from "../../types/WebData";
import { AreaDataSet } from "../../types/WebData";

interface Props {
  LocationId: number;
}

/**
 * 設置場所管理ダイアログコンポーネント
 *
 * @param {Props} { LocationId }
 * @return {*} 
 */
export const LocationEditDialog = ({ LocationId }: Props) => {
  const reloadContext = useContext(ReloadContext);
  const [open, setOpen] = useState(false);
  const { handleSubmit } = useForm<any>();

  const [jusho, setJusho] = useState<string>("");
  const [locationMei, setLocationMei] = useState<string>("");
  const [kaisha, setKaisha] = useState<KaishaDataSet | null>({ id: 0 });
  const [area, setArea] = useState<AreaDataSet | null>({ id: 0 });
  const [updateDt, setUpdateDt] = useState<Date | null>(null);

  // エラーメッセージ表示用
  // 各入力項目ごとのメッセージ
  const [errorMessageJusho, setErrorMessageJusho] = useState<string>("");
  const [errorMessageLocationMei, setErrorMessageLocationMei] = useState<string>("");
  const [errorMessageKaishaId, setErrorMessageKaishaId] = useState<string>("");
  const [errorMessageAreaId, setErrorMessageAreaId] = useState<string>("");
  const [load, setLoad] = useState<boolean>(false);

  const isCreate = LocationId === 0;
  // 処理中フラグ（ボタン連打対策）
  const processing = useRef(false);

  const handleClickOpen = async () => {
    // エラーメッセージ初期化
    initErrorMessage();

    if (isCreate) { // 新規登録
      setJusho("");
      setLocationMei("");
      setKaisha({ id: 0 })
      setArea({ id: 0 });
      setUpdateDt(null);
    } else {
      const res = await getSearchLocationId(LocationId);
      if (res?.isError) {
        await reloadContext?.setSnackbarInfo({
          isOpen: true,
          type: "error",
          message: res?.message,
        });
      }

      setJusho(res.data[0].jusho);
      setLocationMei(res.data[0].location_mei);
      setKaisha({ id: res.data[0].kaisha_id });
      setArea({ id: res.data[0].area_id });
      setUpdateDt(res.data[0].update_dt);
    }

    setOpen(true);
  };

  /**
 * エラーメッセージ初期化処理
 */
  const initErrorMessage = () => {
    setErrorMessageLocationMei("")
    setErrorMessageAreaId("")
    setErrorMessageJusho("")
    setErrorMessageKaishaId("")
  }

  const handleClose = () => {
    setOpen(false);
  };

  // テーブル更新・一覧更新
  const handleUpdate = async () => {
    // エラーメッセージ初期化
    initErrorMessage();

    if (locationMei === "") {
      return setErrorMessageLocationMei("設置場所名を入力してください。");
    }
    if (area?.id === 0) {
      return setErrorMessageAreaId("エリア名を選択してください。");
    }
    if (jusho === "") {
      return setErrorMessageJusho("住所を入力してください。");
    }
    if (kaisha?.id === 0) {
      return setErrorMessageKaishaId("会社名を選択してください。");
    }

    // 処理中(true)なら非同期処理せずに抜ける
    if (processing.current) return;
    // 処理中フラグを上げる
    processing.current = true;
    // 疑似非同期処理
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 1000);

    setLoad(true);

    const result = await updateLocation({
      id: LocationId,
      jusho: jusho,
      location_mei: locationMei,
      kaisha_id: kaisha?.id,
      area_id: area?.id,
      update_dt: (updateDt) ? moment(updateDt).format("YYYY-MM-DD HH:mm:ss") : undefined,
    });

    await reloadContext?.setSnackbarInfo({
      isOpen: true,
      type: result.isError ? "error" : "success",
      message: result.message,
    });
    setLoad(false);
    await reloadSetting();
  };

  // モーダル→画面戻り時
  const reloadSetting = () => {
    reloadContext?.setReload(reloadContext?.reload + 1);
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      {
        isCreate ?
          <Button
            type="button"
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
            onClick={() => handleClickOpen()}
          >
            新規設置場所登録
          </Button>
          :
          <Edit color="primary" fontSize='large'
            onClick={() => handleClickOpen()}
          />
      }
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            width: "50vw",
          }
        }}
      >
        <form onSubmit={handleSubmit(handleUpdate)}>
          <Typography component="h3" variant="h6" mt={1} ml={2}>
            {isCreate ? "新規設置場所登録" : "設置場所情報編集"}
          </Typography>
          <DialogContent sx={{ paddingTop: 0 }}>
            <TextField
              label="設置場所名 *"
              value={locationMei}
              onChange={e => setLocationMei(e.target.value)}
              fullWidth
              variant="outlined"
              sx={{ marginTop: 1 }}
            />
            {errorMessageLocationMei && (
              <small className={"error-message"}>{errorMessageLocationMei}</small>
            )}
            <AreaSelect area={area} setArea={setArea} label={"エリア名 *"} />
            {errorMessageAreaId && (
              <small className={"error-message"}>{errorMessageAreaId}</small>
            )}
            <TextField
              label="住所 *"
              value={jusho}
              onChange={e => setJusho(e.target.value)}
              fullWidth
              variant="outlined"
              sx={{ marginTop: 1 }}
            />
            {errorMessageJusho && (
              <small className={"error-message"}>{errorMessageJusho}</small>
            )
            }
            <KaishaSelect kaisha={kaisha} setKaisha={setKaisha} label={"会社名 *"} />
            {
              errorMessageKaishaId && (
                <small className={"error-message"}>{errorMessageKaishaId}</small>
              )
            }

          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>閉じる</Button>
            <LoadingButton loading={load} variant="contained" type="submit">登録</LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}
