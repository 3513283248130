import { KokyakuDataSet, KokyakuSearchParam } from "../types/WebData";
import API from "./api";
import * as MESSAGE from "../const/message";
import { HTTP_STATUS_CODE } from "../const/index";

/**
 * kokyakuテーブルAPI
 * 広告主情報検索
 *
 * @param {kokyakuId} kokyakuId
 * @return {*} 
 */
export const getSearchKokyakuId = async (kokyakuId: number) => {
  const result = await API.get(`/cms-api/kokyaku/id`, { params: { id: kokyakuId } })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR, data: [] };
    });

  return result;
};

/**
 * kokyakuテーブルAPI
 * 広告主情報検索
 *
 * @param {KokyakuSearchParam} searchParam
 * @return {*} 
 */
export const postSearchKokyaku = async (searchParam: KokyakuSearchParam) => {
  const result = await API.post(`/cms-api/kokyaku/search`, searchParam)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR, data: [] };
    });

  return result;
};

/**
 * kokyakuテーブルAPI
 * 広告主情報検索
 *
 * @param {KokyakuSearchParam} searchParam
 * @return {*} 
 */
export const postSearchKokyakuDairiten = async (searchParam: KokyakuSearchParam) => {
  const result = await API.post(`/cms-api/kokyaku/search/dairiten`, searchParam)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR, data: [] };
    });
  return result;
};

/**
 * kokyakuテーブルAPI 更新
 *
 * @param {KokyakuDataSet} postData
 */
export const updateKokyaku = async (postData: KokyakuDataSet) => {
  if (postData.id === 0) {
    // insert
    const result = await API.post("/cms-api/kokyaku", postData)
      .then((res) => {
        return { isError: false, message: MESSAGE.TOROKU_SEIKO };
      })
      .catch((error) => {
        return { isError: true, message: MESSAGE.SYSTEM_ERROR, data: [] };
      });
    return result;
  } else {
    // update
    const result = await API.put("/cms-api/kokyaku/id", postData)
      .then((res) => {
        return { isError: false, message: MESSAGE.KOSHIN_SEIKO };
      })
      .catch((error) => {
        const status = error.response.status;
        const message = status === HTTP_STATUS_CODE.CONFLICT_ERROR ? MESSAGE.CONFLICT_ERROR : MESSAGE.SYSTEM_ERROR;
        return { isError: true, message: message, data: [] };
      });
    return result;
  }
};

/**
 * kokyakuテーブルAPI del_flg更新
 *
 * @param {number} id
 * @param {boolean} del_flg
 */
export const deleteKokyaku = async (id: number, del_flg: boolean) => {
  const result = await API.put("/cms-api/kokyaku/delete", { id: id, del_flg: del_flg })
    .then((res) => {
      return { isError: false, message: "" };
    })
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR, data: [] };
    });
  return result;
};