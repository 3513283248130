import { useState, useContext, useRef } from "react";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import Clear from '@mui/icons-material/Clear';

import { ReloadContext } from "../../context/ReloadContext";
import { deleteAllOshirase } from "../../api/oshirase";
import OshiraseDialogModule from "./OshiraseDialog.module.css";
import LoadingButton from '@mui/lab/LoadingButton';

type Props = {
  loginuserId: number;
};

/**
 * お知らせ削除確認ダイアログコンポーネント
 *
 * @param {Props} { loginuserId }
 * @return {*} 
 */
const OshiraseDeleteAllDialog = ({ loginuserId }: Props) => {
  const reloadContext = useContext(ReloadContext);
  const [open, setOpen] = useState(false);
  // 処理中フラグ（ボタン連打対策）
  const processing = useRef(false);
  const [load, setLoad] = useState<boolean>(false);

  const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    setOpen(true);
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    setOpen(false);
  };

  const deleteAll = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();

    // 処理中(true)なら非同期処理せずに抜ける
    if (processing.current) return;
    // 処理中フラグを上げる
    processing.current = true;
    // 疑似非同期処理
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 1000);

    setLoad(true);

    const result = await deleteAllOshirase(loginuserId);
    await reloadContext?.setSnackbarInfo({
      isOpen: true,
      type: result?.isError ? "error" : "success",
      message: result?.isError ? result?.message : "削除しました。",
    });
    setLoad(false);
    await reloadSetting();
  };

  // モーダル→画面戻り時
  const reloadSetting = () => {
    reloadContext?.setReload(reloadContext?.reload + 1);
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }} onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => event.stopPropagation()}>
      <Button color="warning" variant="contained" onClick={handleClickOpen}>全件削除</Button>
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            width: "20vw",
          }
        }}
      >
        <Typography component="h3" variant="subtitle1" mt={2} ml={2}>
          削除確認
        </Typography>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            全件削除してもよろしいですか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>閉じる</Button>
          <LoadingButton loading={load} variant="contained" onClick={deleteAll}>削除する</LoadingButton>
        </DialogActions>
      </Dialog>
    </Box >
  );
}

export default OshiraseDeleteAllDialog;