import { useContext, useState, useRef } from "react";
import { useForm } from "react-hook-form";

import {
  Button,
  Box,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";

import Edit from '@mui/icons-material/Edit';

import moment from "moment";

import { ReloadContext } from "../../context/ReloadContext";
import { AuthInfoContext } from "../../context/AuthContext";
import { KENGEN } from "../../const/index";
import { updateKaisha, getSearchKaishaId } from "../../api/kaisha";
import KengenSelect from "../Common/KengenSelect";
import LoadingButton from '@mui/lab/LoadingButton';

interface Props {
  KaishaId: number;
}

/**
 * 会社管理ダイアログコンポーネント
 *
 * @param {Props} { KaishaId }
 * @return {*} 
 */
export const KaishaEditDialog = ({ KaishaId }: Props) => {
  const [authInfo] = useContext(AuthInfoContext);
  const reloadContext = useContext(ReloadContext);
  const [open, setOpen] = useState(false);
  const { handleSubmit } = useForm<any>();

  const [kaishaMei, setKaishaMei] = useState<string>("");
  const [jusho, setJusho] = useState<string>("");
  const [kengenId, setKengenId] = useState<number>(0);
  const [updateDt, setUpdateDt] = useState<Date | null>(null);

  // エラーメッセージ表示用
  // 各入力項目ごとのメッセージ
  const [errorMessageKaishaMei, setErrorMessageKaishaMei] = useState<string>("");
  const [errorMessageJusho, setErrorMessageJusho] = useState<string>("");
  const [errorMessageKengenId, setErrorMessageKengenId] = useState<string>("");
  const [load, setLoad] = useState<boolean>(false);

  const isCreate = KaishaId === 0;
  // 処理中フラグ（ボタン連打対策）
  const processing = useRef(false);

  const handleClickOpen = async () => {
    // エラーメッセージ初期化
    initErrorMessage();

    if (isCreate) { // 新規登録
      setKaishaMei("");
      setJusho("");
      setKengenId(0);
      setUpdateDt(null);
    } else {
      // 会社詳細情報取得
      const res = await getSearchKaishaId(KaishaId);
      if (res?.isError) {
        await reloadContext?.setSnackbarInfo({
          isOpen: true,
          type: "error",
          message: res?.message,
        });
      }

      setKaishaMei(res.data[0].kaisha_mei);
      setJusho(res.data[0].jusho);
      setKengenId(res.data[0].kengen_id);
      setUpdateDt(res.data[0].update_dt);
    }

    setOpen(true);
  };

  /**
 * エラーメッセージ初期化処理
 */
  const initErrorMessage = () => {
    setErrorMessageKaishaMei("")
    setErrorMessageJusho("")
    setErrorMessageKengenId("")
  }

  const handleClose = () => {
    setOpen(false);
  };

  // テーブル更新・一覧更新
  const handleUpdate = async () => {
    // エラーメッセージ初期化
    initErrorMessage();

    if (kaishaMei === "") {
      return setErrorMessageKaishaMei("会社名を入力してください。");
    }
    if (jusho === "") {
      return setErrorMessageJusho("住所を入力してください。");
    }
    if (kengenId === 0) {
      return setErrorMessageKengenId("権限を設定してください。");
    }

    // 処理中(true)なら非同期処理せずに抜ける
    if (processing.current) return;
    // 処理中フラグを上げる
    processing.current = true;
    // 疑似非同期処理
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 1000);
    setLoad(true);
    const result = await updateKaisha({
      id: KaishaId,
      jusho: jusho,
      kaisha_mei: kaishaMei,
      kengen_id: kengenId,
      update_dt: (updateDt) ? moment(updateDt).format("YYYY-MM-DD HH:mm:ss") : undefined,
    });

    await reloadContext?.setSnackbarInfo({
      isOpen: true,
      type: result.isError ? "error" : "success",
      message: result.message,
    });
    await reloadSetting();
    setLoad(false);
  };

  // モーダル→画面戻り時
  const reloadSetting = () => {
    reloadContext?.setReload(reloadContext?.reload + 1);
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      {
        isCreate ?
          <Button
            type="button"
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
            onClick={() => handleClickOpen()}
          >
            新規会社登録
          </Button>
          :
          <Edit color="primary" fontSize='large'
            onClick={() => handleClickOpen()}
          />
      }
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            width: "50vw",
          }
        }}
      >
        <form onSubmit={handleSubmit(handleUpdate)}>
          <Typography component="h3" variant="h6" mt={1} ml={2}>
            {isCreate ? "新規会社登録" : "会社情報編集"}
          </Typography>
          <DialogContent sx={{ paddingTop: 0 }}>
            <TextField
              label="会社名 *"
              value={kaishaMei}
              onChange={e => setKaishaMei(e.target.value)}
              fullWidth
              variant="outlined"
              sx={{ marginTop: 1 }}
            />
            {errorMessageKaishaMei && (
              <small className={"error-message"}>{errorMessageKaishaMei}</small>
            )}
            <TextField
              label="住所 *"
              value={jusho}
              onChange={e => setJusho(e.target.value)}
              fullWidth
              variant="outlined"
              sx={{ marginTop: 1 }}
            />
            {errorMessageJusho && (
              <small className={"error-message"}>{errorMessageJusho}</small>
            )}
            <KengenSelect kengenId={kengenId} setKengenId={setKengenId} label="権限 *" disabled={authInfo.kengen === KENGEN.ADMIN ? false : true} />
            {errorMessageKengenId && (
              <small className={"error-message"}>{errorMessageKengenId}</small>
            )}
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>閉じる</Button>
            <LoadingButton loading={load} variant="contained" type="submit">登録</LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}
