import {
  TentoShomeiSearchParam,
} from "../types/WebData";
import API from "./api";
import * as MESSAGE from "../const/message";

/**
 * 点灯証明検索
 *
 * @param {TentoShomeiSearchParam} searchParam
 * @return {*} 
 */
export const postSearchTentoShomei = async (searchParam: TentoShomeiSearchParam) => {
  const result = await API.post(`/cms-api/tentoShomei/search`, searchParam)
    .then(
      (res) => {
        return { isError: false, message: "", data: res.data.data };
      })
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR, data: [] };
    });
  return result;
};