import { useState, useContext } from "react";

import {
  Button,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Card,
  CardContent,
  CardHeader,
} from "@mui/material";
import { Info, Warning } from '@mui/icons-material';

import moment from "moment";
import { ReloadContext } from "../../context/ReloadContext";
import { OshiraseDataSet } from "../../types/WebData";
import OshiraseDeleteDialog from './OshiraseDeleteDialog';
import OshiraseDialogModule from "./OshiraseDialog.module.css";
import { OSHIRASE_LEVEL } from "../../const/index";
import { updateOshirase } from "../../api/oshirase";

type Props = {
  oshiraseData: OshiraseDataSet;
}

/**
 * お知らせ詳細ダイアログコンポーネント
 *
 * @param {Props} { oshiraseData }
 * @return {*} 
 */
const OshiraseShosaiDialog = ({ oshiraseData }: Props) => {
  const reloadContext = useContext(ReloadContext);
  const [open, setOpen] = useState(false);

  const handleClickOpen = async () => {
    setOpen(true);
    if (!oshiraseData.kidoku_flg) {
      const result = await updateOshirase(oshiraseData.id);
      if (result?.isError) {
        await reloadContext?.setSnackbarInfo({
          isOpen: true,
          type: "error",
          message: result?.message,
        });
      }
    }
    reloadContext?.setReload(reloadContext?.reload + 1)
  };

  const handleClose = () => {
    setOpen(false);
    reloadContext?.setReload(reloadContext?.reload + 1)
  };

  const getCardClassName = () => {
    const osiraseLevelClass = oshiraseData.oshirase_level === OSHIRASE_LEVEL.INFO
      ? "card-info"
      : oshiraseData.oshirase_level === OSHIRASE_LEVEL.NOTICE
        ? "card-notice"
        : oshiraseData.oshirase_level === OSHIRASE_LEVEL.WARNING
          ? "card-warning" : "";
    const kidokuFlgClass = oshiraseData.kidoku_flg ? "card-kidoku" : "";
    const result = OshiraseDialogModule[osiraseLevelClass] + " " + OshiraseDialogModule[kidokuFlgClass];
    return result;
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Card className={getCardClassName()} sx={{ mb: 1, boxShadow: 3, position: "relative" }} onClick={handleClickOpen}>
        <OshiraseDeleteDialog deleteId={oshiraseData.id} />
        <CardContent className={OshiraseDialogModule[`MuiCardHeader-content`]}
          sx={{
            padding: "0 !important",
            div: {
              cursor: `pointer`,
              whiteSpace: `nowrap`,
              overflow: `hidden`,
              textOverflow: `ellipsis`,
            },
            span: {
              cursor: `pointer`,
              whiteSpace: `nowrap`,
              overflow: `hidden`,
              textOverflow: `ellipsis`,
            },
            h6: {
              cursor: `pointer`,
              whiteSpace: `nowrap`,
              overflow: `hidden`,
              textOverflow: `ellipsis`,
            }
          }}>
          <CardHeader
            avatar={
              oshiraseData.oshirase_level === OSHIRASE_LEVEL.INFO
                ? <Info color="primary" />
                : oshiraseData.oshirase_level === OSHIRASE_LEVEL.NOTICE
                  ? <Warning color="warning" />
                  : oshiraseData.oshirase_level === OSHIRASE_LEVEL.WARNING
                    ? <Warning color="error" /> : "error"
            }
            title={
              <Box sx={{
                display: "flex",
                flexFlow: "column",
              }}>
                <Typography variant="subtitle2">{moment(oshiraseData.create_dt).format("YYYY/MM/DD HH:mm:ss")}</Typography>
                <Typography variant="subtitle1">{oshiraseData.oshirase_title}</Typography>
              </Box>
            }
            subheader={oshiraseData.oshirase_naiyo}
          />
        </CardContent>
      </Card>
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            width: "80vw",
            minHeight: "50vh",
            maxWidth: "80vw"
          }
        }}
      >
        <DialogContent>
          <Typography variant="subtitle1" sx={{ padding: "0px 24px", paddingLeft: "0px" }}>{moment(oshiraseData.create_dt).format("YYYY/MM/DD HH:mm:ss")}</Typography>
          <DialogTitle sx={{ paddingTop: "0px", paddingLeft: "0px" }}>
            {oshiraseData.oshirase_title}
          </DialogTitle>
          <DialogContentText className={OshiraseDialogModule["oshirase-naiyo"]} >
            {oshiraseData.oshirase_naiyo}
          </DialogContentText>
        </DialogContent >
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>閉じる</Button>
        </DialogActions>
      </Dialog >
    </Box >
  );
}

export default OshiraseShosaiDialog;
