import { useState, useEffect } from 'react';

import { useDropzone } from 'react-dropzone';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { ALLOW_MOVIE_MIME_TYPES } from "../../const/index";

type Props = {
    contentFileMei: string;
    setUploadFile: React.Dispatch<React.SetStateAction<File | null>>;
    contentByosu: number;
    setEditFlg?: React.Dispatch<React.SetStateAction<boolean>>;
};

/**
 * ファイルアップロードコンポーネント
 *
 * @param {Props} { contentFileMei, setUploadFile, contentByosu, setEditFlg? }
 * @return {*} 
 */
const FileUpload = ({ contentFileMei, setUploadFile, contentByosu, setEditFlg }: Props) => {
    const [acceptedFile, setAcceptedFile] = useState<File | null>(null);
    const [errorMessage, setErrorMessage] = useState<string>("");

    useEffect(() => {
        let isMounted = true;
        if (isMounted) setUploadFile(acceptedFile);
        return () => { isMounted = false };
    }, [acceptedFile])

    const onDrop = async (acceptedFiles: File[]) => {
        setErrorMessage("");
        if (setEditFlg) {
            setEditFlg(false)
        }
        const file = acceptedFiles[0];
        // 動画秒数+-秒以内チェック（画像はスキップ）
        if (ALLOW_MOVIE_MIME_TYPES.includes(file.type.toLowerCase())) {
            const duration = await getDuration(file);
            // コンテンツ秒数よりアップロードされた動画の秒数が長い場合エラー
            if (contentByosu < duration) {
                return setErrorMessage(`事前に登録した再生時間${contentByosu}秒を超えています。${contentByosu}秒以内のファイルを選択してください。`);
            }
        }
        setAcceptedFile(file)
    }

    const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: false });

    // 動画再生時間取得
    const getDuration = (file: File): Promise<number> => {
        const element = document.createElement("video");
        const fileURL = URL.createObjectURL(file);
        return new Promise((resolve) => {
            element.addEventListener('loadedmetadata', () => {
                const duration = element.duration;
                element.remove();
                resolve(duration);
            });
            element.src = fileURL;
        });
    }

    return (
        <Box>
            <TextField
                margin="dense"
                id="user_id"
                label="現在のコンテンツ"
                value={contentFileMei}
                fullWidth
                variant="outlined"
                disabled
            />
            <Box mt={1} border={1}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '100px',
                    borderColor: '#C4C4C4',
                    borderRadius: '4px'
                }}>
                <div {...getRootProps({ className: 'dropzone' })}>
                    <Typography variant="subtitle2" mx={1}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#666666',
                        }}>
                        ファイルをドラッグ＆ドロップしてください（mp4、jpgのみ）
                        <Typography variant="body1" noWrap sx={{ minWidth: '50px', "marginRight": "15px" }}>または</Typography>
                        <Button
                            variant="outlined"
                            sx={{
                                width: "40%",
                            }}
                        >
                            ファイルを選択
                        </Button>
                    </Typography>
                </div>
            </Box>
            {errorMessage && (
                <small style={{ color: "red", marginLeft: "16px" }}>{errorMessage}</small>
            )}
            <Typography variant="subtitle2" mt={1} mx={1} sx={{ color: '#666666' }}>
                選択したファイル：{acceptedFile?.name}
            </Typography >
            <input {...getInputProps()} />
        </Box >
    );
}

export default FileUpload;